import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SusoService {

  suso:any;
  constructor() { }

  setSuso(suso){
    this.suso = suso;
  }
}
