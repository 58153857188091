import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.css']
})
export class DashboardAdminComponent implements OnInit {

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private storageRef: AngularFireStorage,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private dateSrv: DateFormatService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService
  ) { }
  filelist = [];
  ngOnInit() {
  }

  getData(){
    this.firestore.collection('CIC').get().forEach(e => {
      e.forEach(file => {
        const data = {
          ...file.data(),
          id: file.id,
          fileName: file.data().FileNames?file.data().FileNames.join('\n'): "Not yet upload",
          CreatedDate: new Date(file.data().CreatedDate.toDate())
        }
        this.filelist.push(data);
      })
    });
  }

  getFilterStatus(status){
    let r = this.filelist.filter(s=>s.Status === status);
    if(r)
      return r.length;
    else
      return 0
  }

}
