import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { MatDatepicker, MatDialog, MatTabChangeEvent, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { ExhibService } from 'src/app/Services/Utilities/exhib.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-view-exhib',
  templateUrl: './view-exhib.component.html',
  styleUrls: ['./view-exhib.component.css']
})
export class ViewExhibComponent implements OnInit {

  todayDate = new Date();
  firstFormGroup = this._formBuilder.group({
    Applicant: ['', Validators.required],
    School: ['', Validators.required],
    Email: ['', Validators.required],
    Phone: [''],
    TeamMembers: this._formBuilder.array([]),
    ProjectTitle: ['', Validators.required],
    Product: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    Publication: this._formBuilder.array([]),
    Intelliectual: this._formBuilder.array([]),
    Research: this._formBuilder.array([]),
    Grant: this._formBuilder.array([]),
    Consultation: this._formBuilder.array([]),
    Technology: this._formBuilder.array([]),
    Products: this._formBuilder.array([]),
    IndustrialPatners: this._formBuilder.array([]),
    Goal: ['', Validators.required],
    TechnologyDriver: ['', Validators.required],
  });
  thirdFormGroup = this._formBuilder.group({
    ExecutiveSummary: ['', Validators.required],
    ProblemStatement: ['', Validators.required],
    Description: ['', Validators.required],
    TRL: ['', Validators.required],
    TRLAssessor: ['', Validators.required],
    Status: ['', Validators.required],
    Track: ['', Validators.required],
    IP: ['', Validators.required],
    Usefulness: ['', Validators.required],
    Benchmarking: ['', Validators.required],
  });
  fourthFormGroup = this._formBuilder.group({
    TargetMarket: ['', Validators.required],
    MarketStrategy: ['', Validators.required],
    BusinessModel: ['', Validators.required],
    Commercialization: ['', Validators.required],
  });
  fifthFormGroup = this._formBuilder.group({
    Photo: [[]],
    Patent: ['', Validators.required],
    CV: ['', Validators.required],
    Pitching: ['', Validators.required],
    BusinessModelCanvas: ['', Validators.required],
  });

  @ViewChild('picker', { read: MatDatepicker, static: false }) datePickerElement = MatDatepicker;

  email;
  uuid;
  edit = false;
  editID;

  Goals = [
    'GOAL 1: No Poverty',
    'GOAL 2: Zero Hunger',
    'GOAL 3: Good Health and Well-being',
    'GOAL 4: Quality Education',
    'GOAL 5: Gender Equality',
    'GOAL 6: Clean Water and Sanitation',
    'GOAL 7: Affordable and Clean Energy',
    'GOAL 8: Decent Work and Economic Growth',
    'GOAL 9: Industry, Innovation and Infrastructure',
    'GOAL 10: Reduced Inequality',
    'GOAL 11: Sustainable Cities and Communities',
    'GOAL 12: Responsible Consumption and Production',
    'GOAL 13: Climate Action',
    'GOAL 14: Life Below Water',
    'GOAL 15: Life on Land',
    'GOAL 16: Peace and Justice Strong Institutions',
    'GOAL 17: Partnerships to Achieve the Goal',
  ]

  Techs = [
    '5G/6G',
    'Sensor Technology',
    '4D/5D Printing	',
    'Advanced Materials',
    'Advanced Intelligent Systems',
    'Cybersecurity & Encryption',
    'Augmented Analytics & Data Discovery',
    'Blockchain',
    'Neurotechnology',
    'Bioscience Technology',
  ]

  Technologies = [
    'TRL 1 - Basic Principle',
    'TRL 2 - Formulation of Concept',
    'TRL 3 - Experimental Proof of Concept',
    'TRL 4 - Lab Validation',
    'TRL 5 - Validation in Real Environment',
    'TRL 6 - Demonstration in Real Environment',
    'TRL 7 - Demonstration in Prototype',
    'TRL 8 - Product Complete and Qualified',
    'TRL 9 - Product Proven',
  ]

  Gap = [
    'Product Advertisement and Marketing',
    'Market Segment Penetration',
    'Product Development and Application Diversification',
    'Certification',
    'Geographical Expansion',
    'Strategic Alliance',
  ]

  Business = [
    'B2B',
    'B2C',

  ]

  dropdownSettings:IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: true,

  };


  constructor(
    private _formBuilder: FormBuilder,
    private toast: ToastrService,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private router: Router,
    public exhibService: ExhibService
  ) {


    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
      this.uuid = auth.uid;

      if (exhibService.exhib) {
        this.edit = true;
        let f = exhibService.exhib;
        this.editID = f.id;

        this.firstFormGroup = this._formBuilder.group({
          Applicant: [f.firstFormGroup.Applicant, Validators.required],
          School: [f.firstFormGroup.School, Validators.required],
          Email: [f.firstFormGroup.Email, Validators.required],
          Phone: [f.firstFormGroup.Phone],
          TeamMembers: this._formBuilder.array([]),
          ProjectTitle: [f.firstFormGroup.ProjectTitle, Validators.required],
          Product: [f.firstFormGroup.Product, Validators.required],
        });
        this.secondFormGroup = this._formBuilder.group({
          Publication: this._formBuilder.array([]),
          Intelliectual: this._formBuilder.array([]),
          Research: this._formBuilder.array([]),
          Grant: this._formBuilder.array([]),
          Consultation: this._formBuilder.array([]),
          Technology: this._formBuilder.array([]),
          Products: this._formBuilder.array([]),
          IndustrialPatners: this._formBuilder.array([]),
          Goal: [f.secondFormGroup.Goal, Validators.required],
          TechnologyDriver: [f.secondFormGroup.TechnologyDriver, Validators.required],
        });
        for (const dir of f.firstFormGroup.TeamMembers) {
          this.teams().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
              IdentityNumber: [dir.IdentityNumber, Validators.required],
              Experience: [dir.Experience, Validators.required],
              Percentages: [dir.Percentages, Validators.required],
              USMStaff: dir.USMStaff,
            }));
        }

        for (const dir of f.secondFormGroup.Publication) {
          this.publication().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
            }));
        }

        for (const dir of f.secondFormGroup.Intelliectual) {
          this.intelliectual().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
            }));
        }

        for (const dir of f.secondFormGroup.Research) {
          this.research().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
            }));
        }

        for (const dir of f.secondFormGroup.Grant) {
          this.grant().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
              Value: [dir.Value, Validators.required],
            }));
        }

        for (const dir of f.secondFormGroup.Consultation) {
          this.consultation().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
              Value: [dir.Value, Validators.required],
            }));
        }

        for (const dir of f.secondFormGroup.Technology) {
          this.technology().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
              Value: [dir.Value, Validators.required],

            }));
        }

        for (const dir of f.secondFormGroup.Products) {
          this.products().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
              Value: [dir.Value, Validators.required],
            }));
        }

        for (const dir of f.secondFormGroup.IndustrialPatners) {
          this.industrialPatners().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
            }));
        }

        this.thirdFormGroup = this._formBuilder.group({
          ExecutiveSummary: [f.thirdFormGroup.ExecutiveSummary, Validators.required],
          ProblemStatement: [f.thirdFormGroup.ProblemStatement, Validators.required],
          Description: [f.thirdFormGroup.Description, Validators.required],
          TRL: [f.thirdFormGroup.TRL, Validators.required],
          TRLAssessor: [f.thirdFormGroup.TRLAssessor, Validators.required],
          Status: [f.thirdFormGroup.Status, Validators.required],
          Track: [f.thirdFormGroup.Track, Validators.required],
          IP: [f.thirdFormGroup.IP, Validators.required],
          Usefulness: [f.thirdFormGroup.Usefulness, Validators.required],
          Benchmarking: [f.thirdFormGroup.Benchmarking, Validators.required],
        });
        this.fourthFormGroup = this._formBuilder.group({
          TargetMarket: [f.fourthFormGroup.TargetMarket, Validators.required],
          MarketStrategy: [f.fourthFormGroup.MarketStrategy, Validators.required],
          MarketStrategyType: [f.fourthFormGroup.MarketStrategyType, Validators.required],
          BusinessModel: [f.fourthFormGroup.BusinessModel, Validators.required],
          BusinessModelType: [f.fourthFormGroup.BusinessModelType, Validators.required],
          Commercialization: [f.fourthFormGroup.Commercialization, Validators.required],
        });
        this.fifthFormGroup = this._formBuilder.group({
          Photo: [f.fifthFormGroup.Photo],
          Patent: [f.fifthFormGroup.Patent, Validators.required],
          CV: [f.fifthFormGroup.CV, Validators.required],
          Pitching: [f.fifthFormGroup.Pitching, Validators.required],
          BusinessModelCanvas: [f.fifthFormGroup.BusinessModelCanvas, Validators.required],
        });





      } else {
        this.firestore.collection('Users').doc(this.uuid).get().forEach(file => {
          this.firstFormGroup.get('Applicant').setValue(file.data().Name);
          this.firstFormGroup.get('Email').setValue(file.data().Email);
          this.firstFormGroup.get('Phone').setValue(file.data().TelephoneNo);
          this.firstFormGroup.get('School').setValue(file.data().SchoolName);
        });

      }
      if(this.router.url.includes('Edit')){
        if(!this.edit)
          this.router.navigate(['Home/exhibition'])
      }
      if(this.router.url.includes('View')){
        if(!this.edit)
          this.router.navigate(['Home/exhibition'])
      }

    });

  }

  ngOnInit() {

  }



  back() {
    this.router.navigate(['/Home/exhibition']);

  }
  getWordLeft(word) {
    return word.split(' ');
  }

  detectChanges1(event) {
    this.firstFormGroup.get('AuditedYearEnd').setValue(event);
  }

  selectedIndex = 0;





  teams(): FormArray {
    return this.firstFormGroup.get('TeamMembers') as FormArray;
  }
  addTeams() {
    this.teams().push(this.newTeams());
  }
  newTeams() {
    const USMStaff = new FormControl();
    USMStaff.setValue(true);

    return this._formBuilder.group({
      Name: ['', Validators.required],
      IdentityNumber: ['', Validators.required],
      Experience: ['', Validators.required],
      Percentages: ['', Validators.required],
      USMStaff,
    });
  }

  publication(): FormArray {
    return this.secondFormGroup.get('Publication') as FormArray;
  }
  addPublication() {
    this.publication().push(this.newPublication());
  }
  newPublication() {
    return this._formBuilder.group({
      Name: ['', Validators.required],
    });
  }

  intelliectual(): FormArray {
    return this.secondFormGroup.get('Intelliectual') as FormArray;
  }
  addIntelliectual() {
    this.intelliectual().push(this.newIntelliectual());
  }
  newIntelliectual() {
    return this._formBuilder.group({
      Name: ['', Validators.required],
    });
  }

  research(): FormArray {
    return this.secondFormGroup.get('Research') as FormArray;
  }
  addResearch() {
    this.research().push(this.newResearch());
  }
  newResearch() {
    return this._formBuilder.group({
      Name: ['', Validators.required],
    });
  }

  grant(): FormArray {
    return this.secondFormGroup.get('Grant') as FormArray;
  }
  addGrant() {
    this.grant().push(this.newGrant());
  }
  newGrant() {
    return this._formBuilder.group({
      Name: ['', Validators.required],
      Value: [0, Validators.required],
    });
  }

  consultation(): FormArray {
    return this.secondFormGroup.get('Consultation') as FormArray;
  }
  addConsultation() {
    this.consultation().push(this.newConsultation());
  }
  newConsultation() {
    return this._formBuilder.group({
      Name: ['', Validators.required],
      Value: [0, Validators.required],
    });
  }

  technology(): FormArray {
    return this.secondFormGroup.get('Technology') as FormArray;
  }
  addTechnology() {
    this.technology().push(this.newTechnology());
  }
  newTechnology() {
    return this._formBuilder.group({
      Name: ['', Validators.required],
    });
  }

  products(): FormArray {
    return this.secondFormGroup.get('Products') as FormArray;
  }
  addProducts() {
    this.products().push(this.newProducts());
  }
  newProducts() {
    return this._formBuilder.group({
      Name: ['', Validators.required],
    });
  }

  industrialPatners(): FormArray {
    return this.secondFormGroup.get('IndustrialPatners') as FormArray;
  }
  addIndustrialPatners() {
    this.industrialPatners().push(this.newIndustrialPatners());
  }
  newIndustrialPatners() {
    return this._formBuilder.group({
      Name: ['', Validators.required],
    });
  }

  validatePercentages = true;
  getTotalPercentage() {
    let share = this.teams().value;
    let total = 0;
    for (const e of share) {
      total += parseFloat(e.Percentages);
    }

    this.validatePercentages = (isNaN(total) ? 0 : total) <= 100;

    if (!this.validatePercentages)
      this.firstFormGroup.setErrors({ invalid: true })
    else
      this.firstFormGroup.setErrors(null)

    return isNaN(total) ? 0 : total;
  }



  save(event: any): void {
    let val = this.fifthFormGroup.get('Photo').value;
    this.fifthFormGroup.get('Photo').setValue([...val, ...event.target.files])
  }

  removeFile(index) {
    let val = this.fifthFormGroup.get('Photo').value;
    val.splice(index, 1);
    this.fifthFormGroup.get('Photo').setValue(val)
  }

  save4(event: any, value): void {
    this.fifthFormGroup.get(value).setValue(event.target.files[0])
  }

  remove(i, type) {
    switch (type) {
      case 'teams':
        this.teams().removeAt(i);
        break;
      case 'publication':
        this.publication().removeAt(i);
        break;
      case 'intelliectual':
        this.intelliectual().removeAt(i);
        break;
      case 'research':
        this.research().removeAt(i);
        break;
      case 'grant':
        this.grant().removeAt(i);
        break;
      case 'products':
        this.products().removeAt(i);
        break;
      case 'consultation':
        this.consultation().removeAt(i);
        break;
      case 'technology':
        this.technology().removeAt(i);
        break;
      case 'industrialPatners':
        this.industrialPatners().removeAt(i);
        break;
    }
  }


  dateFormat = new DateFormatService();

  saveAsDraft() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm save as draft';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();

          if (this.edit) {
            let five = this.fifthFormGroup.getRawValue();
            await this.uploadImages(five);

            let info = {
              UpdatedDate: new Date(),
              UpdatedBy: this.email,
              CreatedBy: this.email,
              Status: "Draft",
              firstFormGroup: this.firstFormGroup.value,
              secondFormGroup: this.secondFormGroup.value,
              thirdFormGroup: this.thirdFormGroup.value,
              fourthFormGroup: this.fourthFormGroup.value,
              UserID: this.uuid,
              fifthFormGroup: five,
            }
            await this.firestore.collection("Exhib").doc(this.editID).update(info);
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
            const infos = {
              message: "Draft has been saved by: " + this.email,
              date: new Date(),
              data: JSON.stringify(info),
            };
            await this.firestore.collection('ExhibLog').doc(dateFormat).set({ Date: new Date() });
            await this.firestore.collection('ExhibLog').doc(dateFormat).collection('User').add(infos);
            this.spinner.hide();
            this.toast.success("Saved Successfully");
            this.router.navigate(['/Home/exhibition']);

          } else {
            await this.firestore.collection("ExhibID").doc("ID").get().forEach(async item => {
              let ID = ('000' + item.data().ID).slice(-3);
              ID = `EX${new Date().getFullYear()}-${ID}`;

              let five = this.fifthFormGroup.getRawValue();
              await this.uploadImages(five);

              let info = {
                UpdatedDate: new Date(),
                CreatedDate: new Date(),
                UpdatedBy: this.email,
                CreatedBy: this.email,
                Status: "Draft",
                firstFormGroup: this.firstFormGroup.value,
                secondFormGroup: this.secondFormGroup.value,
                thirdFormGroup: this.thirdFormGroup.value,
                fourthFormGroup: this.fourthFormGroup.value,
                UserID: this.uuid,
                fifthFormGroup: five,
              }
              await this.firestore.collection("Exhib").doc(ID).set(info);
              await this.firestore.collection('ExhibDrafts').doc(this.uuid).delete();
              const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
              const infos = {
                message: "Draft has been saved by: " + this.email,
                date: new Date(),
                data: JSON.stringify(info),
              };
              await this.firestore.collection('ExhibLog').doc(dateFormat).set({ Date: new Date() });
              await this.firestore.collection('ExhibLog').doc(dateFormat).collection('User').add(infos);
              await this.firestore.collection('ExhibID').doc("ID").set({ ID: item.data().ID + 1 })

              this.spinner.hide();
              this.toast.success("Saved Successfully");
              this.router.navigate(['/Home/exhibition']);

            })
          }


        } catch (error) {
          this.toast.error(error, "Error happen");
          this.spinner.hide();

        }
      }
    })
  }

  saveAsSubmit() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm submit the new application?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          if (this.edit) {

            let five = this.fifthFormGroup.getRawValue();
            await this.uploadImages(five);
            let info = {
              UpdatedDate: new Date(),
              SubmittedDate: new Date(),
              CreatedBy: this.email,
              UpdatedBy: this.email,
              UserID: this.uuid,
              Status: "Submitted",
              firstFormGroup: this.firstFormGroup.value,
              secondFormGroup: this.secondFormGroup.value,
              thirdFormGroup: this.thirdFormGroup.value,
              fourthFormGroup: this.fourthFormGroup.value,
              fifthFormGroup: five,

            }
            await this.firestore.collection("Exhib").doc(this.editID).update(info);
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
            const infos = {
              message: "New application has been submitted by: " + this.email,
              date: new Date(),
              data: JSON.stringify(info),
            };
            await this.firestore.collection('ExhibDrafts').doc(this.uuid).delete();
            await this.firestore.collection('ExhibLog').doc(dateFormat).set({ Date: new Date() });
            await this.firestore.collection('ExhibLog').doc(dateFormat).collection('User').add(infos);
            this.spinner.hide();
            this.toast.success("Saved Successfully");
            this.router.navigate(['/Home/exhibition']);
          } else {
            await this.firestore.collection("ExhibID").doc("ID").get().forEach(async item => {
              let ID = ('000' + item.data().ID).slice(-3);
              ID = `EX${new Date().getFullYear()}-${ID}`;

              let five = this.fifthFormGroup.getRawValue();
              await this.uploadImages(five);
              let info = {
                UpdatedDate: new Date(),
                SubmittedDate: new Date(),
                CreatedDate: new Date(),
                UpdatedBy: this.email,
                Status: "Submitted",
                firstFormGroup: this.firstFormGroup.value,
                secondFormGroup: this.secondFormGroup.value,
                thirdFormGroup: this.thirdFormGroup.value,
                fourthFormGroup: this.fourthFormGroup.value,
                fifthFormGroup: five,
                UserID: this.uuid,
                CreatedBy: this.email,
              }
              await this.firestore.collection("Exhib").doc(ID).set(info);
              await this.firestore.collection('ExhibDrafts').doc(this.uuid).delete();

              const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
              const infos = {
                message: "New application has been submitted by: " + this.email,
                date: new Date(),
                data: JSON.stringify(info),
              };
              await this.firestore.collection('ExhibLog').doc(dateFormat).set({ Date: new Date() });
              await this.firestore.collection('ExhibLog').doc(dateFormat).collection('User').add(infos);
              await this.firestore.collection('ExhibID').doc("ID").set({ ID: item.data().ID + 1 })

              this.spinner.hide();
              this.toast.success("Update Successfully");
              this.router.navigate(['/Home/exhibition']);
            })

          }


        } catch (error) {
          this.toast.error(error, "Error happen");
          this.spinner.hide();

        }
      }
    })
  }

  async uploadImages(five) {
    let realAttachments = [];

    for (const p of five.Photo) {
      if (p && p.name && !p.url) {
        realAttachments.push(await this.uploadImageAsPromise(p));
      } else if (p.url) {
        realAttachments.push(p);
      }
    }
    five.Photo = realAttachments;




    if (five.Patent && five.Patent.name && !five.Patent.url) {
      let file = await this.uploadImageAsPromise(five.Patent);
      five.Patent = null;
      five.Patent = file;
    }
    if (five.CV && five.CV.name && !five.CV.url) {
      let file = await this.uploadImageAsPromise(five.CV);
      five.CV = null;
      five.CV = file;
    }
    if (five.Pitching && five.Pitching.name && !five.Pitching.url) {
      let file = await this.uploadImageAsPromise(five.Pitching);
      five.Pitching = null;
      five.Pitching = file;
    }
    if (five.BusinessModelCanvas && five.BusinessModelCanvas.name && !five.BusinessModelCanvas.url) {
      let file = await this.uploadImageAsPromise(five.BusinessModelCanvas);
      five.BusinessModelCanvas = null;
      five.BusinessModelCanvas = file;
    }


  }


  uploadImageAsPromise(imageFile) {
    return new Promise((resolve, reject) => {
      const filePath = `Exhib/${imageFile.name}_${new Date().getTime()}`;
      var storageRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, imageFile);
      let progress;
      task.snapshotChanges().pipe(
        finalize(() => {
          var downloadURL = storageRef.getDownloadURL();
          downloadURL.subscribe(async url => {
            let file = {
              name: imageFile.name,
              url: url,
            }
            resolve(file);
          });
        })
      ).subscribe(url => {
        if (url) {
          progress = url;
        }
      });
    });
  }

  async viewPPT(form) {
    const existingPdfBytes = await fetch(form.url).then(res => res.arrayBuffer())
    let file = new Blob([existingPdfBytes]);
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = form.name;
    anchor.href = fileURL;
    anchor.click();
  }

  SelectGoal(item) {
    this.secondFormGroup.get('Goal').setValue(item);
  }

  SelectTech(item) {
    this.secondFormGroup.get('TechnologyDriver').setValue(item);
  }

  SelectTechnology(item) {
    this.thirdFormGroup.get('TRL').setValue(item);
  }

  SelectGap(item) {
    this.fourthFormGroup.get('Commercialization').setValue(item);
  }

  async downloadSuSoTemplate() {
    const url = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/exhibition%20Pitch%20Deck%20CIC.pptx?alt=media&token=5a17fce7-4c71-4f78-a4ae-e699b8eeb79b'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    let file = new Blob([existingPdfBytes], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = "Exhibition Pitch Deck CIC.pptx";
    anchor.href = fileURL;
    anchor.click();
  }

  async downloadModelCanvas() {
    const url = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/business-model-canvas.docx?alt=media&token=1bbf176c-c0c6-49a0-ae03-11b432da3d9b'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    let file = new Blob([existingPdfBytes], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = "BusinessTemplate.docx";
    anchor.href = fileURL;
    anchor.click();
  }


}
