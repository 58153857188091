import { RemarkComponent } from './../../Shared/remark/remark.component';
import { FinalResultComponent } from './../../Shared/final-result/final-result.component';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatPaginator, MatDialog, PageEvent, Sort, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PDFDocument, StandardFonts, PDFPage, layoutMultilineText, TextAlignment, rgb } from 'pdf-lib';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { ExhibService } from 'src/app/Services/Utilities/exhib.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { SubmitFormComponent } from '../cic/submit-form/submit-form.component';

@Component({
  selector: 'app-admin-exhib',
  templateUrl: './admin-exhib.component.html',
  styleUrls: ['./admin-exhib.component.css']
})
export class AdminExhibComponent implements OnInit {

  filelist = [];
  sortedfilelist: any;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  searchbox = '';
  searchValue = '';
  sortedu = {
    active: '',
    direction: ''
  };
  dateRange: any;
  email: any;
  uuid: any;
  loading = true;

  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private storageRef: AngularFireStorage,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    public router: Router,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private dateSrv: DateFormatService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    public exhibSrv: ExhibService
  ) {
    this.spinner.show();
    this.exhibSrv.setExhib('');
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
      this.uuid = auth.uid;
      this.firestore.collection('Exhib', ref => ref.orderBy('CreatedDate', 'desc')).get().forEach(e => {
        e.forEach(file => {
          if (file.data().Status !== 'Draft') {
            const data = {
              ...file.data(),
              id: file.id,
              fileName: file.data().FileNames ? file.data().FileNames.join('\n') : "Not yet upload",
              CreatedDate: new Date(file.data().CreatedDate.toDate()),
              SubmittedDate: file.data().SubmittedDate ? new Date(file.data().SubmittedDate.toDate()) : null
            }
            this.filelist.push(data);
            this.sortedfilelist = this.filelist.slice(this.offset, (this.offset + this.pageSize));
            this.loading = false;
            this.length = this.filelist.length;
          }
        })
      });
      this.spinner.hide();
    });

  }

  ngOnInit() {
  }

  view(file) {
    this.exhibSrv.setExhib(file);
    this.router.navigate(['/Admin/exhibition/View'])
  }

  SetupFile() {
    this.loading = true;

    this.filelist = [];
    this.sortedfilelist = [];
    this.firestore.collection('Exhib', ref => ref.orderBy('CreatedDate', 'desc')).get().forEach(e => {
      e.forEach(file => {
        if (file.data().Status != 'Draft') {
          const data = {
            ...file.data(),
            id: file.id,
            fileName: file.data().FileNames ? file.data().FileNames.join('\n') : "Not yet upload",
            CreatedDate: new Date(file.data().CreatedDate.toDate()),
            SubmittedDate: new Date(file.data().SubmittedDate.toDate())
          }
          this.filelist.push(data);
          this.sortedfilelist = this.filelist.slice(this.offset, (this.offset + this.pageSize));
          this.loading = false;
          this.length = this.filelist.length;
        }
      })

    });
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    this.sortedfilelist = this.filelist.slice();
    this.sortFile();
    this.limitFile();
  }

  limitFile() {
    this.sortedfilelist = this.sortedfilelist.slice(this.offset, (this.offset + this.pageSize));
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.sortedfilelist = this.filelist.slice();
    if (this.status || this.fileName || this.dateRange) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !(this.status || this.fileName || this.dateRange)) {
      this.sortedfilelist = this.filelist.slice();
      this.limitFile();
      return;
    }

    this.sortedfilelist = this.sortedfilelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'file': return this.compare(a.fileName, b.fileName, isAsc);
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'app': return this.compare(a.firstFormGroup.Applicant, b.firstFormGroup.Applicant, isAsc);
        case 'name': return this.compare(a.firstFormGroup.Product, b.firstFormGroup.Product, isAsc);
        case 'school': return this.compare(a.firstFormGroup.School, b.firstFormGroup.School, isAsc);
        case 'site': return this.compare(a.secondFormGroup.TechnologyDriver, b.secondFormGroup.TechnologyDriver, isAsc);
        case 'date': return this.compareDate(a.SubmittedDate, b.SubmittedDate, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        default: return 0;
      }
    });
    this.limitFile();
  }

  sortFile() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.sortedfilelist = this.sortedfilelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'file': return this.compare(a.fileName, b.fileName, isAsc);
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'app': return this.compare(a.firstFormGroup.Applicant, b.firstFormGroup.Applicant, isAsc);
        case 'name': return this.compare(a.firstFormGroup.Product, b.firstFormGroup.Product, isAsc);
        case 'school': return this.compare(a.firstFormGroup.School, b.firstFormGroup.School, isAsc);
        case 'site': return this.compare(a.secondFormGroup.TechnologyDriver, b.secondFormGroup.TechnologyDriver, isAsc);
        case 'date': return this.compareDate(a.SubmittedDate, b.SubmittedDate, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  reset() {
    this.fileName = '';
    this.status = 'All';
    this.dateRange = '';
    this.SetupFile();
  }
  search() {
    let startDate;
    let endDate;

    this.sortedfilelist = this.filelist.filter(u => String(u.id).toLowerCase().includes(this.fileName.toLowerCase()));
    if (this.status !== 'All')
      this.sortedfilelist = this.sortedfilelist.filter(u => u.Status === this.status);
    if (typeof (this.dateRange) === 'object') {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        startDate = this.dateRange.startDate.toDate();
        endDate = this.dateRange.endDate.toDate();
        this.sortedfilelist = this.sortedfilelist.filter(u =>
          u.SubmittedDate.getTime() >= startDate.getTime() && u.SubmittedDate <= endDate.getTime());

      }
    }
    this.length = this.sortedfilelist.length;
    this.sortFile();
    this.limitFile();
  }

  DS() {
    let startDate;
    let endDate;
    this.sortedfilelist = this.filelist.filter(u => String(u.id).toLowerCase().includes(this.fileName.toLowerCase()));
    if (this.status !== 'All')
      this.sortedfilelist = this.sortedfilelist.filter(u => u.Status === this.status);
    if (typeof (this.dateRange) === 'object') {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        startDate = this.dateRange.startDate.toDate();
        endDate = this.dateRange.endDate.toDate();
        this.sortedfilelist = this.sortedfilelist.filter(u =>
          u.SubmittedDate.getTime() >= startDate.getTime() && u.SubmittedDate <= endDate.getTime());

      }
    }

    this.length = this.sortedfilelist.length;
    this.sortFile();
  }



  status = "All"
  fileName = '';
  applicationList = [];
  SelectStatus(status) {
    this.status = status;
  }

  dateRangeChange(e) {


  }

  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }


  SubmitForm(file) {
    this.dialog.open(SubmitFormComponent, {
      width: '70%',
      height: '80%',
      disableClose: true,
      data: file.id
    }).afterClosed().subscribe(r => {
      this.SetupFile();
    });
  }

  private dateFormat = new DateFormatService();

  edit(file) {
    this.exhibSrv.setExhib(file);
    this.router.navigate(['/Admin/exhibition/Edit'])

  }


  getTotalPercentage(f) {
    let total = 0;
    for (const e of f) {
      total += parseFloat(e.Percentages);
    }
    return isNaN(total) ? 0 : total;
  }

  draft(file) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm want to return back to draft for this application?'

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          await this.firestore.collection("Exhib").doc(file.id).update({ Status:'Draft', UpdatedDate: new Date(), UpdatedBy: this.email }).then(async e => {
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
            const info = {
              message: "Application " + file.id + " has been drafted back by Email: " + this.email,
              date: new Date()
            };
            await this.firestore.collection('ExhibLog').doc(dateFormat).set({ Date: new Date() });
            await this.firestore.collection('ExhibLog').doc(dateFormat).collection('User').add(info);

            this.spinner.hide();
            this.toaster.success("Return back to draft Successfully");
            this.SetupFile();
          });
        } catch (error) {
          this.toaster.error(error, "Error happen");
          this.spinner.hide();

        }
      }
    })
  }



  approve(file, value) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm want to update this application to ' + value + '?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          await this.firestore.collection("Exhib").doc(file.id).update({ Status: value, UpdatedDate: new Date(), UpdatedBy: this.email });
          file.Status = value;

          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: "Application " + file.id + " has been updated by Email: " + this.email + ". Status: " + value,
            date: new Date()
          };
          await this.firestore.collection('ExhibLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('ExhibLog').doc(dateFormat).collection('User').add(info);


          this.spinner.hide();
          this.toaster.success("Updated Successfully");
        } catch (error) {
          this.toaster.error(error, "Error happen");
          this.spinner.hide();
        }
      }
    })
  }

  finalResult(file) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    this.dialog.open(FinalResultComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          await this.firestore.collection("Exhib").doc(file.id).update({ Status: "Awarded", Result: data, UpdatedDate: new Date(), UpdatedBy: this.email });
          file.Status = "Awarded";
          file.Result = data;

          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: "Application " + file.id + " has been updated by Email: " + this.email + ". Status: Awarded",
            value: data,
            date: new Date()
          };
          await this.firestore.collection('ExhibLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('ExhibLog').doc(dateFormat).collection('User').add(info);


          this.spinner.hide();
          this.toaster.success("Awareded Successfully");
        } catch (error) {
          this.toaster.error(error, "Error happen");
          this.spinner.hide();
        }
      }
    })
  }

  async preview(file) {
    this.spinner.show();

    let f1 = file.firstFormGroup
    let f2 = file.secondFormGroup
    let f3 = file.thirdFormGroup
    let f4 = file.fourthFormGroup

    this.pdfDoc = await PDFDocument.create();
    const font = await this.pdfDoc.embedFont(StandardFonts.TimesRoman)

    const fontBold = await this.pdfDoc.embedFont(StandardFonts.TimesRomanBold)

    this.currentPage = this.pdfDoc.addPage();
    this.currentY = 800;
    this.currentX = 50;
    this.currentFontSize = 18;
    this.autoText('Technology Exhibition Submission', fontBold);
    this.currentY = this.currentY - 10;

    this.currentFontSize = 16;
    this.currentY = this.currentY - 10;
    this.autoText('Introduction', fontBold);

    this.currentFontSize = 12;
    this.autoText('Applicant Name ', font);
    this.autoText(': ' + f1.Applicant, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('School', font);
    this.autoText(': ' + f1.School, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Email', font);
    this.autoText(': ' + f1.Email, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Phone number', font);
    this.autoText(': ' + f1.Phone, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Project Title: ', font);
    this.currentFontSize = 12;
    this.autoText(f1.ProjectTitle, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Product/Technology Name: ', font);
    this.currentFontSize = 12;
    this.autoText(f1.Product, font);


    if (f1.TeamMembers.length > 0) {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('Team Members', fontBold);
    }

    let i = 1;
    for (const d of f1.TeamMembers) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Name, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Identity Number ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.IdentityNumber, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('USM Staff ', font, false, this.currentY, true, 70, true);
      this.autoText(d.USMStaff ? ': Yes' : ': No', font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Expertise/Experience ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' +d.Experience, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Percentage(%)', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Percentages, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

      i++
    }

    if (f1.TeamMembers.length > 0) {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 5;
      this.autoText(' Total Percentage: ' + this.getTotalPercentage(f1.TeamMembers), font);
    }

    this.currentFontSize = 12;

    this.currentFontSize = 16;
    this.currentY = this.currentY - 20;
    this.autoText('Technology Roadmap', fontBold);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Sustainable Development Goals: ', font);
    this.currentFontSize = 12;
    this.autoText(f2.Goal, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('My STIE 10x10 Science & Technology Driver: ', font);
    this.currentFontSize = 12;
    this.autoText(f2.TechnologyDriver, font);

    if (f2.Publication.length > 0) {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('List of Publication', fontBold);
    }

    i = 1;
    for (const d of f2.Publication) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Name, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }

    if (f2.Intelliectual.length > 0) {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('List of Intellectual Property', fontBold);
    }

    i = 1;
    for (const d of f2.Intelliectual) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Name, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }

    if (f2.Research.length > 0) {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('List of Research Collaboration Agreement', fontBold);
    }

    i = 1;
    for (const d of f2.Research) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Name, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }

    if (f2.Grant.length > 0) {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('List of Grants', fontBold);
    }

    i = 1;
    for (const d of f2.Grant) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Name, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Value (RM)', font, true, this.currentY, true, 70, true);
      this.autoText(': ' + d.Value, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }

    if (f2.Consultation.length > 0) {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('List of Consultation', fontBold);
    }

    i = 1;
    for (const d of f2.Consultation) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Name, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Value (RM)', font, true, this.currentY, true, 70, true);
      this.autoText(': ' + d.Value, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }


    if (f2.Technology.length > 0) {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('List of Technology Licencing', fontBold);
    }

    i = 1;
    for (const d of f2.Technology) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Name, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Value (RM)', font, true, this.currentY, true, 70, true);
      this.autoText(': ' + d.Value || 0, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }

    if (f2.Products.length > 0) {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('List of Product Commercialization', fontBold);
    }

    i = 1;
    for (const d of f2.Products) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Name, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Value (RM)', font, true, this.currentY, true, 70, true);
      this.autoText(': ' + d.Value || 0, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }

    if (f2.IndustrialPatners.length > 0) {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('List of Industrial Partners/Collaborators', fontBold);
    }

    i = 1;
    for (const d of f2.IndustrialPatners) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Name, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }

    this.currentFontSize = 16;
    this.currentY = this.currentY - 20;
    this.autoText('Technology Description', fontBold);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Executive Summary', font);
    this.currentFontSize = 12;
    this.autoText(f3.ExecutiveSummary, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Problem Statement', font);
    this.currentFontSize = 12;
    this.autoText(f3.ProblemStatement, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Technology Readiness Level (TRL)', font);
    this.currentFontSize = 12;
    this.autoText(f3.TRL, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('TRL Assessor', font);
    this.currentFontSize = 12;
    this.autoText(f3.TRLAssessor, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Product/Technology Status', font);
    this.currentFontSize = 12;
    this.autoText(f3.Status, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Usefulness and Application ', font);
    this.currentFontSize = 12;
    this.autoText(f3.Usefulness, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Technology Benchmarking', font);
    this.currentFontSize = 12;
    this.autoText(f3.Benchmarking, font);


    this.currentFontSize = 16;
    this.currentY = this.currentY - 20;
    this.autoText('Technology Commercialization', fontBold);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Target Market ', font);
    this.currentFontSize = 12;
    this.autoText(f4.TargetMarket, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Go-to-market Strategy ', font);
    this.currentFontSize = 12;
    this.autoText(f4.MarketStrategy, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Market Strategy Type  ', font);
    this.currentFontSize = 12;
    this.autoText(f4.MarketStrategyType.length > 0 ? f4.MarketStrategyType.join(',') : "None", font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Business Model ', font);
    this.currentFontSize = 12;
    this.autoText(f4.BusinessModel, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Commercialization Gap ', font);
    this.currentFontSize = 12;
    this.autoText(f4.Commercialization, font);



    const pdfBytes = await this.pdfDoc.save()
    let f = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(f);
    var anchor = document.createElement("a");
    anchor.download = file.id + ".pdf";
    anchor.href = fileURL;
    anchor.click();
    this.spinner.hide();
  }

  remark(file) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = file.Remark ? file.Remark : '';

    this.dialog.open(RemarkComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          await this.firestore.collection("Exhib").doc(file.id).update({ Remark: data, UpdatedDate: new Date(), UpdatedBy: this.email });
          file.Remark = data;
          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: "Application " + file.id + " has been updated by Email: " + this.email,
            value: data,
            date: new Date()
          };
          await this.firestore.collection('ExhibLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('ExhibLog').doc(dateFormat).collection('User').add(info);


          this.spinner.hide();
          this.toaster.success("Update Remark Successfully");
        } catch (error) {
          this.toaster.error(error, "Error happen");
          this.spinner.hide();
        }
      }
    })
  }

  getFilterStatus(status) {
    let r = this.filelist.filter(s => s.Status === status);
    if (r)
      return r.length;
    else
      return 0
  }

  getUnFilterStatus() {
    let r = this.filelist.filter(s => s.Status !== 'Draft');
    if (r)
      return r.length;
    else
      return 0
  }

  allowEdit(file) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    if (!file.AllowEdit) {
      file.AllowEdit = false;
    }
    let m = !file.AllowEdit ? 'to edit?' : 'not allow to edit?'
    dialogConfig.data = 'Confirm want to update this application ' + m;

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          if (!file.AllowEdit) {
            file.AllowEdit = false;
          }
          await this.firestore.collection("Exhib").doc(file.id).update({ AllowEdit: !file.AllowEdit, UpdatedDate: new Date(), UpdatedBy: this.email });
          file.AllowEdit = !file.AllowEdit;

          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: "Application " + file.id + " has been updated by Email: " + this.email + ". AllowEdit: " + !file.AllowEdit,
            date: new Date()
          };
          await this.firestore.collection('ExhibLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('ExhibLog').doc(dateFormat).collection('User').add(info);


          this.spinner.hide();
          this.toaster.success("Updated Successfully");
        } catch (error) {
          this.toaster.error(error, "Error happen");
          this.spinner.hide();
        }
      }
    })
  }


  pdfDoc: PDFDocument;
  currentX = 0;
  currentY = 0;
  currentPage: PDFPage;
  currentFontSize = 12

  async autoText(text, font, fixedYPosition = false, y = 0, fixedXPosition = false, x = 0, correctWidth = false) {
    let multiText = layoutMultilineText(text,
      {
        alignment: TextAlignment.Left,
        fontSize: this.currentFontSize,
        font: font,
        bounds: { x: this.currentX, y: this.currentY - this.currentFontSize - 5, width: correctWidth ? 350 : 500, height: 600 }
      })

    if (fixedYPosition)
      this.currentY = y;

    for (let i = 0; i < multiText.lines.length; i++) {
      this.currentPage.drawText(`${multiText.lines[i].text}`, {
        x: fixedXPosition ? x : this.currentX,
        y: this.currentY,
        size: this.currentFontSize,
        font: font,
        color: rgb(0, 0, 0)
      })

      if (this.currentY < 80) {
        this.currentPage = this.pdfDoc.addPage();
        this.currentY = 780
      }
      this.currentY = this.currentY - this.currentFontSize - 4;

    }
  }



}
