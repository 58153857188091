import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { SubmitFormComponent } from '../../cic/submit-form/submit-form.component';

@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.css']
})
export class UploadFormComponent implements OnInit {


  files = '';
  fileList =[];
  fileNames =[];
  downloadUrls = [];
  email:any;
  uuid:any;
  file="Select one application";
  title: any;
  type:any;
  loading = true;
  name: any;
  constructor(
    public dialogRef: MatDialogRef<SubmitFormComponent>,
    private toast: ToastrService,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.title = data.file;
      this.type = data.type;
      this.name = data.name
      this.angularFireAuth.authState.subscribe(auth => {
        this.email = auth.email;
        this.uuid = auth.uid;
      });
  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }

  remove(i){
    this.fileList.splice(i,1);
  }
  save(event: any): void {
    this.files = '';
    var selectedFiles = event.target.files;
    for (var i = 0; i < selectedFiles.length; i++) {
      this.files += '<br>File Name: ' + selectedFiles[i].name;
      this.files += '<br>File Type: ' + selectedFiles[i].type;
      this.files += '<br>----------------------------';
      this.fileList.push(selectedFiles[i]);
    }
  }
  private dateFormat = new DateFormatService();


  submit() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = {top: '5%'};
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm submit with these files?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          for (const item of this.fileList) {
              await this.uploadImageAsPromise(item);
          }
            let info = {
              UpdatedDate : new Date(),
              UpdatedBy : this.email,
              FileUrls: this.downloadUrls,
              FileNames: this.fileNames,
              Status: "Pending Approval",
            }
            await this.firestore.collection("DoubleTax").doc(this.title).update(info);
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
            const infos = {
              message: "Application "+this.title+" document has been uploaded by email: "  + this.email,
              date: new Date(),
              data: JSON.stringify(info),
            };
            await this.firestore.collection('DoubleTaxLog').doc(dateFormat).set({ Date: new Date() });
            await this.firestore.collection('DoubleTaxLog').doc(dateFormat).collection('User').add(infos);

            this.http.get(`https://cic-usm.as.r.appspot.com/newtax?form=${this.title}&user=${this.name}`).subscribe(result=>{
              console.log(result);
            })

          this.spinner.hide();
          this.toast.success("These may take few days to process.","Submit Successfully");
          this.dialogRef.close()
        }catch(error){
          this.toast.error(error,"Error happen");
        }
      }
    })
  }



  uploadImageAsPromise (imageFile) {
    return new Promise( (resolve, reject)=> {
        const filePath = `DoubleTax/${imageFile.name}_${new Date().getTime()}`;
        var storageRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, imageFile);
        let progress;
        task.snapshotChanges().pipe(
          finalize(() => {
            var downloadURL = storageRef.getDownloadURL();
            downloadURL.subscribe(async url => {
              this.downloadUrls.push(url);
              this.fileNames.push(imageFile.name);
              resolve(true);
            });
          })
        ).subscribe(url => {
          if (url) {
            progress = url;
          }
        });
    });
  }


}
