import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { MatDatepicker, MatDialog, MatTabChangeEvent, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { SusoService } from 'src/app/Services/Utilities/suso.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-view-suso',
  templateUrl: './view-suso.component.html',
  styleUrls: ['./view-suso.component.css']
})
export class AdminViewSusoComponent implements OnInit {

  todayDate = new Date();
  firstFormGroup = this._formBuilder.group({
    CompanyName: ['', Validators.required],
    IncorporationDate: [new Date(), Validators.required],
    CompanyRegistrationNo: ['', Validators.required],
    MOFRegistrationNo: [''],
    CompanyRegisteredAddress: ['', Validators.required],
    CompanyBusinessAddress: ['', Validators.required],
    OfficePhoneNumber: ['', Validators.required],
    CompanyEmail: ['', Validators.required],
    RegisteredPaidUpCapital: [0, Validators.required],
    SecretaryAndAddress: ['', Validators.required],
    AuditorAndAddress: ['', Validators.required],
    BankName: ['', Validators.required],
    BankAccountNo: ['', Validators.required],
    CompanyLogo: ['', Validators.required],
    AccumulatedRevenue: [0, Validators.required],
    BusinessProfile: ['', Validators.required],
    AuditedYearEnd: ['', Validators.required],
    USMEquity: [0, Validators.required],
    USMProxyRepresentative: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    Directors: this._formBuilder.array([]),
  });
  thirdFormGroup = this._formBuilder.group({
    Shareholders: this._formBuilder.array([]),
  });
  staffFormGroup = this._formBuilder.group({
    Staffs: this._formBuilder.array([]),
    numberOfIntern: ['', Validators.required],
  });
  fourthFormGroup = this._formBuilder.group({
    Techs: this._formBuilder.array([]),
  });
  fifthFormGroup = this._formBuilder.group({
    TotalRevenueLast2: [0, Validators.required],
    ProfitAfterTaxLast2: [0, Validators.required],
    TotalRevenuePrevious: [0, Validators.required],
    ProfitAfterTaxPrevious: [0, Validators.required],
    ProductSales: this._formBuilder.array([]),
  });
  sixFormGroup = this._formBuilder.group({
    Products: this._formBuilder.array([]),
  });
  sevenFormGroup = this._formBuilder.group({
    USMFunds: [0, Validators.required],
    Expenditures: this._formBuilder.array([]),
  });
  eightFormGroup = this._formBuilder.group({
    ExternalGrants: this._formBuilder.array([]),
  });
  nineFormGroup = this._formBuilder.group({
    SSMIncorpotrationCertificate: ['', Validators.required],
    CompanyProfileAttachments: ['', Validators.required],
    FirstYearAuditedReport: ['', Validators.required],
    SecondYearAuditedReport: ['', Validators.required],
    CompanyLogo: ['', Validators.required],
    TechnologyLicencingAgreement: ['', Validators.required],
  });

  @ViewChild('picker', { read: MatDatepicker, static: false }) datePickerElement = MatDatepicker;

  email;
  uuid;
  edit = false;
  editID;
  editEmail;
  constructor(
    private _formBuilder: FormBuilder,
    private toast: ToastrService,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private router: Router,
    public susoSrv: SusoService

  ) {

    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
      this.uuid = auth.uid;

      if (susoSrv.suso) {
        this.todayDate = new Date(susoSrv.suso.CreatedDate)
        this.edit = true;
        this.editID = susoSrv.suso.id;
        this.editEmail = susoSrv.suso.CreatedBy;
        let f = susoSrv.suso;

        this.firstFormGroup = this._formBuilder.group({
          CompanyName: [f.firstFormGroup.CompanyName, Validators.required],
          IncorporationDate: [new Date(f.firstFormGroup.IncorporationDate.toDate()), Validators.required],
          CompanyRegistrationNo: [f.firstFormGroup.CompanyRegistrationNo, Validators.required],
          MOFRegistrationNo: [f.firstFormGroup.MOFRegistrationNo],
          CompanyRegisteredAddress: [f.firstFormGroup.CompanyRegisteredAddress, Validators.required],
          CompanyBusinessAddress: [f.firstFormGroup.CompanyBusinessAddress, Validators.required],
          OfficePhoneNumber: [f.firstFormGroup.OfficePhoneNumber, Validators.required],
          CompanyEmail: [f.firstFormGroup.CompanyEmail, Validators.required],
          RegisteredPaidUpCapital: [f.firstFormGroup.RegisteredPaidUpCapital, Validators.required],
          SecretaryAndAddress: [f.firstFormGroup.SecretaryAndAddress, Validators.required],
          AuditorAndAddress: [f.firstFormGroup.AuditorAndAddress, Validators.required],
          BankName: [f.firstFormGroup.BankName, Validators.required],
          BankAccountNo: [f.firstFormGroup.BankAccountNo, Validators.required],
          CompanyLogo: [f.firstFormGroup.CompanyLogo, Validators.required],
          AccumulatedRevenue: [f.firstFormGroup.AccumulatedRevenue, Validators.required],
          BusinessProfile: [f.firstFormGroup.BusinessProfile, Validators.required],
          AuditedYearEnd: [f.firstFormGroup.AuditedYearEnd, Validators.required],
          USMEquity: [f.firstFormGroup.USMEquity, Validators.required],
          USMProxyRepresentative: [f.firstFormGroup.USMProxyRepresentative, Validators.required],
        });
        for (const dir of f.secondFormGroup.Directors) {
          this.directors().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
              IdentityNumber: [dir.IdentityNumber, Validators.required],
              Experience: [dir.Experience, Validators.required],
              DateOfAppointment: [new Date(dir.DateOfAppointment.toDate()), Validators.required],
              USMStaff: dir.USMStaff
            }));
        }

        for (const dir of f.thirdFormGroup.Shareholders) {
          this.shareholders().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
              IdentityNumber: [dir.IdentityNumber, Validators.required],
              Percentages: [dir.Percentages, Validators.required],
              USMStaff: dir.USMStaff,
            }));
        }

        this.fourthFormGroup = this._formBuilder.group({
          Techs: this._formBuilder.array([]),
        });

        let index = 0;
        for (const dir of f.fourthFormGroup.Techs) {
          this.techs().push(
            this._formBuilder.group({
              TechnologyName: [dir.TechnologyName, Validators.required],
              MainInventor: [dir.MainInventor, Validators.required],
              JointInventor: this._formBuilder.array([]),
              TechnologySummary: [dir.TechnologySummary, Validators.required],
              Grants: this._formBuilder.array([]),
              TypeOfIPs: [dir.TypeOfIPs, Validators.required],
              DateOfIPs: [dir.DateOfIPs?new Date(dir.DateOfIPs.toDate()): new Date(), Validators.required],
              TitleOfIPs: [dir.TitleOfIPs, Validators.required],
              Filling: [dir.Filling, Validators.required],
          }));

          for (const c of dir.JointInventor) {
            this.joints(index).push(
              this._formBuilder.group({
                Name: [c.Name, Validators.required],
              }));
          }

          for (const c of dir.Grants) {
            this.grants(index).push(
              this._formBuilder.group({
                Name: [c.Name, Validators.required],
              }));
          }
          index++;
        }




        this.staffFormGroup = this._formBuilder.group({
          Staffs: this._formBuilder.array([]),
          numberOfIntern: [f.staffFormGroup.numberOfIntern, Validators.required],
        });
        for (const dir of f.staffFormGroup.Staffs) {
          this.staffs().push(
            this._formBuilder.group({
              Position: [dir.Position, Validators.required],
              NumberOfStaff: [dir.NumberOfStaff, Validators.required],
              EmploymentStatus: [dir.EmploymentStatus, Validators.required],
              KWSP: dir.KWSP
            }));
        }


        this.fifthFormGroup = this._formBuilder.group({
          TotalRevenueLast2: [f.fifthFormGroup.TotalRevenueLast2, Validators.required],
          ProfitAfterTaxLast2: [f.fifthFormGroup.ProfitAfterTaxLast2, Validators.required],
          TotalRevenuePrevious: [f.fifthFormGroup.TotalRevenuePrevious, Validators.required],
          ProfitAfterTaxPrevious: [f.fifthFormGroup.ProfitAfterTaxPrevious, Validators.required],
          ProductSales: this._formBuilder.array([]),
        });

        for (const dir of f.fifthFormGroup.ProductSales) {
          this.sales().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
              Sales: [dir.Sales, Validators.required],
              Date: [new Date(dir.Date.toDate()), Validators.required],
              Client: [dir.Client, Validators.required],
            }));
        }
         index = 0;
        for (const dir of f.sixFormGroup.Products) {
          this.products().push(
            this._formBuilder.group({
              Clients: this._formBuilder.array([]),
              CoInventors: this._formBuilder.array([]),
              Attachments: [dir.Attachments],
              Name: [dir.Name, Validators.required],
              Amount: [dir.Amount, Validators.required],
              Year: [dir.Year, Validators.required],
              MainInventor: [dir.MainInventor, Validators.required],
            }));

            for (const c of dir.Clients) {
              this.clients(index).push(
                this._formBuilder.group({
                  Name: [c.Name, Validators.required],
                  Address: [c.Address, Validators.required],
                }));
            }

            for (const c of dir.CoInventors) {
              this.coInventors(index).push(
                this._formBuilder.group({
                  Name: [c.Name, Validators.required],
                }));
            }
            index++;
        }

        this.sevenFormGroup = this._formBuilder.group({
          USMFunds: [f.sevenFormGroup.USMFunds, Validators.required],
          Expenditures: this._formBuilder.array([]),
        });

        for (const dir of f.sevenFormGroup.Expenditures) {
          this.expenditures().push(
            this._formBuilder.group({
              Details: [dir.Details, Validators.required],
              Amount: [dir.Amount, Validators.required],
              Attachments: [dir.Attachments],
            }));
        }

        for (const dir of f.eightFormGroup.ExternalGrants) {

          if(dir.Duration){
            dir.Duration.startDate = new Date(dir.Duration.startDate.toDate())
            dir.Duration.endDate = new Date(dir.Duration.endDate.toDate())

          }
          this.externalGrants().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
              FunderName: [dir.FunderName, Validators.required],
              Duration: [dir.Duration, Validators.required],
              GrantValue: [dir.GrantValue, Validators.required],
              Attachment: [dir.Attachment],
            }));
        }

        this.nineFormGroup = this._formBuilder.group({
          SSMIncorpotrationCertificate: [f.nineFormGroup.SSMIncorpotrationCertificate, Validators.required],
          CompanyProfileAttachments: [f.nineFormGroup.CompanyProfileAttachments, Validators.required],
          FirstYearAuditedReport: [f.nineFormGroup.FirstYearAuditedReport, Validators.required],
          SecondYearAuditedReport: [f.nineFormGroup.SecondYearAuditedReport, Validators.required],
          CompanyLogo: [f.nineFormGroup.CompanyLogo, Validators.required],
          TechnologyLicencingAgreement: [f.nineFormGroup.TechnologyLicencingAgreement, Validators.required],
        });

      }else{
        this.router.navigate(['/Admin/monitoring']);

      }

    });

  }

  ngOnInit() {

  }



  back() {
    this.router.navigate(['/Admin/monitoring']);

  }
  getWordLeft(word) {
    return word.split(' ');
  }

  detectChanges1(event) {
    this.firstFormGroup.get('AuditedYearEnd').setValue(event);
  }

  selectedIndex = 0;


  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedIndex = tabChangeEvent.index;
  }

  addDirectors() {
    this.directors().push(this.newDirectors());
  }
  newDirectors() {
    const USMStaff = new FormControl();
    USMStaff.setValue(true);

    return this._formBuilder.group({
      Name: ['', Validators.required],
      IdentityNumber: ['', Validators.required],
      Experience: ['', Validators.required],
      DateOfAppointment: [new Date(), Validators.required],
      USMStaff,
    });

  }

  directors(): FormArray {
    return this.secondFormGroup.get('Directors') as FormArray;
  }

  shareholders(): FormArray {
    return this.thirdFormGroup.get('Shareholders') as FormArray;
  }
  addShareHolders() {
    this.shareholders().push(this.newShareHolders());
  }
  newShareHolders() {
    const USMStaff = new FormControl();
    USMStaff.setValue(true);

    return this._formBuilder.group({
      Name: ['', Validators.required],
      IdentityNumber: ['', Validators.required],
      Percentages: ['', Validators.required],
      USMStaff,
    });
  }

  validatePercentages = true;
  getTotalPercentage() {
    let share = this.shareholders().value;
    let total = 0;
    for (const e of share) {
      total += parseFloat(e.Percentages);
    }

    this.validatePercentages = (isNaN(total) ? 0 : total) <= 100;

    if (!this.validatePercentages)
      this.thirdFormGroup.setErrors({ invalid: true })
    else
      this.thirdFormGroup.setErrors(null)

    return isNaN(total) ? 0 : total;
  }



  joints(i): FormArray {
    return this.techs().at(i).get('JointInventor') as FormArray;
  }

  addJoints(i) {
    this.joints(i).push(this.newJoints());
  }

  newJoints() {
    return this._formBuilder.group({
      Name: ['', Validators.required],
    });
  }

  techs(): FormArray {
    return this.fourthFormGroup.get('Techs') as FormArray;
  }

  addTechs() {
    this.techs().push(this.newTechs());
  }

  newTechs() {
    return this._formBuilder.group({
      TechnologyName: ['', Validators.required],
      MainInventor: ['', Validators.required],
      JointInventor: this._formBuilder.array([]),
      TechnologySummary: ['', Validators.required],
      Grants: this._formBuilder.array([]),
      TypeOfIPs: ['', Validators.required],
      DateOfIPs: [new Date(), Validators.required],
      TitleOfIPs: ['', Validators.required],
      Filling: ['', Validators.required],
    });
  }

  grants(i): FormArray {
    return this.techs().at(i).get('Grants') as FormArray;
  }

  addGrants(i) {
    this.grants(i).push(this.newGrants());
  }

  newGrants() {
    return this._formBuilder.group({
      Name: ['', Validators.required],
    });
  }

  sales(): FormArray {
    return this.fifthFormGroup.get('ProductSales') as FormArray;
  }

  addSales() {
    this.sales().push(this.newSales());
  }

  newSales() {
    return this._formBuilder.group({
      Name: ['', Validators.required],
      Sales: ['', Validators.required],
      Date: [new Date(), Validators.required],
      Client: ['', Validators.required],
    });
  }

  products(): FormArray {
    return this.sixFormGroup.get('Products') as FormArray;
  }

  addProducts() {
    this.products().push(this.newProducts());
  }

  newProducts() {
    return this._formBuilder.group({
      Name: ['', Validators.required],
      Amount: ['', Validators.required],
      Year: ['', Validators.required],
      Clients: this._formBuilder.array([]),
      MainInventor: ['', Validators.required],
      CoInventors: this._formBuilder.array([]),
      Attachments: [[]],
    });
  }

  clients(i): FormArray {
    return this.products().at(i).get('Clients') as FormArray;
  }

  addClients(i) {
    this.clients(i).push(this.newClients());
  }

  newClients() {
    return this._formBuilder.group({
      Name: ['', Validators.required],
      Address: ['', Validators.required],
    });
  }

  coInventors(i): FormArray {
    return this.products().at(i).get('CoInventors') as FormArray;
  }

  addCoInventors(i) {
    this.coInventors(i).push(this.newCoInventors());
  }

  newCoInventors() {
    return this._formBuilder.group({
      Name: ['', Validators.required],
    });
  }

  save(event: any, index): void {
    let val = this.products().at(index).get('Attachments').value;
    this.products().at(index).get('Attachments').setValue([...val, ...event.target.files])
  }

  removeFile(index, i) {
    let val = this.products().at(index).get('Attachments').value;
    val.splice(i, 1);
    this.products().at(index).get('Attachments').setValue(val)
  }


  addExpenditures() {
    this.expenditures().push(this.newExpenditures());
  }
  newExpenditures() {
    return this._formBuilder.group({
      Details: ['', Validators.required],
      Amount: ['', Validators.required],
      Attachments: [[]],
    });

  }

  expenditures(): FormArray {
    return this.sevenFormGroup.get('Expenditures') as FormArray;
  }


  save2(event: any, index): void {
    let val = this.expenditures().at(index).get('Attachments').value;
    this.expenditures().at(index).get('Attachments').setValue([...val, ...event.target.files])
  }



  removeFile2(index, i) {
    let val = this.expenditures().at(index).get('Attachments').value;
    val.splice(i, 1);
    this.expenditures().at(index).get('Attachments').setValue(val)
  }

  externalGrants(): FormArray {
    return this.eightFormGroup.get('ExternalGrants') as FormArray;
  }

  addExternalGrants() {
    this.externalGrants().push(this.newExternalGrants());
  }

  newExternalGrants() {
    return this._formBuilder.group({
      Name: ['', Validators.required],
      FunderName: ['', Validators.required],
      Duration: ['', Validators.required],
      GrantValue: ['', Validators.required],
      Attachment: [''],
    });

  }

  save3(event: any, index): void {
    this.externalGrants().at(index).get('Attachment').setValue(event.target.files[0])
  }

  save4(event: any, value): void {
    this.nineFormGroup.get(value).setValue(event.target.files[0])
  }






  dateFormat = new DateFormatService();


  async uploadImages(six, seven, eight, nine) {

    for (const p of six.Products) {
      let realAttachments = [];
      for (var a of p.Attachments) {
        if (a && a.name && !a.url) {
          realAttachments.push(await this.uploadImageAsPromise(a));
        }else if(a.url){
          realAttachments.push(a);
        }
      }
      p.Attachments = realAttachments;
    }

    for (const e of seven.Expenditures) {
      let realAttachments = [];
      for (var a of e.Attachments) {
        if (a && a.name  && !a.url) {
          realAttachments.push(await this.uploadImageAsPromise(a));
        }else if(a.url){
          realAttachments.push(a);
        }
      }
      e.Attachments = realAttachments;
    }

    for (const e of eight.ExternalGrants) {
      if (e.Attachment && e.Attachment.name  && !e.Attachment.url) {
        let file = await this.uploadImageAsPromise(e.Attachment);
        e.Attachment = null;
        e.Attachment = file;
      }
      if(e.Duration){
        e.Duration.endDate = new Date(e.Duration.endDate)
        e.Duration.startDate = new Date(e.Duration.startDate)
      }
    }


    if (nine.SSMIncorpotrationCertificate && nine.SSMIncorpotrationCertificate.name  && !nine.SSMIncorpotrationCertificate.url){
      let file = await this.uploadImageAsPromise(nine.SSMIncorpotrationCertificate);
      nine.SSMIncorpotrationCertificate =  null;
      nine.SSMIncorpotrationCertificate =  file;
    }
    if (nine.CompanyProfileAttachments && nine.CompanyProfileAttachments.name  && !nine.CompanyProfileAttachments.url){
      let file = await this.uploadImageAsPromise(nine.CompanyProfileAttachments);
      nine.CompanyProfileAttachments =  null;
      nine.CompanyProfileAttachments =  file;
    }
    if (nine.FirstYearAuditedReport && nine.FirstYearAuditedReport.name  && !nine.FirstYearAuditedReport.url){
      let file = await this.uploadImageAsPromise(nine.FirstYearAuditedReport);
      nine.FirstYearAuditedReport =  null;
      nine.FirstYearAuditedReport =  file;
    }
    if (nine.SecondYearAuditedReport && nine.SecondYearAuditedReport.name  && !nine.SecondYearAuditedReport.url){
      let file = await this.uploadImageAsPromise(nine.SecondYearAuditedReport);
      nine.SecondYearAuditedReport =  null;
      nine.SecondYearAuditedReport =  file;
    }
    if (nine.CompanyLogo && nine.CompanyLogo.name  && !nine.CompanyLogo.url){
      let file = await this.uploadImageAsPromise(nine.CompanyLogo);
      nine.CompanyLogo =  null;
      nine.CompanyLogo =  file;
    }
    if (nine.TechnologyLicencingAgreement && nine.TechnologyLicencingAgreement.name  && !nine.TechnologyLicencingAgreement.url){
      let file = await this.uploadImageAsPromise(nine.TechnologyLicencingAgreement);
      nine.TechnologyLicencingAgreement =  null;
      nine.TechnologyLicencingAgreement =  file;
    }

  }


  uploadImageAsPromise(imageFile) {
    return new Promise((resolve, reject) => {
      const filePath = `SUSOPM/${imageFile.name}_${new Date().getTime()}`;
      var storageRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, imageFile);
      let progress;
      task.snapshotChanges().pipe(
        finalize(() => {
          var downloadURL = storageRef.getDownloadURL();
          downloadURL.subscribe(async url => {

            let file = {
              name:imageFile.name,
              url:url,
            }
            resolve(file);
          });
        })
      ).subscribe(url => {
        if (url) {
          progress = url;
        }
      });
    });
  }

  async viewPPT(form){
    const existingPdfBytes = await fetch(form.url).then(res => res.arrayBuffer())
    let file = new Blob([existingPdfBytes]);
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = form.name;
    anchor.href = fileURL;
    anchor.click();
  }
  staffs(): FormArray {
    return this.staffFormGroup.get('Staffs') as FormArray;
  }
  addStaffs() {
    this.staffs().push(this.newStaffs());
  }
  newStaffs() {
    const KWSP = new FormControl();
    KWSP.setValue(true);

    return this._formBuilder.group({
      Position: ['', Validators.required],
      NumberOfStaff: ['', Validators.required],
      EmploymentStatus: [[], Validators.required],
      KWSP
    });
  }
}
