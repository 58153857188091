import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import copyright from '../../Shared/JSON/copyright.json';
import patent from '../../Shared/JSON/patent.json';
import PCMS from '../../Shared/JSON/PCMS.json';
import trademark from '../../Shared/JSON/trademark.json';
import suso from '../../Shared/JSON/suso.json';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { MatTabChangeEvent } from '@angular/material';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  totalCopyright;
  totalPatent;
  totalPCMS;
  totaltrademark;
  totalSuso;
  dataCopyright;
  dataPatent;
  dataPCMS;
  dataTrademark;
  dataSuso
  mobileQuery: MediaQueryList;

  pieSuso: Chart;
  piePatentC: Chart;
  piePatentS: Chart;

  piePCMSC: Chart;
  piePCMSS: Chart;

  pieCopyrightC: Chart;
  pieCopyrightS: Chart;

  pieTrademark: Chart;


  private mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this.mobileQueryListener);


    this.totalCopyright = copyright.Data.length;
    this.totalPatent = patent.Data.length;
    this.totalPCMS = PCMS.Data.length;
    this.totaltrademark = trademark.Data.length;
    this.totalSuso = suso.Data.length;

    this.dataCopyright = copyright.Data;
    this.dataPatent = patent.Data;
    this.dataPCMS = PCMS.Data;
    this.dataTrademark = trademark.Data;
    this.dataSuso = suso.Data;

   }

  ngOnInit() {


  }

  ngAfterViewInit(): void {
    this.updateChart(null)
  }

  login(){
    this.router.navigate(['Login']);

  }

  updateChart(tabChangeEvent: MatTabChangeEvent){

    setTimeout(() => {
      if(tabChangeEvent){
        switch(tabChangeEvent.index){
          case 0:
          this.pieSuso = new Chart('pieSuso', {
            type: 'doughnut',
            data: {
              labels: ["Medical Services",
                      "Polymer Engineering",
                      "Aerospace Engineering",
                      "Food",
                      "ICT and Electronic Engineering",
                      "Biotechnology",
                      "Agriculture",
                      "Education"],
              datasets: [{
                label: "Business Nature",
                backgroundColor: ['rgba(39, 69, 245, 0.61)',
                                  '#21d59b',
                                  '#8d8d8d',
                                  '#f99600',
                                  '#00ffc4',
                                  'rgba(245, 39, 43, 0.61)',
                                  'rgba(234, 88, 255, 0.61)',
                                  '#d3ff00'],
                data: [5, 2, 1, 1, 1, 1, 1, 1],
              }]
            },
            options: {
              responsive:true,
maintainAspectRatio:false,
              title:{
                display:true,
                text:'Business Nature',
              },
              legend: {
                position: 'bottom',
                display: this.mobileQuery.matches?false:true,
              },

            }

          })
          break;

          case 1 :
          const countries = this.dataPatent
          .map(({ Country }) => Country)
          .reduce((names, Country) => {
            const count = names[Country] || 0;
            names[Country] = count + 1;
            return names;
          }, {});

          let result = Object.values(countries);
          let key = Object.keys(countries);


          const schools = this.dataPatent
          .map(({ SchoolsCenters }) => SchoolsCenters)
          .reduce((names, SchoolsCenters) => {
            const count = names[SchoolsCenters] || 0;
            names[SchoolsCenters] = count + 1;
            return names;
          }, {});

          let result1 = Object.values(schools);
          let key1 = Object.keys(schools);

          this.piePatentC= new Chart('piePatentC', {
            type: 'doughnut',
            data: {
              labels: key,
              datasets: [{
                label: "Country",
                backgroundColor: ['rgba(39, 69, 245, 0.61)',
                                  '#21d59b',
                                  '#8d8d8d',
                                  '#f99600',
                                  '#00ffc4',
                                  'rgba(245, 39, 43, 0.61)',
                                  'rgba(234, 88, 255, 0.61)',
                                  '#d3ff00',
                                  'rgba(39, 69, 245, 0.61)',
                                  'rgba(235, 162, 30, 0.61)',
                                  'rgba(201, 185, 155, 0.61)',
                                  'rgba(192, 155, 201, 0.61)',
                                  'rgba(172, 242, 198, 0.61)',
                                  'rgba(235, 242, 198, 0.61)',
                                  'rgba(204, 185, 205, 0.61)',
                                  'rgba(108, 55, 255, 0.61)',
                                  'rgba(107, 255, 243, 0.61)',
                                  'rgba(107, 255, 58, 0.61)',
                                  'rgba(255, 59, 59, 0.61)',
                                  'rgba(125, 125, 125, 0.61)',
                                  'rgba(255, 190, 59, 0.61)',
                                  'rgba(255, 59, 59, 0.61)',
              ],
              data: result,
            }]
          },
          options: {
            responsive:true,
maintainAspectRatio:false,
            title:{
              display:true,
              text:'Country',
            },
            legend: {
              position: 'bottom',
              display: this.mobileQuery.matches?false:true,
            },
          }
          });

          this.piePatentS= new Chart('piePatentS', {
              type: 'doughnut',
              data: {
                labels: key1,
                datasets: [{
                  label: "Schools/Centers",
                  backgroundColor: ['rgba(39, 69, 245, 0.61)',
                                    '#21d59b',
                                    '#8d8d8d',
                                    '#f99600',
                                    '#00ffc4',
                                    'rgba(245, 39, 43, 0.61)',
                                    'rgba(234, 88, 255, 0.61)',
                                    '#d3ff00',
                                    'rgba(39, 69, 245, 0.61)',
                                    'rgba(235, 162, 30, 0.61)',
                                    'rgba(201, 185, 155, 0.61)',
                                    'rgba(192, 155, 201, 0.61)',
                                    'rgba(172, 242, 198, 0.61)',
                                    'rgba(235, 242, 198, 0.61)',
                                    'rgba(204, 185, 205, 0.61)',
                                    'rgba(108, 55, 255, 0.61)',
                                    'rgba(107, 255, 243, 0.61)',
                                    'rgba(107, 255, 58, 0.61)',
                                    'rgba(255, 59, 59, 0.61)',
                                    'rgba(125, 125, 125, 0.61)',
                                    'rgba(255, 190, 59, 0.61)',
                                    'rgba(255, 59, 59, 0.61)',
                                    'rgba(39, 69, 245, 0.61)',
                                    '#21d59b',
                                    '#8d8d8d',
                                    '#f99600',
                                    '#00ffc4',
                                    'rgba(245, 39, 43, 0.61)',
                ],
                data: result1,
              }]
            },
            options: {
              responsive:true,
              maintainAspectRatio:false,
              title: {
                display: true,
                text: 'School/Centers'
              },
              legend: {
                position: 'bottom',
                display: this.mobileQuery.matches?false:true,
                labels: {
                  generateLabels: chart => chart.data.labels.map((l, i) => ({
                    datasetIndex: 0,
                    index: i,
                    text: l.substr(0,20) + (l.length > 20?'...':''),
                    fillStyle: chart.data.datasets[0].backgroundColor[i],
                    hidden: false
                  }))
                }
              },
            }

          })
          break;
          case 2 :
            const countriesP = this.dataPCMS
            .map(({ Country }) => Country)
            .reduce((names, Country) => {
              const count = names[Country] || 0;
              names[Country] = count + 1;
              return names;
            }, {});

            let resultP = Object.values(countriesP);
            let keyP = Object.keys(countriesP);


            const schoolsP = this.dataPCMS
            .map(({ SchoolsCenters }) => SchoolsCenters)
            .reduce((names, SchoolsCenters) => {
              const count = names[SchoolsCenters] || 0;
              names[SchoolsCenters] = count + 1;
              return names;
            }, {});

            let resultP1 = Object.values(schoolsP);
            let keyP1 = Object.keys(schoolsP);

            this.piePCMSC= new Chart('piePCMSC', {
              type: 'doughnut',
              data: {
                labels: keyP,
                datasets: [{
                  label: "Country",
                  backgroundColor: ['rgba(39, 69, 245, 0.61)',
                                    '#21d59b',
                                    '#8d8d8d',
                                    '#f99600',
                                    '#00ffc4',
                                    'rgba(245, 39, 43, 0.61)',
                                    'rgba(234, 88, 255, 0.61)',
                                    '#d3ff00',
                                    'rgba(39, 69, 245, 0.61)',
                                    'rgba(235, 162, 30, 0.61)',
                                    'rgba(201, 185, 155, 0.61)',
                                    'rgba(192, 155, 201, 0.61)',
                                    'rgba(172, 242, 198, 0.61)',
                                    'rgba(235, 242, 198, 0.61)',
                                    'rgba(204, 185, 205, 0.61)',
                                    'rgba(108, 55, 255, 0.61)',
                                    'rgba(107, 255, 243, 0.61)',
                                    'rgba(107, 255, 58, 0.61)',
                                    'rgba(255, 59, 59, 0.61)',
                                    'rgba(125, 125, 125, 0.61)',
                                    'rgba(255, 190, 59, 0.61)',
                                    'rgba(255, 59, 59, 0.61)',
                ],
                data: resultP,
              }]
            },
            options: {
              responsive:true,
maintainAspectRatio:false,
              title:{
                display:true,
                text:'Country',
              },
              legend: {
                position: 'bottom',
                display: this.mobileQuery.matches?false:true,
              },
            }
            });

            this.piePCMSS= new Chart('piePCMSS', {
                type: 'doughnut',
                data: {
                  labels: keyP1,
                  datasets: [{
                    label: "Schools/Centers",
                    backgroundColor: ['rgba(39, 69, 245, 0.61)',
                                      '#21d59b',
                                      '#8d8d8d',
                                      '#f99600',
                                      '#00ffc4',
                                      'rgba(245, 39, 43, 0.61)',
                                      'rgba(234, 88, 255, 0.61)',
                                      '#d3ff00',
                                      'rgba(39, 69, 245, 0.61)',
                                      'rgba(235, 162, 30, 0.61)',
                                      'rgba(201, 185, 155, 0.61)',
                                      'rgba(192, 155, 201, 0.61)',
                                      'rgba(172, 242, 198, 0.61)',
                                      'rgba(235, 242, 198, 0.61)',
                                      'rgba(204, 185, 205, 0.61)',
                                      'rgba(108, 55, 255, 0.61)',
                                      'rgba(107, 255, 243, 0.61)',
                                      'rgba(107, 255, 58, 0.61)',
                                      'rgba(255, 59, 59, 0.61)',
                                      'rgba(125, 125, 125, 0.61)',
                                      'rgba(255, 190, 59, 0.61)',
                                      'rgba(255, 59, 59, 0.61)',
                                      'rgba(39, 69, 245, 0.61)',
                                      '#21d59b',
                                      '#8d8d8d',
                                      '#f99600',
                                      '#00ffc4',
                                      'rgba(245, 39, 43, 0.61)',
                  ],
                  data: resultP1,
                }]
              },
              options: {
                responsive:true,
maintainAspectRatio:false,
                title: {
                  display: true,
                  text: 'School/Centers'
                },
                legend: {
                  position: 'bottom',
                  display: this.mobileQuery.matches?false:true,
                  labels: {
                    generateLabels: chart => chart.data.labels.map((l, i) => ({
                      datasetIndex: 0,
                      index: i,
                      text: l.substr(0,20) + (l.length > 20?'...':''),
                      fillStyle: chart.data.datasets[0].backgroundColor[i],
                      hidden: false
                    }))
                  }
                },
              }

            })
          break;
          case 3 :
            const LanguageC = this.dataCopyright
            .map(({ Language }) => Language)
            .reduce((names, Language) => {
              const count = names[Language] || 0;
              names[Language] = count + 1;
              return names;
            }, {});

            let resultC = Object.values(LanguageC);
            let keyC = Object.keys(LanguageC);


            const schoolsC = this.dataCopyright
            .map(({ SchoolsCenters }) => SchoolsCenters)
            .reduce((names, SchoolsCenters) => {
              const count = names[SchoolsCenters] || 0;
              names[SchoolsCenters] = count + 1;
              return names;
            }, {});

            let resultC1 = Object.values(schoolsC);
            let keyC1 = Object.keys(schoolsC);

            this.pieCopyrightC= new Chart('pieCopyrightC', {
              type: 'doughnut',
              data: {
                labels: keyC,
                datasets: [{
                  label: "Language",
                  backgroundColor: ['rgba(39, 69, 245, 0.61)',
                                    '#21d59b',
                                    '#8d8d8d',
                                    '#f99600',
                                    '#00ffc4',
                                    'rgba(245, 39, 43, 0.61)',
                                    'rgba(234, 88, 255, 0.61)',
                                    '#d3ff00',
                                    'rgba(39, 69, 245, 0.61)',
                                    'rgba(235, 162, 30, 0.61)',
                                    'rgba(201, 185, 155, 0.61)',
                                    'rgba(192, 155, 201, 0.61)',
                                    'rgba(172, 242, 198, 0.61)',
                                    'rgba(235, 242, 198, 0.61)',
                                    'rgba(204, 185, 205, 0.61)',
                                    'rgba(108, 55, 255, 0.61)',
                                    'rgba(107, 255, 243, 0.61)',
                                    'rgba(107, 255, 58, 0.61)',
                                    'rgba(255, 59, 59, 0.61)',
                                    'rgba(125, 125, 125, 0.61)',
                                    'rgba(255, 190, 59, 0.61)',
                                    'rgba(255, 59, 59, 0.61)',
                ],
                data: resultC,
              }]
            },
            options: {
              responsive:true,
maintainAspectRatio:false,
              title:{
                display:true,
                text:'Language',
              },
              legend: {
                position: 'bottom',
                display: this.mobileQuery.matches?false:true,
              },
            }
            });

            this.pieCopyrightS= new Chart('pieCopyrightS', {
                type: 'doughnut',
                data: {
                  labels: keyC1,
                  datasets: [{
                    label: "Schools/Centers",
                    backgroundColor: ['rgba(39, 69, 245, 0.61)',
                                      '#21d59b',
                                      '#8d8d8d',
                                      '#f99600',
                                      '#00ffc4',
                                      'rgba(245, 39, 43, 0.61)',
                                      'rgba(234, 88, 255, 0.61)',
                                      '#d3ff00',
                                      'rgba(39, 69, 245, 0.61)',
                                      'rgba(235, 162, 30, 0.61)',
                                      'rgba(201, 185, 155, 0.61)',
                                      'rgba(192, 155, 201, 0.61)',
                                      'rgba(172, 242, 198, 0.61)',
                                      'rgba(235, 242, 198, 0.61)',
                                      'rgba(204, 185, 205, 0.61)',
                                      'rgba(108, 55, 255, 0.61)',
                                      'rgba(107, 255, 243, 0.61)',
                                      'rgba(107, 255, 58, 0.61)',
                                      'rgba(255, 59, 59, 0.61)',
                                      'rgba(125, 125, 125, 0.61)',
                                      'rgba(255, 190, 59, 0.61)',
                                      'rgba(255, 59, 59, 0.61)',
                                      'rgba(39, 69, 245, 0.61)',
                                      '#21d59b',
                                      '#8d8d8d',
                                      '#f99600',
                                      '#00ffc4',
                                      'rgba(245, 39, 43, 0.61)',
                                      'rgba(234, 88, 255, 0.61)',
                                      '#d3ff00',
                                      'rgba(39, 69, 245, 0.61)',
                                      'rgba(235, 162, 30, 0.61)',
                                      'rgba(201, 185, 155, 0.61)',
                                      'rgba(192, 155, 201, 0.61)',
                                      'rgba(172, 242, 198, 0.61)',
                                      'rgba(235, 242, 198, 0.61)',
                                      'rgba(204, 185, 205, 0.61)',
                                      'rgba(108, 55, 255, 0.61)',
                                      'rgba(107, 255, 243, 0.61)',
                                      'rgba(107, 255, 58, 0.61)',
                                      'rgba(255, 59, 59, 0.61)',
                                      'rgba(125, 125, 125, 0.61)',
                                      'rgba(255, 190, 59, 0.61)',
                                      'rgba(255, 59, 59, 0.61)',
                  ],
                  data: resultC1,
                }]
              },
              options: {
                responsive:true,
                maintainAspectRatio:false,
                title: {
                  display: true,
                  text: 'School/Centers'
                },
                legend: {
                  position: 'bottom',
                  display: this.mobileQuery.matches?false:true,
                  labels: {
                    generateLabels: chart => chart.data.labels.map((l, i) => ({
                      datasetIndex: 0,
                      index: i,
                      text: l.substr(0,10) + (l.length > 10?'...':''),
                      fillStyle: chart.data.datasets[0].backgroundColor[i],
                      hidden: false
                    }))
                  }
                },
              }

            })
          break;
          case 4:
            const schoolsT = this.dataTrademark
            .map(({ SchoolsCenters }) => SchoolsCenters)
            .reduce((names, SchoolsCenters) => {
              const count = names[SchoolsCenters] || 0;
              names[SchoolsCenters] = count + 1;
              return names;
            }, {});

            let resultT = Object.values(schoolsT);
            let keyT = Object.keys(schoolsT);


            this.pieTrademark = new Chart('pieTrademark', {
              type: 'doughnut',
              data: {
                labels: keyT,
                datasets: [{
                  label: "Trademark",
                  backgroundColor: ['rgba(39, 69, 245, 0.61)',
                  '#21d59b',
                  '#8d8d8d',
                  '#f99600',
                  '#00ffc4',
                  'rgba(245, 39, 43, 0.61)',
                  'rgba(234, 88, 255, 0.61)',
                  '#d3ff00',
                  'rgba(39, 69, 245, 0.61)',
                  'rgba(235, 162, 30, 0.61)',
                  'rgba(201, 185, 155, 0.61)',
                  'rgba(192, 155, 201, 0.61)',
                  'rgba(172, 242, 198, 0.61)',
                  'rgba(235, 242, 198, 0.61)',
                  'rgba(204, 185, 205, 0.61)',
                  'rgba(108, 55, 255, 0.61)',
                  'rgba(107, 255, 243, 0.61)',
                  'rgba(107, 255, 58, 0.61)',
                  'rgba(255, 59, 59, 0.61)',
                  'rgba(125, 125, 125, 0.61)',
                  'rgba(255, 190, 59, 0.61)',
                  'rgba(255, 59, 59, 0.61)',
                  'rgba(39, 69, 245, 0.61)',
                  '#21d59b',
                  '#8d8d8d',
                  '#f99600',
                  '#00ffc4',
                  'rgba(245, 39, 43, 0.61)',
                  'rgba(234, 88, 255, 0.61)',
                  '#d3ff00',
                  'rgba(39, 69, 245, 0.61)',
                  'rgba(235, 162, 30, 0.61)',
                  'rgba(201, 185, 155, 0.61)',
                  'rgba(192, 155, 201, 0.61)',
                  'rgba(172, 242, 198, 0.61)',
                  'rgba(235, 242, 198, 0.61)',
                  'rgba(204, 185, 205, 0.61)',
                  'rgba(108, 55, 255, 0.61)',
                  'rgba(107, 255, 243, 0.61)',
                  'rgba(107, 255, 58, 0.61)',
                  'rgba(255, 59, 59, 0.61)',
                  'rgba(125, 125, 125, 0.61)',
                  'rgba(255, 190, 59, 0.61)',
                  'rgba(255, 59, 59, 0.61)',
                ],
                  data: resultT,
                }]
              },
              options: {
                responsive:true,
maintainAspectRatio:false,
                title:{
                  display:true,
                  text:'Trademark',
                },
                legend: {
                  position: 'bottom',
                  display: this.mobileQuery.matches?false:true,
                  labels: {
                    generateLabels: chart => chart.data.labels.map((l, i) => ({
                      datasetIndex: 0,
                      index: i,
                      text: l.substr(0,10) + (l.length > 10?'...':''),
                      fillStyle: chart.data.datasets[0].backgroundColor[i],
                      hidden: false
                    }))
                  }
                },
              }

            })
            break;

        }
      }else{
        this.pieSuso = new Chart('pieSuso', {
          type: 'doughnut',
          data: {
            labels: ["Medical Services",
                    "Polymer Engineering",
                    "Aerospace Engineering",
                    "Food",
                    "ICT and Electronic Engineering",
                    "Biotechnology",
                    "Agriculture",
                    "Education"],
            datasets: [{
              label: "Business Nature",
              backgroundColor: ['rgba(39, 69, 245, 0.61)',
                                '#21d59b',
                                '#8d8d8d',
                                '#f99600',
                                '#00ffc4',
                                'rgba(245, 39, 43, 0.61)',
                                'rgba(234, 88, 255, 0.61)',
                                '#d3ff00'],
              data: [5, 2, 1, 1, 1, 1, 1, 1],
            }]
          },
          options: {
            responsive:true,
maintainAspectRatio:false,
            title:{
              display:true,
              text:'Business Nature',
            },
            legend: {
              position: 'bottom',
              display: this.mobileQuery.matches?false:true,
            },

          }

        })
      }



    }, 100);

  }

}
