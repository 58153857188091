import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-remark',
  templateUrl: './remark.component.html',
  styleUrls: ['./remark.component.css']
})
export class RemarkComponent implements OnInit {

  remark:any;
  message: any;
  title: any;
  constructor(
    private dialogRef: MatDialogRef<RemarkComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
  ) {
    if(data.title){
      this.remark = data.Remark;
      this.title = data.title;
    }else{
      this.remark = data;
    }
  }

  ngOnInit(){

  }
  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(this.remark);
  }

}
