import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as firebase from 'firebase';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { UserInfoService } from 'src/app/Services/Utilities/user-info.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent implements OnInit {
  dateRange: any;

  message: any;
  hidePassword = true;
  hidePassword2 = true;
  hidePassword3 = true;

  loginModel: any = {
    oldpassword: '',
    newpassword: '',
    confirmpassword: '',

  };
  role: string;
  passcodes = ["", "", "", ""];

  hidePasscode = true;
  hidePasscode2 = true;
  hidePasscode3 = true;
  hidePasscode4 = true;
  email: any;
  exhibOpen =false;
  constructor(
    private dialogRef: MatDialogRef<AccountSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
    private angularFireAuth: AngularFireAuth,
    private userinfoSrv: UserInfoService,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage
  ) {
    this.email = 'cicadmin@cic.com'
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
      this.userID = auth.uid;

      this.firestore.collection('SUSOPMSettings').doc('settings').get().forEach(e=>{
        this.dateRange = {
          startDate :  moment(e.data().startDate.toDate()),
          endDate :  moment(e.data().endDate.toDate()),
        }
      })
      this.firestore.collection('Users').doc(this.userID).get().forEach(file => {

        this.registerModel = {
          name: file.data().Name,
          nameE: false,
          telephoneNo: file.data().TelephoneNo,
          telephoneNoE: false,
          email: file.data().Email,
          emailE: false,
          ic: file.data().IC,
          icE: false,
          pos: file.data().Position,
          posE: false,
          schoolName: file.data().SchoolName,
          schoolNameE: false,
          add1: file.data().Address1,
          add1E: false,
          add2: file.data().Address2,
          add3: file.data().Address3,
          postcode: file.data().Postcode,
          postcodeE: false,
          city: file.data().City,
          cityE: false,
          state: file.data().State,
          stateE: false,
          country: file.data().Country,
          countryE: false,
          nationality: file.data().Nationality,
          nationalityE: false,
          faxNo: file.data().FaxNo,
        }

      });
    });
    this.userinfoSrv.currentRole.subscribe(role => this.role = role);
    this.message = data;

  }

  async ngOnInit() {

  }
  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }

  change() {
    if (this.loginModel.newpassword !== this.loginModel.confirmpassword) {
      this.toast.error('New Password not same', "Warning")
      return;
    }

    this.angularFireAuth.authState.subscribe(async authState => {
      if (authState === null) {
      } else {
        const cre = firebase.auth.EmailAuthProvider.credential(authState.email, this.loginModel.oldpassword);
        authState.reauthenticateWithCredential(cre).then(r => {
          authState.updatePassword(this.loginModel.newpassword).then(update => {
            this.toast.success('Change password successful', "Success")
            this.dialogRef.close(true);
          })
        }).catch(error => {
          this.toast.error('Wrong old password', "Failed to change")

        })

      }

    })
  }

  registerModel = {
    name: '',
    nameE: false,
    telephoneNo: '',
    telephoneNoE: false,
    email: '',
    emailE: false,
    ic: '',
    icE: false,
    pos: '',
    posE: false,
    schoolName: '',
    schoolNameE: false,
    add1: '',
    add1E: false,
    add2: '',
    add3: '',
    postcode: '',
    postcodeE: false,
    city: '',
    cityE: false,
    state: '',
    stateE: false,
    country: '',
    countryE: false,
    nationality: '',
    nationalityE: false,
    faxNo: '',
  };
  userID: string;

  private dateFormat = new DateFormatService();
  flag = false;
  validate(value, name) {
    if (!value) {
      this.toast.error("Please fill in the " + name);
      this.flag = true;
      return true;
    }
    return false;

  }

  async submit() {
    this.flag = false;

    this.registerModel.nameE = this.validate(this.registerModel.name, "Full Name");
    this.registerModel.telephoneNoE = this.validate(this.registerModel.telephoneNo, "Phone number");
    this.registerModel.icE = this.validate(this.registerModel.ic, "IC / Passport no");
    this.registerModel.posE = this.validate(this.registerModel.pos, "Position / Designation");
    this.registerModel.schoolNameE = this.validate(this.registerModel.schoolName, "School Name/ Centre Name");
    this.registerModel.add1E = this.validate(this.registerModel.add1, "School Address Line 1");
    this.registerModel.postcodeE = this.validate(this.registerModel.postcode, "Postcode");
    this.registerModel.cityE = this.validate(this.registerModel.city, "City");
    this.registerModel.stateE = this.validate(this.registerModel.state, "State");
    this.registerModel.countryE = this.validate(this.registerModel.country, "Country");
    this.registerModel.nationalityE = this.validate(this.registerModel.nationality, "Nationality");


    if (this.flag) {
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm submit with this information?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          let newUserInfo = {
            UpdatedDate: new Date(),
            UpdatedBy: this.email,
            Email: this.email,
            Role: "User",
            Name: this.registerModel.name,
            TelephoneNo: this.registerModel.telephoneNo,
            IC: this.registerModel.ic,
            Position: this.registerModel.pos,
            SchoolName: this.registerModel.schoolName,
            Address1: this.registerModel.add1,
            Address2: this.registerModel.add2,
            Address3: this.registerModel.add3,
            State: this.registerModel.state,
            Postcode: this.registerModel.postcode,
            Country: this.registerModel.country,
            City: this.registerModel.city,
            Nationality: this.registerModel.nationality,
            FaxNo: this.registerModel.faxNo
          }
          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: "Profile information has been updated" + this.email,
            date: new Date()
          };
          await this.firestore.collection("Users").doc(this.userID).update(newUserInfo);
          await this.firestore.collection('UsersLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('UsersLog').doc(dateFormat).collection('User').add(info);
          this.toast.success("Profile information has been updated", 'Updated successfully', { timeOut: 3000 });

        } catch (error) {
          this.toast.error(error, 'Error', { timeOut: 3000 });

        }
      }
    });

  }

  SelectPos(pos) {
    this.registerModel.pos = pos;
  }

  save(){
    if (typeof (this.dateRange) === 'object') {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        let startDate = this.dateRange.startDate.toDate();
        let endDate = this.dateRange.endDate.toDate();
        let info ={
          startDate,
          endDate,
          updatedBy: this.email,
          updatedDate: new Date()
        }
        this.firestore.collection('SUSOPMSettings').doc('settings').set(info);
      }
    }
    this.firestore.collection('ExhibitionSettings').doc('settings').set( {'Edit':this.exhibOpen});
    this.toast.success('Updated successfully');


  }


}
