import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PidGrantService {


  pidGrant:any;
  constructor() { }

  setPidGrant(pidGrant){
    this.pidGrant = pidGrant;
  }
}
