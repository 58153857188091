import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {

  timeOut = 0;
  constructor(
    public dialogRef: MatDialogRef<VerifyEmailComponent>,
    private toast: ToastrService,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) public data) {
      if(data)
        this.timeOut = data;
  }

  ngOnInit() {
    setInterval(()=>{
      if(this.timeOut !== 0)
        this.timeOut = this.timeOut - 1;
    },1000)
  }

  close(): void {
    this.dialogRef.close();
  }

  submit(){
    this.angularFireAuth.authState.subscribe(async authState =>{
      if(authState === null){
      } else{
        if(!authState.emailVerified){
          authState.sendEmailVerification().then(e=>{
            console.log(e)
          }).catch(error=>{
            console.log(error);
          });
          this.timeOut = 300;
        }
      }
    })
  }

}
