import { AdminViewDoubleTaxComponent } from './Components/admin-double_tax/admin-view-double_tax/admin-view-double_tax.component';
import { AdminDoubleTaxComponent } from './Components/admin-double_tax/admin-double_tax.component';
import { ViewDoubleTaxComponent } from './Components/double_tax/view-double_tax/view-double_tax.component';
import { NewDoubleTaxComponent } from './Components/double_tax/new-double_tax/new-double_tax.component';
import { DoubleTaxComponent } from './Components/double_tax/double_tax.component';
import { AdminSusoDashboardComponent } from './Components/admin-suso-dashboard/admin-suso-dashboard.component';
import { AdminViewExhibComponent } from './Components/admin-exhib/view-exhib/view-exhib.component';
import { AdminExhibComponent } from './Components/admin-exhib/admin-exhib.component';
import { NewApplicationComponent } from './Components/funds/new-application/new-application.component';
import { ViewExhibComponent } from './Components/exhib/view-exhib/view-exhib.component';
import { NewExhibComponent } from './Components/exhib/new-exhib/new-exhib.component';
import { ExhibComponent } from './Components/exhib/exhib.component';
import { ViewSusoComponent } from './Components/suso/view-suso/view-suso.component';
import { NewSusoComponent } from './Components/suso/new-suso/new-suso.component';
import { AdminSusoComponent } from './Components/admin-suso/admin-suso.component';
import { SusoComponent } from './Components/suso/suso.component';
import { AdminViewFundsComponent } from './Components/admin-funds/admin-view-funds/admin-view-funds.component';
import { ViewFundsComponent } from './Components/funds/view-funds/view-funds.component';
import { AdminFundsComponent } from './Components/admin-funds/admin-funds.component';
import { FundsComponent } from './Components/funds/funds.component';
import { DashboardAdminComponent } from './Components/dashboard-admin/dashboard-admin.component';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { AdminCICComponent } from './Components/admin-cic/admin-cic.component';
import { CICComponent } from './Components/cic/cic.component';
import { NotFoundComponent } from './Components/not-found/not-found.component';
import { LayoutComponent } from './Components/Home/layout/layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './Components/Home/login/login.component';
import { AdminViewSusoComponent } from './Components/admin-suso/view-suso/view-suso.component';
import { IndexComponent } from './Components/index/index.component';
import { PidGrantComponent } from './Components/pid-grant/pid-grant.component';
import { NewPidGrantComponent } from './Components/pid-grant/new-pid-grant/new-pid-grant.component';
import { ViewPidGrantComponent } from './Components/pid-grant/view-pid-grant/view-pid-grant.component';
import { AdminPidGrantComponent } from './Components/admin-pid-grant/admin-pid-grant.component';
import { AdminViewPidGrantComponent } from './Components/admin-pid-grant/admin-view-prid-grant/admin-view-pid-grant.component';


const routes: Routes = [
{ path: '', component: IndexComponent },

{ path: 'Login', component: LoginComponent },
{ path: '404', component:  NotFoundComponent},

{ path: 'Home', component: LayoutComponent,
  children: [
    {
      path: 'copyright',
      component: CICComponent
    },
    // {
    //   path: 'dashboard',
    //   component: DashboardComponent
    // },
    {
      path: 'suso',
      component: FundsComponent,
    },
    {
      path: 'suso/View',
      component: ViewFundsComponent
    },
    {
      path: 'suso/Edit',
      component: NewApplicationComponent
    },
    {
      path: 'suso/Add',
      component: NewApplicationComponent
    },
    {
      path: 'monitoring',
      component: NewSusoComponent
    },
    {
      path: 'monitoring/View',
      component: ViewSusoComponent
    },
    {
      path: 'exhibition',
      component: ExhibComponent
    },
    {
      path: 'exhibition/Add',
      component: NewExhibComponent
    },
    {
      path: 'exhibition/Edit',
      component: NewExhibComponent
    },
    {
      path: 'exhibition/View',
      component: ViewExhibComponent
    },
    {
      path: 'double_tax',
      component: DoubleTaxComponent
    },
    {
      path: 'double_tax/Add',
      component: NewDoubleTaxComponent
    },
    {
      path: 'double_tax/Edit',
      component: NewDoubleTaxComponent
    },
    {
      path: 'double_tax/View',
      component: ViewDoubleTaxComponent
    },
    {
      path: 'pid-grant',
      component: PidGrantComponent
    },
    {
      path: 'pid-grant/Add',
      component: NewPidGrantComponent
    },
    {
      path: 'pid-grant/Edit',
      component: NewPidGrantComponent
    },
    {
      path: 'pid-grant/View',
      component: ViewPidGrantComponent
    },

  ]
},
{ path: 'Admin', component: LayoutComponent,
  children: [
    {
      path: 'copyright',
      component: AdminCICComponent
    },
    {
      path: 'dashboard',
      component: AdminSusoDashboardComponent
    },
    {
      path: 'suso',
      component: AdminFundsComponent
    },
    {
      path: 'suso/View',
      component: AdminViewFundsComponent
    },
    {
      path: 'monitoring',
      component: AdminSusoComponent
    },
    {
      path: 'monitoring/View',
      component: AdminViewSusoComponent
    },
    {
      path: 'exhibition',
      component: AdminExhibComponent
    },
    {
      path: 'exhibition/View',
      component: AdminViewExhibComponent
    },
    {
      path: 'double_tax',
      component: AdminDoubleTaxComponent
    },
    {
      path: 'double_tax/View',
      component: AdminViewDoubleTaxComponent
    },
    {
      path: 'pid-grant',
      component: AdminPidGrantComponent
    },
    {
      path: 'pid-grant/View',
      component: AdminViewPidGrantComponent
    },
  ]
},

];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
