import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-final-result',
  templateUrl: './final-result.component.html',
  styleUrls: ['./final-result.component.css']
})
export class FinalResultComponent implements OnInit {

  result = this._formBuilder.group({
    Gold: false,
    Silver: false,
    Bronze: false,
    SpecialAward: false,
    Award: false,
  });

  constructor(
    private dialogRef: MatDialogRef<FinalResultComponent>,
    private toast: ToastrService,
    private _formBuilder: FormBuilder
  ) {
  }

  ngOnInit(){

  }
  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    const ary = []
    for (const [key, value] of Object.entries(this.result.getRawValue())) {
      if(value){
        ary.push(key)
      }
    }

    this.dialogRef.close(ary);
  }

}
