import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExhibService {

  exhib:any;
  constructor() { }

  setExhib(exhib){
    this.exhib = exhib;
  }
}
