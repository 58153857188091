import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FundsServiceService {
  file:any;
  constructor() { }

  setFile(file){
    this.file = file;
  }
}
