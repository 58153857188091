import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatPaginator, MatDialog, PageEvent, Sort, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';
import { FundsServiceService } from 'src/app/Services/Utilities/funds-service.service';
import { SusoService } from 'src/app/Services/Utilities/suso.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { SubmitFormComponent } from '../cic/submit-form/submit-form.component';
import { NewApplicationComponent } from '../funds/new-application/new-application.component';

@Component({
  selector: 'app-admin-suso',
  templateUrl: './admin-suso.component.html',
  styleUrls: ['./admin-suso.component.css']
})
export class AdminSusoComponent implements OnInit {

  filelist = [];
  sortedfilelist: any;
  length = 0;
  pageSize = 30;
  pageSizeOptions: number[] = [30, 50, 100];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  searchbox = '';
  searchValue = '';
  sortedu = {
    active: '',
    direction: ''
  };
  dateRange: any;
  email: any;
  uuid: any;
  loading = true;

  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private storageRef: AngularFireStorage,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    public router: Router,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private dateSrv: DateFormatService,
    private excelHelper: ExcelHelperService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    public susoSrv: SusoService
  ) {
    this.spinner.show();
    this.susoSrv.setSuso('');
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
      this.uuid = auth.uid;
      this.firestore.collection('SUSOPM', ref => ref.orderBy('CreatedDate', 'desc')).get().forEach(e => {
        e.forEach(file => {
        if (file.data().Status != 'Draft') {
          const data = {
            ...file.data(),
            id: file.id,
            CreatedDate: new Date(file.data().CreatedDate.toDate()),
            UpdatedDate: new Date(file.data().UpdatedDate.toDate()),
            SubmittedDate: new Date(file.data().SubmittedDate.toDate())
          }
          this.filelist.push(data);
          this.sortedfilelist = this.filelist.slice(this.offset, (this.offset + this.pageSize));
          this.loading = false;
          this.length = this.filelist.length;

        }
      })
      });
      this.spinner.hide();
    });

  }

  ngOnInit() {
  }

  view(file) {
    this.susoSrv.setSuso(file);
    this.router.navigate(['/Admin/monitoring/View'])
  }

  SetupFile() {
    this.loading = true;

    this.filelist = [];
    this.firestore.collection('SUSOPM', ref => ref.orderBy('CreatedDate', 'desc')).get().forEach(e => {
      e.forEach(file => {
        if (file.data().Status != 'Draft') {
          const data = {
            ...file.data(),
            id: file.id,
            CreatedDate: new Date(file.data().CreatedDate.toDate()),
            SubmittedDate: new Date(file.data().SubmittedDate.toDate()),
            UpdatedDate: new Date(file.data().UpdatedDate.toDate())
          }
          this.filelist.push(data);
        }
        this.sortedfilelist = this.filelist.slice(this.offset, (this.offset + this.pageSize));
        this.loading = false;
        this.length = this.filelist.length;
      })
    });
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    this.sortedfilelist = this.filelist.slice();
    this.sortFile();
    this.limitFile();
  }

  limitFile() {
    this.sortedfilelist = this.sortedfilelist.slice(this.offset, (this.offset + this.pageSize));
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.sortedfilelist = this.filelist.slice();
    if (this.status || this.fileName || this.dateRange) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !(this.status || this.fileName || this.dateRange)) {
      this.sortedfilelist = this.filelist.slice();
      this.limitFile();
      return;
    }

    this.sortedfilelist = this.sortedfilelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'id': return this.compare(a.CreatedBy, b.CreatedBy, isAsc);
        case 'company': return this.compare(a.firstFormGroup.CompanyName, b.firstFormGroup.CompanyName, isAsc);
        case 'revenue': return this.compare(a.firstFormGroup.AccumulatedRevenue, b.firstFormGroup.AccumulatedRevenue, isAsc);
        case 'int': return this.compare(a.fourthFormGroup.Techs.length, b.fourthFormGroup.Techs.length, isAsc);
        case 'emp': return this.compare(this.getValue(a.staffFormGroup.Staffs), this.getValue(b.staffFormGroup.Staffs), isAsc);
        case 'intern': return this.compare(a.staffFormGroup.numberOfIntern, b.staffFormGroup.numberOfIntern, isAsc);
        case 'prod': return this.compare(a.sixFormGroup.Products.length, b.sixFormGroup.Products.length, isAsc);
        case 'dateU': return this.compareDate(a.UpdatedDate, b.UpdatedDate, isAsc);
        default: return 0;
      }
    });
    this.limitFile();
  }

  sortFile() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.sortedfilelist = this.sortedfilelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'id': return this.compare(a.CreatedBy, b.CreatedBy, isAsc);
        case 'company': return this.compare(a.firstFormGroup.CompanyName, b.firstFormGroup.CompanyName, isAsc);
        case 'revenue': return this.compare(a.firstFormGroup.AccumulatedRevenue, b.firstFormGroup.AccumulatedRevenue, isAsc);
        case 'int': return this.compare(a.fourthFormGroup.Techs.length, b.fourthFormGroup.Techs.length, isAsc);
        case 'emp': return this.compare(this.getValue(a.staffFormGroup.Staffs), this.getValue(b.staffFormGroup.Staffs), isAsc);
        case 'intern': return this.compare(a.staffFormGroup.numberOfIntern, b.staffFormGroup.numberOfIntern, isAsc);
        case 'prod': return this.compare(a.sixFormGroup.Products.length, b.sixFormGroup.Products.length, isAsc);
        case 'dateU': return this.compareDate(a.UpdatedDate, b.UpdatedDate, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  reset() {
    this.fileName = '';
    this.status = 'All';
    this.dateRange = '';
    this.SetupFile();
  }
  search() {
    let startDate;
    let endDate;

    this.sortedfilelist = this.filelist.filter(u => String(u.CreatedBy).toLowerCase().includes(this.fileName.toLowerCase()));
    if (this.status !== 'All')
      this.sortedfilelist = this.sortedfilelist.filter(u => u.Status === this.status);
    if (typeof (this.dateRange) === 'object') {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        startDate = this.dateRange.startDate.toDate();
        endDate = this.dateRange.endDate.toDate();
        this.sortedfilelist = this.sortedfilelist.filter(u =>
          u.UpdatedDate.getTime() >= startDate.getTime() && u.UpdatedDate <= endDate.getTime());

      }
    }
    this.length = this.sortedfilelist.length;
    this.sortFile();
    this.limitFile();
  }

  DS() {
    let startDate;
    let endDate;

    this.sortedfilelist = this.filelist.filter(u => String(u.CreatedBy).toLowerCase().includes(this.fileName.toLowerCase()));
    if (this.status !== 'All')
      this.sortedfilelist = this.sortedfilelist.filter(u => u.Status === this.status);
    if (typeof (this.dateRange) === 'object') {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        startDate = this.dateRange.startDate.toDate();
        endDate = this.dateRange.endDate.toDate();
        this.sortedfilelist = this.sortedfilelist.filter(u =>
          u.UpdatedDate.getTime() >= startDate.getTime() && u.UpdatedDate <= endDate.getTime());

      }
    }

    this.length = this.sortedfilelist.length;
    this.sortFile();
  }

  assign(lorry, id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Assign this File ' + id + ' to ' + lorry.name
    };
    lorry.totalNum++;
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.firestore.collection('Files').doc(id)
          .update({ LorryType: lorry.name, No: lorry.totalNum }).then(s => {
            const r = this.sortedfilelist.find(d => d.id === id);
            r.LorryType = lorry.name;
            this.firestore.collection('lorries').doc(lorry.id).update({ totalNum: lorry.totalNum });

          });
      }
    });
  }

  status = "All"
  fileName = '';
  applicationList = [];
  SelectStatus(status) {
    this.status = status;
  }

  dateRangeChange(e) {


  }

  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }


  SubmitForm(file) {
    this.dialog.open(SubmitFormComponent, {
      width: '70%',
      height: '80%',
      disableClose: true,
      data: file.id
    }).afterClosed().subscribe(r => {
      this.SetupFile();
    });
  }

  private dateFormat = new DateFormatService();


  getTotalValue(value) {
    let total = 0;

    if (this.filelist.length > 0) {
      switch (value) {
        case 'SUSO':
          for (const f of this.filelist) {
            total += f.firstFormGroup.CompanyRegistrationNo ? 1 : 0
          }
          break;
        case 'Revenue':
          for (const f of this.filelist) {
            total += parseFloat(f.firstFormGroup.AccumulatedRevenue) || 0
          }
          break;
        case 'productCommercialization':
          for (const f of this.filelist) {
            for (const p of f.sixFormGroup.Products) {
              total += parseFloat(p.Amount) || 0
            }
          }
          break;
        case 'USMInvestment':
          for (const f of this.filelist) {
            total += parseFloat(f.sevenFormGroup.USMFunds) || 0
          }
          break;
        case 'ExternalGrants':
          for (const f of this.filelist) {
            for (const p of f.eightFormGroup.ExternalGrants) {
              total += parseFloat(p.GrantValue) || 0
            }
          }
          break;
        case 'Internship':
          for (const f of this.filelist) {
            total += parseFloat(f.staffFormGroup.numberOfIntern) || 0
          }
          break;
        case 'Employment':
          for (const f of this.filelist) {
            for (const p of f.staffFormGroup.Staffs) {
              total += parseFloat(p.NumberOfStaff) || 0
            }
          }
          break;

      }
    }
    return total;

  }
  getCount(value) {
    let total = 0;

    if (this.filelist.length > 0) {
      switch (value) {
        case 'Revenue':
          for (const f of this.filelist) {
            total += f.firstFormGroup.AccumulatedRevenue ? 1 : 0
          }
          break;
        case 'productCommercialization':
          for (const f of this.filelist) {
            total += f.sixFormGroup.Products.length || 0
          }
          break;
        case 'USMInvestment':
          for (const f of this.filelist) {
            total += f.sevenFormGroup.Expenditures.length || 0
          }
          break;
        case 'ExternalGrants':
          for (const f of this.filelist) {
            total += f.eightFormGroup.ExternalGrants.length || 0
          }
          break;
      }
    }
    return '(' + total + ')';

  }

  getUnFilterStatus() {
    let r = this.filelist.filter(s => s.Status !== 'Draft');
    if (r)
      return r.length;
    else
      return 0
  }

  getValue(s) {
    let total = 0;
    for (const sd of s) {
      total += parseFloat(sd.NumberOfStaff);
    }
    return total;
  }

  getTop5() {
    let ary = [];
    if (this.filelist.length > 0) {
      ary = this.filelist.sort((a, b) => this.compare(parseFloat(a.firstFormGroup.AccumulatedRevenue),
        parseFloat(b.firstFormGroup.AccumulatedRevenue), false))


      ary = ary.slice(0, 5);
      let highest = ary[0].firstFormGroup.AccumulatedRevenue
      ary[0].width = '100%';

      for (const a of ary) {
        a.firstFormGroup.AccumulatedRevenue = parseFloat(a.firstFormGroup.AccumulatedRevenue)
        a.width = ((parseFloat(a.firstFormGroup.AccumulatedRevenue) / parseFloat(highest)) * 100) + '%';
      }

    }


    return ary;
  }

  exportProducts() {
    const exportInformation = [];
    const exportInformation1 = [];
    const exportInformation2 = [];

    this.spinner.show();

    // for (const p of this.filelist) {
    //   if (p.sixFormGroup.Products.length === 0) {
    //     const info = {
    //       "Company Name": p.firstFormGroup.CompanyName || "-",
    //       "Product/Technology Name": "-",
    //       "Accumulated Amount(RM)": "-",
    //       "Year to Claim Product Comm": "-",
    //       "Main Invendor Name": "-",
    //     }
    //     exportInformation.push(info)

    //   }
    //   else {
    //     p.sixFormGroup.Products.forEach((e, index) => {

    //       const info = {
    //         "Company Name": index == 0 ? p.firstFormGroup.CompanyName || "-" : '',
    //         "Product/Technology Name": e.Name || '-',
    //         "Accumulated Amount(RM)": e.Amount || '-',
    //         "Year to Claim Product Comm": e.Year || '-',
    //         "Main Invendor Name": e.MainInventor || '-',
    //       }
    //       exportInformation.push(info)
    //     });
    //   }
    // }
    // this.excelHelper.exportAsExcelFile(exportInformation, 'ProductCommerlization');

    for (const p of this.filelist) {
      for (const e of p.sixFormGroup.Products) {
        let index = 0;
        for (const f of e.Clients) {
            const info = {
              "Company Name": index == 0 ? p.firstFormGroup.CompanyName || "-" : '',
              "Product/Technology Name": index == 0 ? e.Name || '-':'',
              "Client Name": f.Name || '-',
              "Client Address": f.Address || '-',
            }
            exportInformation1.push(info)
            index++;
        }

      }

    }
    console.log(exportInformation1)
    this.excelHelper.exportAsExcelFile(exportInformation1, 'ProductCommerlization_Client');

    for (const p of this.filelist) {
      p.sixFormGroup.Products.forEach((e) => {
        e.CoInventors.forEach((f,index)  => {
          const info = {
            "Company Name": index == 0 ? p.firstFormGroup.CompanyName || "-" : '',
            "Product/Technology Name": index == 0 ? e.Name || '-':'',
            "Co-Inventors Name": f.Name || '-',
          }
          exportInformation2.push(info)
        });

      });
    }
    this.excelHelper.exportAsExcelFile(exportInformation2, 'ProductCommerlization_CoInventors');


    this.spinner.hide();

  }




}
