import { UploadFormComponent } from './upload-form/upload-form.component';
import { UserInfoService } from 'src/app/Services/Utilities/user-info.service';
import { DoubleTaxService } from './../../Services/Utilities/double_tax.service';
import { data } from 'jquery';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatPaginator, MatDialog, PageEvent, Sort, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { FundsServiceService } from 'src/app/Services/Utilities/funds-service.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { SubmitFormComponent } from '../cic/submit-form/submit-form.component';
import { NewApplicationComponent } from '../funds/new-application/new-application.component';
import fontkit from '@pdf-lib/fontkit';
import { PDFDocument, StandardFonts, layoutMultilineText, TextAlignment, rgb } from 'pdf-lib';

@Component({
  selector: 'app-double_tax',
  templateUrl: './double_tax.component.html',
  styleUrls: ['./double_tax.component.css']
})
export class DoubleTaxComponent implements OnInit {


  filelist = [];
  sortedfilelist: any;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  searchbox = '';
  searchValue = '';
  sortedu = {
    active: '',
    direction: ''
  };
  dateRange: any;
  email: any;
  uuid: any;
  loading = true;

  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private storageRef: AngularFireStorage,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    public router: Router,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private dateSrv: DateFormatService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    public doubleTaxSrv: DoubleTaxService,
    public userSrv: UserInfoService
  ) {
    this.spinner.show();
    this.doubleTaxSrv.setDoubleTax('');
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
      this.uuid = auth.uid;
      this.firestore.collection('DoubleTax', ref => ref.where('UserID', '==', this.uuid).orderBy('CreatedDate', 'desc')).get().forEach(e => {
        e.forEach(file => {
          const data = {
            ...file.data(),
            id: file.id,
            fileName: file.data().FileNames ? file.data().FileNames.join('\n') : "Not yet upload",
            CreatedDate: new Date(file.data().CreatedDate.toDate())
          }
          this.filelist.push(data);
          this.sortedfilelist = this.filelist.slice(this.offset, (this.offset + this.pageSize));
          this.loading = false;
          this.length = this.filelist.length;
        })
      });
      this.spinner.hide();
    });

  }

  ngOnInit() {
  }

  view(file) {
    this.doubleTaxSrv.setDoubleTax(file);
    this.router.navigate(['/Home/double_tax/View'])
  }

  SetupFile() {
    this.loading = true;

    this.filelist = [];
    this.sortedfilelist = [];
    this.firestore.collection('DoubleTax', ref => ref.where('UserID', '==', this.uuid).orderBy('CreatedDate', 'desc')).get().forEach(e => {
      e.forEach(file => {
        const data = {
          ...file.data(),
          id: file.id,
          fileName: file.data().FileNames ? file.data().FileNames.join('\n') : "Not yet upload",

          CreatedDate: new Date(file.data().CreatedDate.toDate())
        }
        this.filelist.push(data);
        this.sortedfilelist = this.filelist.slice(this.offset, (this.offset + this.pageSize));
        this.loading = false;
        this.length = this.filelist.length;
      })

    });
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    this.sortedfilelist = this.filelist.slice();
    this.sortFile();
    this.limitFile();
  }

  limitFile() {
    this.sortedfilelist = this.sortedfilelist.slice(this.offset, (this.offset + this.pageSize));
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.sortedfilelist = this.filelist.slice();
    if (this.status || this.fileName || this.dateRange) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !(this.status || this.fileName || this.dateRange)) {
      this.sortedfilelist = this.filelist.slice();
      this.limitFile();
      return;
    }

    this.sortedfilelist = this.sortedfilelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'file': return this.compare(a.fileName, b.fileName, isAsc);
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'date': return this.compareDate(a.CreatedDate, b.CreatedDate, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        default: return 0;
      }
    });
    this.limitFile();
  }

  sortFile() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.sortedfilelist = this.sortedfilelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'file': return this.compare(a.fileName, b.fileName, isAsc);
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'date': return this.compareDate(a.CreatedDate, b.CreatedDate, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  reset() {
    this.fileName = '';
    this.status = 'All';
    this.dateRange = '';
    this.SetupFile();
  }
  search() {
    let startDate;
    let endDate;

    this.sortedfilelist = this.filelist.filter(u => String(u.id).toLowerCase().includes(this.fileName.toLowerCase()));
    if (this.status !== 'All')
      this.sortedfilelist = this.sortedfilelist.filter(u => u.Status === this.status);
    if (typeof (this.dateRange) === 'object') {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        startDate = this.dateRange.startDate.toDate();
        endDate = this.dateRange.endDate.toDate();
        this.sortedfilelist = this.sortedfilelist.filter(u =>
          u.CreatedDate.getTime() >= startDate.getTime() && u.CreatedDate <= endDate.getTime());

      }
    }
    this.length = this.sortedfilelist.length;
    this.sortFile();
    this.limitFile();
  }

  DS() {
    let startDate;
    let endDate;

    this.sortedfilelist = this.filelist.filter(u => String(u.id).toLowerCase().includes(this.fileName.toLowerCase()));
    if (this.status !== 'All')
      this.sortedfilelist = this.sortedfilelist.filter(u => u.Status === this.status);
    if (typeof (this.dateRange) === 'object') {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        startDate = this.dateRange.startDate.toDate();
        endDate = this.dateRange.endDate.toDate();
        this.sortedfilelist = this.sortedfilelist.filter(u =>
          u.CreatedDate.getTime() >= startDate.getTime() && u.CreatedDate <= endDate.getTime());

      }
    }

    this.length = this.sortedfilelist.length;
    this.sortFile();
  }


  status = "All"
  fileName = '';
  applicationList = [];
  SelectStatus(status) {
    this.status = status;
  }


  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  FillForm() {
    this.router.navigate(['/Home/double_tax/Add'])


  }

  private dateFormat = new DateFormatService();

  edit(file) {
    this.doubleTaxSrv.setDoubleTax(file);
    this.router.navigate(['/Home/double_tax/Edit'])

  }

  cancel(file) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm want to cancel this application';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          await this.firestore.collection("DoubleTax").doc(file.id).update({ Status: "Canceled", UpdatedDate: new Date(), UpdatedBy: this.email });
          file.Status = "Canceled";

          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: "Application " + file.id + " has been canceled by Email: " + this.email,
            date: new Date()
          };
          await this.firestore.collection('DoubleTaxLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('DoubleTaxLog').doc(dateFormat).collection('User').add(info);


          this.spinner.hide();
          this.toaster.success("Cancel Successfully");
        } catch (error) {
          this.toaster.error(error, "Error happen");
          this.spinner.hide();

        }
      }
    })
  }

  remove(file) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm want to delete this application';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          await this.firestore.collection("DoubleTax").doc(file.id).delete().then(async e => {
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
            const info = {
              message: "Application " + file.id + " has been deleted by Email: " + this.email,
              date: new Date()
            };
            await this.firestore.collection('DoubleTaxLog').doc(dateFormat).set({ Date: new Date() });
            await this.firestore.collection('DoubleTaxLog').doc(dateFormat).collection('User').add(info);

            this.spinner.hide();
            this.toaster.success("Delete Successfully");
            this.SetupFile();
          });
        } catch (error) {
          this.toaster.error(error, "Error happen");
          this.spinner.hide();

        }
      }
    })
  }

  clone(file) {
    this.spinner.show();

    try {
      this.firestore.collection("DoubleTaxID").doc("ID").get().forEach(async item => {
        let ID = ('000' + item.data().ID).slice(-3);
        ID = `EX${new Date().getFullYear()}-${ID}`;
        this.firestore.collection('DoubleTax').doc(file.id).get().forEach(async e => {
          if (e.exists) {

            let info = {
              ...e.data(),
              CreatedDate: new Date(),
              UpdatedDate: new Date(),
              Status: "Draft",
            }
            await this.firestore.collection("DoubleTax").doc(ID).set(info);
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
            const infos = {
              message: "Application" + file.id + " has been cloned to  " + ID + " by " + this.email,
              date: new Date(),
              data: JSON.stringify(info),
            };
            await this.firestore.collection('DoubleTaxLog').doc(dateFormat).set({ Date: new Date() });
            await this.firestore.collection('DoubleTaxLog').doc(dateFormat).collection('User').add(infos);
            await this.firestore.collection('DoubleTaxID').doc("ID").set({ ID: item.data().ID + 1 })

            this.toaster.success("Clone successfully");
            this.SetupFile();
          }
          this.spinner.hide();
        });
      })

    } catch (error) {
      this.spinner.hide();
      this.toaster.error("Failed to clone");

    }

  }

  async download(file) {
    try {
      this.spinner.show();
      await this.generateBorang1(file);
      await this.generateBorang2(file);
      await this.generateBorang3(file);
      await this.generateBorang4(file);

      this.spinner.hide();
    } catch (error) {
      this.toaster.error(error, "Error happen");
      this.spinner.hide();
    }
  }

  async generateBorang1(f) {
    const url = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/Borang%201.pdf?alt=media&token=385d5f56-d3a2-4acd-a062-36a22fc39b8f'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    const fonturl = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/WINGDNG2.TTF?alt=media&token=44461c60-dce3-4a45-9970-89f1a07a0681'
    const fontBytes = await fetch(fonturl).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    const pages = pdfDoc.getPages()

    pdfDoc.registerFontkit(fontkit)
    const customFont = await pdfDoc.embedFont(fontBytes)

    const firstPage = pages[0];
    const secondPage = pages[1];
    const thirdPage = pages[2];
    let dateString;


    this.addText(f.firstFormGroup.Applicant, 205, 573, 400, 20, firstPage, helveticaFont);
    this.addText(this.userSrv.ic, 205, 552, 400, 20, firstPage, helveticaFont);
    this.addText(f.firstFormGroup.Phone, 205, 532, 400, 20, firstPage, helveticaFont);
    this.addText(f.firstFormGroup.Email, 205, 512, 400, 20, firstPage, helveticaFont);
    this.addText(f.firstFormGroup.School, 205, 492, 400, 20, firstPage, helveticaFont);


    this.addText(f.secondFormGroup.ProjectTitle, 295, 435, 240, 20, firstPage, helveticaFont, true, 10);

    if (typeof (f.secondFormGroup.StartDate) === 'object') {
      let d = new Date(f.secondFormGroup.StartDate.toDate());

      dateString = ('0' + d.getDate()).slice(-2)
        + '-' + ('0' + (d.getMonth() + 1)).slice(-2)
        + '-' + d.getFullYear().toString();
      this.addText(dateString, 295, 397, 400, 20, firstPage, helveticaFont, false, 10);

    }

    if (typeof (f.secondFormGroup.EndDate) === 'object') {
      let d = new Date(f.secondFormGroup.EndDate.toDate());

      dateString = ('0' + d.getDate()).slice(-2)
        + '-' + ('0' + (d.getMonth() + 1)).slice(-2)
        + '-' + d.getFullYear().toString();
      this.addText(dateString, 430, 397, 400, 20, firstPage, helveticaFont,false, 10);

    }

    this.addText('RM' + parseFloat(f.fifthFormGroup.AllowanceYear1).toFixed(2), 300, 312, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + parseFloat(f.fifthFormGroup.AllowanceYear2).toFixed(2), 390, 312, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + parseFloat(f.fifthFormGroup.AllowanceYear3).toFixed(2), 475, 312, 100, 20, firstPage, helveticaFont,false, 9);

    this.addText('RM' + parseFloat(f.fifthFormGroup.TravelYear1).toFixed(2), 300, 291, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + parseFloat(f.fifthFormGroup.TravelYear2).toFixed(2), 390, 291, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + parseFloat(f.fifthFormGroup.TravelYear3).toFixed(2), 475, 291, 100, 20, firstPage, helveticaFont,false, 9);

    this.addText('RM' + parseFloat(f.fifthFormGroup.RentalYear1).toFixed(2), 300, 230, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + parseFloat(f.fifthFormGroup.RentalYear2).toFixed(2), 390, 230, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + parseFloat(f.fifthFormGroup.RentalYear3).toFixed(2), 475, 230, 100, 20, firstPage, helveticaFont,false, 9);

    this.addText('RM' + parseFloat(f.fifthFormGroup.ResearchYear1).toFixed(2), 300, 210, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + parseFloat(f.fifthFormGroup.ResearchYear2).toFixed(2), 390, 210, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + parseFloat(f.fifthFormGroup.ResearchYear3).toFixed(2), 475, 210, 100, 20, firstPage, helveticaFont,false, 9);

    this.addText('RM' + parseFloat(f.fifthFormGroup.MaintenanceYear1).toFixed(2), 300, 189, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + parseFloat(f.fifthFormGroup.MaintenanceYear2).toFixed(2), 390, 189, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + parseFloat(f.fifthFormGroup.MaintenanceYear3).toFixed(2), 475, 189, 100, 20, firstPage, helveticaFont,false, 9);

    this.addText('RM' + parseFloat(f.fifthFormGroup.ProfessionalYear1).toFixed(2), 300, 168, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + parseFloat(f.fifthFormGroup.ProfessionalYear2).toFixed(2), 390, 168, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + parseFloat(f.fifthFormGroup.ProfessionalYear3).toFixed(2), 475, 168, 100, 20, firstPage, helveticaFont,false, 9);

    this.addText('RM' + parseFloat(f.fifthFormGroup.AccessoriesYear1).toFixed(2), 300, 148, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + parseFloat(f.fifthFormGroup.AccessoriesYear2).toFixed(2), 390, 148, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + parseFloat(f.fifthFormGroup.AccessoriesYear3).toFixed(2), 475, 148, 100, 20, firstPage, helveticaFont,false, 9);

    this.addText('RM' + this.computeTotal('ServiceCharge1',f), 300, 127, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + this.computeTotal('ServiceCharge2',f), 390, 127, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + this.computeTotal('ServiceCharge3',f), 475, 127, 100, 20, firstPage, helveticaFont,false, 9);


    this.addText('RM' + this.computeTotal('GrandTotal',f), 300, 101, 100, 20, firstPage, helveticaFont,false, 11);

    this.addText(f.thirdFormGroup.CompanyName, 295, 643, 270, 20, secondPage, helveticaFont,true, 9);
    this.addText(f.thirdFormGroup.CompanyAddress, 295, 625, 270, 20, secondPage, helveticaFont,true, 9);
    this.addText(f.thirdFormGroup.CompanyContactPersonPhone, 295, 588, 270, 20, secondPage, helveticaFont,true, 9);
    this.addText(f.thirdFormGroup.CompanyContactPersonEmail, 295, 548, 270, 20, secondPage, helveticaFont,true, 9);


    this.addText('RM' + this.computeTotal('ServiceCharge1',f), 295, 505, 270, 20, secondPage, helveticaFont,false, 9);
    this.addText('RM' + this.computeTotal('ServiceCharge2',f), 380, 505, 270, 20, secondPage, helveticaFont,false, 9);
    this.addText('RM' + this.computeTotal('ServiceCharge3',f), 460, 505, 270, 20, secondPage, helveticaFont,false, 9);



    this.addText(f.thirdFormGroup.CompanyContactPersonName, 295, 410, 270, 20, secondPage, helveticaFont,true, 9);
    this.addText(f.thirdFormGroup.CompanyContactPersonPosition, 295, 390, 270, 20, secondPage, helveticaFont,true, 9);
    this.addText(f.thirdFormGroup.CompanyAddress, 295, 374, 270, 20, secondPage, helveticaFont,true, 9);
    this.addText(f.thirdFormGroup.CompanyContactPersonPhone, 295, 348, 270, 20, secondPage, helveticaFont,true, 9);
    this.addText(f.thirdFormGroup.CompanyContactPersonEmail, 295, 310, 270, 20, secondPage, helveticaFont,true, 9);

    this.addText(f.firstFormGroup.Applicant, 75, 164, 400, 20, secondPage, helveticaFont,true, 11);

    const pdfBytes = await pdfDoc.save()
    let file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = "Borang1_" + f.id + ".pdf";

    anchor.href = fileURL;
    anchor.click();
  }

  async generateBorang2(f) {
    console.log(f);
    const url = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/Borang%202.pdf?alt=media&token=c0265c9f-feb7-4680-888b-cee528d7b130'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    const fonturl = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/WINGDNG2.TTF?alt=media&token=44461c60-dce3-4a45-9970-89f1a07a0681'
    const fontBytes = await fetch(fonturl).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    const pages = pdfDoc.getPages()

    pdfDoc.registerFontkit(fontkit)
    const customFont = await pdfDoc.embedFont(fontBytes)

    const firstPage = pages[0];
    const secondPage = pages[1];
    const thirdPage = pages[2];

    let dateString;

    this.addText(f.secondFormGroup.ProjectTitle, 150, 655, 500, 20, firstPage, helveticaFont, true);
    this.addText(f.thirdFormGroup.CompanyName, 150, 630, 500, 20, firstPage, helveticaFont);
    this.addText(f.firstFormGroup.School, 150, 615, 500, 20, firstPage, helveticaFont);

    this.addText(`Saya ${f.firstFormGroup.Applicant}   (${this.userSrv.ic})  daripada Syarikat ${f.thirdFormGroup.CompanyName} akan memberikan komitmen bernilai sebanyak RM ${this.computeTotal('GrandTotal',f)} bagi perlaksanaan projek ${f.secondFormGroup.ProjectTitle} oleh Universiti Sains Malaysia (USM). Butiran pembayaran komitmen tersebut adalah seperti berikut:`, 65 ,300,475,300,firstPage, helveticaFont)

    this.addText(f.thirdFormGroup.CompanyContactPersonName, 200, 210, 500, 20, firstPage, helveticaFont);
    this.addText(this.userSrv.ic, 200, 185, 500, 20, firstPage, helveticaFont);
    this.addText(f.thirdFormGroup.CompanyContactPersonPosition, 200, 162, 500, 20, firstPage, helveticaFont);
    this.addText(f.thirdFormGroup.CompanyName, 200, 138, 500, 20, firstPage, helveticaFont);

    if(f.eightFormGroup && f.eightFormGroup.Commitment.length > 0){
      let to = 0;
      for (const c of f.eightFormGroup.Commitment) {
        to+= parseFloat(c.Amount);
      }
      this.addText('RM' + to.toFixed(2), 405, 337, 100, 20, firstPage, helveticaFont);

    }


    const pdfBytes = await pdfDoc.save()
    let file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = "Borang2_" + f.id + ".pdf";

    anchor.href = fileURL;
    anchor.click();

  }

  async generateBorang3(f) {
    console.log(f);
    const url = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/Borang%203.pdf?alt=media&token=61cd347a-33a8-4dcd-8a31-2752ebf4ed42'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    const fonturl = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/WINGDNG2.TTF?alt=media&token=44461c60-dce3-4a45-9970-89f1a07a0681'
    const fontBytes = await fetch(fonturl).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    const pages = pdfDoc.getPages()

    pdfDoc.registerFontkit(fontkit)
    const customFont = await pdfDoc.embedFont(fontBytes)

    const firstPage = pages[0];
    const secondPage = pages[1];
    const thirdPage = pages[2];
    let dateString = '';
    if (typeof (f.secondFormGroup.StartDate) === 'object') {
      let d = new Date(f.secondFormGroup.StartDate.toDate());

      dateString = ('0' + d.getDate()).slice(-2)
        + '-' + ('0' + (d.getMonth() + 1)).slice(-2)
        + '-' + d.getFullYear().toString();

    }


    this.addText(f.firstFormGroup.Applicant, 220, 500, 500, 20, firstPage, helveticaFont, true,9);
    this.addText(f.firstFormGroup.School, 220, 488, 500, 20, firstPage, helveticaFont,true,9);
    this.addText(f.thirdFormGroup.CompanyName, 220, 468, 500, 20, firstPage, helveticaFont,true,9);
    this.addText(f.secondFormGroup.ProjectTitle, 220, 447, 500, 20, firstPage, helveticaFont, true,9);
    this.addText(dateString, 220, 426, 500, 20, firstPage, helveticaFont, true,9);
    this.addText('RM'+this.computeTotal('GrandTotal',f), 220, 395, 500, 20, firstPage, helveticaFont, true,9);


    this.addText('RM' + this.computeTotal('Allowance',f), 470, 348, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + this.computeTotal('Travel',f), 470, 328, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + this.computeTotal('Rental',f), 470, 275, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + this.computeTotal('Research',f), 470, 257, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + this.computeTotal('Maintenance',f), 470, 241, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + this.computeTotal('Professional',f), 470, 226, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + this.computeTotal('Accessories',f), 470, 208, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + this.computeTotal('ServiceCharge',f), 470, 188, 100, 20, firstPage, helveticaFont,false, 9);
    this.addText('RM' + this.computeTotal('GrandTotal',f), 470, 168, 100, 20, firstPage, helveticaFont,false, 9);


    const pdfBytes = await pdfDoc.save()
    let file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = "Borang3_" + f.id + ".pdf";

    anchor.href = fileURL;
    anchor.click();
  }


  async generateBorang4(f) {
    console.log(f);
    const url = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/Borang%204.pdf?alt=media&token=73aeb00f-6db4-4307-b090-3093429a8141'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    const fonturl = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/WINGDNG2.TTF?alt=media&token=44461c60-dce3-4a45-9970-89f1a07a0681'
    const fontBytes = await fetch(fonturl).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    const pages = pdfDoc.getPages()

    pdfDoc.registerFontkit(fontkit)
    const customFont = await pdfDoc.embedFont(fontBytes)

    const firstPage = pages[0];
    const secondPage = pages[1];
    const thirdPage = pages[2];


    this.addText(f.secondFormGroup.ProjectTitle, 135, 446, 500, 20, firstPage, helveticaFont, true,10);
    this.addText(f.firstFormGroup.Applicant, 215, 328, 500, 20, firstPage, helveticaFont, true,10);
    this.addText(this.userSrv.ic, 215, 304, 500, 20, firstPage, helveticaFont,true,10);
    this.addText(f.firstFormGroup.School, 215, 270, 500, 20, firstPage, helveticaFont,true,10);

    const pdfBytes = await pdfDoc.save()
    let file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = "Borang4_" + f.id + ".pdf";

    anchor.href = fileURL;
    anchor.click();
  }

  computeTotal(type, f) {
    switch (type) {
      case 'Allowance':
        return !isNaN(
          parseFloat(f.fifthFormGroup.AllowanceYear1) +
          parseFloat(f.fifthFormGroup.AllowanceYear2) +
          parseFloat(f.fifthFormGroup.AllowanceYear3)
        ) ? (parseFloat(f.fifthFormGroup.AllowanceYear1) +
          parseFloat(f.fifthFormGroup.AllowanceYear2) +
          parseFloat(f.fifthFormGroup.AllowanceYear3)).toFixed(2) : '0.00'

      case 'Travel':
        return !isNaN(
          parseFloat(f.fifthFormGroup.TravelYear1) +
          parseFloat(f.fifthFormGroup.TravelYear2) +
          parseFloat(f.fifthFormGroup.TravelYear3)
        ) ? (parseFloat(f.fifthFormGroup.TravelYear1) +
          parseFloat(f.fifthFormGroup.TravelYear2) +
          parseFloat(f.fifthFormGroup.TravelYear3)).toFixed(2) : '0.00'

      case 'Rental':
        return !isNaN(
          parseFloat(f.fifthFormGroup.RentalYear1) +
          parseFloat(f.fifthFormGroup.RentalYear2) +
          parseFloat(f.fifthFormGroup.RentalYear3)
        ) ? (parseFloat(f.fifthFormGroup.RentalYear1) +
          parseFloat(f.fifthFormGroup.RentalYear2) +
          parseFloat(f.fifthFormGroup.RentalYear3)).toFixed(2) : '0.00'

      case 'Research':
        return !isNaN(
          parseFloat(f.fifthFormGroup.ResearchYear1) +
          parseFloat(f.fifthFormGroup.ResearchYear2) +
          parseFloat(f.fifthFormGroup.ResearchYear3)
        ) ? (parseFloat(f.fifthFormGroup.ResearchYear1) +
          parseFloat(f.fifthFormGroup.ResearchYear2) +
          parseFloat(f.fifthFormGroup.ResearchYear3)).toFixed(2) : '0.00'

      case 'Maintenance':
        return !isNaN(
          parseFloat(f.fifthFormGroup.MaintenanceYear1) +
          parseFloat(f.fifthFormGroup.MaintenanceYear2) +
          parseFloat(f.fifthFormGroup.MaintenanceYear3)
        ) ? (parseFloat(f.fifthFormGroup.MaintenanceYear1) +
          parseFloat(f.fifthFormGroup.MaintenanceYear2) +
          parseFloat(f.fifthFormGroup.MaintenanceYear3)).toFixed(2) : '0.00'

      case 'Professional':
        return !isNaN(
          parseFloat(f.fifthFormGroup.ProfessionalYear1) +
          parseFloat(f.fifthFormGroup.ProfessionalYear2) +
          parseFloat(f.fifthFormGroup.ProfessionalYear3)
        ) ? (parseFloat(f.fifthFormGroup.ProfessionalYear1) +
          parseFloat(f.fifthFormGroup.ProfessionalYear2) +
          parseFloat(f.fifthFormGroup.ProfessionalYear3)).toFixed(2) : '0.00'
      case 'Accessories':
        return !isNaN(
          parseFloat(f.fifthFormGroup.AccessoriesYear1) +
          parseFloat(f.fifthFormGroup.AccessoriesYear2) +
          parseFloat(f.fifthFormGroup.AccessoriesYear3)
        ) ? (parseFloat(f.fifthFormGroup.AccessoriesYear1) +
          parseFloat(f.fifthFormGroup.AccessoriesYear2) +
          parseFloat(f.fifthFormGroup.AccessoriesYear3)).toFixed(2) : '0.00'


      case 'GrandTotal':
      case 'ServiceCharge1':
      case 'ServiceCharge':
      case 'ServiceCharge2':
      case 'ServiceCharge3':

        let total = !isNaN(
          parseFloat( f.fifthFormGroup.AllowanceYear1) +
          parseFloat( f.fifthFormGroup.TravelYear1) +
          parseFloat( f.fifthFormGroup.RentalYear1) +
          parseFloat( f.fifthFormGroup.ResearchYear1) +
          parseFloat( f.fifthFormGroup.MaintenanceYear1) +
          parseFloat( f.fifthFormGroup.ProfessionalYear1) +
          parseFloat( f.fifthFormGroup.AccessoriesYear1)
        ) ?
          (
            parseFloat( f.fifthFormGroup.AllowanceYear1) +
            parseFloat( f.fifthFormGroup.TravelYear1) +
            parseFloat( f.fifthFormGroup.RentalYear1) +
            parseFloat( f.fifthFormGroup.ResearchYear1) +
            parseFloat( f.fifthFormGroup.MaintenanceYear1) +
            parseFloat( f.fifthFormGroup.ProfessionalYear1) +
            parseFloat( f.fifthFormGroup.AccessoriesYear1)
          ) : 0;

          let total2 = !isNaN(
            parseFloat( f.fifthFormGroup.AllowanceYear2) +
            parseFloat( f.fifthFormGroup.TravelYear2) +
            parseFloat( f.fifthFormGroup.RentalYear2) +
            parseFloat( f.fifthFormGroup.ResearchYear2) +
            parseFloat( f.fifthFormGroup.MaintenanceYear2) +
            parseFloat( f.fifthFormGroup.ProfessionalYear2) +
            parseFloat( f.fifthFormGroup.AccessoriesYear2)
          ) ?
            (
              parseFloat( f.fifthFormGroup.AllowanceYear2) +
              parseFloat( f.fifthFormGroup.TravelYear2) +
              parseFloat( f.fifthFormGroup.RentalYear2) +
              parseFloat( f.fifthFormGroup.ResearchYear2) +
              parseFloat( f.fifthFormGroup.MaintenanceYear2) +
              parseFloat( f.fifthFormGroup.ProfessionalYear2) +
              parseFloat( f.fifthFormGroup.AccessoriesYear2)
            ) : 0;

            let total3 = !isNaN(
              parseFloat( f.fifthFormGroup.AllowanceYear3) +
              parseFloat( f.fifthFormGroup.TravelYear3) +
              parseFloat( f.fifthFormGroup.RentalYear3) +
              parseFloat( f.fifthFormGroup.ResearchYear3) +
              parseFloat( f.fifthFormGroup.MaintenanceYear3) +
              parseFloat( f.fifthFormGroup.ProfessionalYear3) +
              parseFloat( f.fifthFormGroup.AccessoriesYear3)
            ) ?
              (
                parseFloat( f.fifthFormGroup.AllowanceYear3) +
                parseFloat( f.fifthFormGroup.TravelYear3) +
                parseFloat( f.fifthFormGroup.RentalYear3) +
                parseFloat( f.fifthFormGroup.ResearchYear3) +
                parseFloat( f.fifthFormGroup.MaintenanceYear3) +
                parseFloat( f.fifthFormGroup.ProfessionalYear3) +
                parseFloat( f.fifthFormGroup.AccessoriesYear3)
              ) : 0;


        if (type === 'ServiceCharge')
            return ((total * 0.05)+ (total2 * 0.05) + (total3 * 0.05)).toFixed(2);
        if (type === 'ServiceCharge1')
          return (total * 0.05).toFixed(2);
        if (type === 'ServiceCharge2')
          return (total2 * 0.05).toFixed(2);
        if (type === 'ServiceCharge3')
          return (total3 * 0.05).toFixed(2);

        if (type === 'total1')
          return (total ).toFixed(2);
        if (type === 'total2')
          return (total2 ).toFixed(2);
        if (type === 'total3')
          return (total3).toFixed(2);

        else if (type === 'GrandTotal')
          return (total + (total * 0.05) + total2 + (total2 * 0.05) + total3 + (total3 * 0.05)).toFixed(2);


    }
  }

  async addText(text, x, y, width, height, page, font, nogap = false, fontsize = 12) {
    let multiText = layoutMultilineText(text,
      {
        alignment: TextAlignment.Left,
        fontSize: fontsize,
        font: font,
        bounds: { x: x, y: y, width: width, height: height }
      })

    for (let i = 0; i < multiText.lines.length; i++) {
      if (nogap)
        page.drawText(`${multiText.lines[i].text}`, {
          x: multiText.lines[i].x,
          y: multiText.lines[i].y,
          size: fontsize,
          font: font,
          color: rgb(0, 0, 0)
        })
      else
        page.drawText(`${multiText.lines[i].text}`, {
          x: multiText.lines[i].x,
          y: i > 0 ? multiText.lines[i].y - (7 * i) : multiText.lines[i].y,
          size: fontsize,
          font: font,
          color: rgb(0, 0, 0)
        })
    }
  }

  upload(file) {
    this.dialog.open(UploadFormComponent, {
      width: '70%',
      height: '80%',
      disableClose: true,
      data: {file:file.id, type:1, name: file.firstFormGroup.Applicant}
    }).afterClosed().subscribe(r => {
      this.SetupFile();
    });
  }

}
