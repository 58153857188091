import { FundsServiceService } from './../../Services/Utilities/funds-service.service';
import { NewApplicationComponent } from './new-application/new-application.component';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatPaginator, MatDialog, PageEvent, Sort, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { FillFormComponent } from '../cic/fill-form/fill-form.component';
import { SelectionComponent } from '../cic/selection/selection.component';
import { SubmitFormComponent } from '../cic/submit-form/submit-form.component';

@Component({
  selector: 'app-funds',
  templateUrl: './funds.component.html',
  styleUrls: ['./funds.component.css']
})
export class FundsComponent implements OnInit {

  filelist = [];
  sortedfilelist: any;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  searchbox = '';
  searchValue = '';
  sortedu = {
    active: '',
    direction: ''
  };
  dateRange: any;
  email: any;
  uuid: any;
  loading= true;

  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private storageRef: AngularFireStorage,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    public router: Router,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private dateSrv: DateFormatService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    public fundService: FundsServiceService
  ) {
    this.spinner.show();
    this.fundService.setFile('')

    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
      this.uuid = auth.uid;
      this.firestore.collection('StartUp', ref => ref.where('UserID', '==', this.uuid).orderBy('CreatedDate', 'desc')).get().forEach(e => {
        e.forEach(file => {
          const data = {
            ...file.data(),
            id: file.id,
            fileName: file.data().FileNames?file.data().FileNames.join('\n'): "Not yet upload",
            CreatedDate: new Date(file.data().CreatedDate.toDate())
          }
          this.filelist.push(data);
          this.sortedfilelist = this.filelist.slice(this.offset, (this.offset + this.pageSize));
          this.loading= false;
          this.length = this.filelist.length;
        })
      });
      this.spinner.hide();
    });

  }

  ngOnInit() {
  }

  view(file){
    this.fundService.setFile(file);
    this.router.navigate(['/Home/suso/View'])
  }

  SetupFile() {
    this.loading= true;

    this.filelist = [];
    this.sortedfilelist = [];
    this.firestore.collection('StartUp', ref => ref.where('UserID', '==', this.uuid).orderBy('CreatedDate', 'desc')).get().forEach(e => {
      e.forEach(file => {
        const data = {
          ...file.data(),
          id: file.id,
          fileName: file.data().FileNames?file.data().FileNames.join('\n'): "Not yet upload",

          CreatedDate: new Date(file.data().CreatedDate.toDate())
        }
        this.filelist.push(data);
        this.sortedfilelist = this.filelist.slice(this.offset, (this.offset + this.pageSize));
          this.loading= false;
          this.length = this.filelist.length;
      })
    });
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    this.sortedfilelist = this.filelist.slice();
    this.sortFile();
    this.limitFile();
  }

  limitFile() {
    this.sortedfilelist = this.sortedfilelist.slice(this.offset, (this.offset + this.pageSize));
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.sortedfilelist = this.filelist.slice();
    if (this.status || this.fileName || this.dateRange) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !(this.status || this.fileName || this.dateRange)) {
      this.sortedfilelist = this.filelist.slice();
      this.limitFile();
      return;
    }

    this.sortedfilelist = this.sortedfilelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'file': return this.compare(a.fileName, b.fileName, isAsc);
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'date': return this.compareDate(a.CreatedDate, b.CreatedDate, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        default: return 0;
      }
    });
    this.limitFile();
  }

  sortFile() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.sortedfilelist = this.sortedfilelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'file': return this.compare(a.fileName, b.fileName, isAsc);
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'date': return this.compareDate(a.CreatedDate, b.CreatedDate, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  reset() {
    this.fileName = '';
    this.status = 'All';
    this.dateRange = '';
    this.SetupFile();
  }
  search() {
    let startDate;
    let endDate;

    this.sortedfilelist = this.filelist.filter(u => String(u.fileName).toLowerCase().includes(this.fileName.toLowerCase()));
    if (this.status !== 'All')
      this.sortedfilelist = this.sortedfilelist.filter(u => u.Status === this.status);
    if (typeof (this.dateRange) === 'object') {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        startDate = this.dateRange.startDate.toDate();
        endDate = this.dateRange.endDate.toDate();
        this.sortedfilelist = this.sortedfilelist.filter(u =>
          u.CreatedDate.getTime() >= startDate.getTime() && u.CreatedDate <= endDate.getTime());

      }
    }
    this.length = this.sortedfilelist.length;
    this.sortFile();
    this.limitFile();
  }

  DS() {
    let startDate;
    let endDate;

    this.sortedfilelist = this.filelist.filter(u => String(u.fileName).toLowerCase().includes(this.fileName.toLowerCase()));
    if (this.status !== 'All')
      this.sortedfilelist = this.sortedfilelist.filter(u => u.Status === this.status);
    if (typeof (this.dateRange) === 'object') {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        startDate = this.dateRange.startDate.toDate();
        endDate = this.dateRange.endDate.toDate();
        this.sortedfilelist = this.sortedfilelist.filter(u =>
          u.CreatedDate.getTime() >= startDate.getTime() && u.CreatedDate <= endDate.getTime());

      }
    }

    this.length = this.sortedfilelist.length;
    this.sortFile();
  }

  assign(lorry, id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Assign this File ' + id + ' to ' + lorry.name
    };
    lorry.totalNum++;
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.firestore.collection('Files').doc(id)
          .update({ LorryType: lorry.name, No: lorry.totalNum }).then(s => {
            const r = this.sortedfilelist.find(d => d.id === id);
            r.LorryType = lorry.name;
            this.firestore.collection('lorries').doc(lorry.id).update({ totalNum: lorry.totalNum });

          });
      }
    });
  }

  status = "All"
  fileName = '';
  applicationList = [];
  SelectStatus(status) {
    this.status = status;
  }

  dateRangeChange(e) {


  }

  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  FillForm() {
    this.router.navigate(['Home/suso/Add'])

  }

  SubmitForm(file) {
    this.dialog.open(SubmitFormComponent, {
      width: '70%',
      height: '80%',
      disableClose: true,
      data: file.id
    }).afterClosed().subscribe(r => {
        this.SetupFile();
    });
  }

  private dateFormat = new DateFormatService();

  edit(file){
    this.fundService.setFile(file)
    this.router.navigate(['Home/suso/Edit'])

  }
  cancel(file){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = {top: '5%'};
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm want to cancel this application';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          await this.firestore.collection("StartUp").doc(file.id).update({Status:"Canceled",UpdatedDate:new Date(), UpdatedBy:this.email});
          file.Status = "Canceled";

          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: "Application " + file.id+ " has been canceled by Email: "  + this.email,
            date: new Date()
          };
          await this.firestore.collection('StartUpLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('StartUpLog').doc(dateFormat).collection('User').add(info);


          this.spinner.hide();
          this.toaster.success("Cancel Successfully");
        }catch(error){
          this.toaster.error(error,"Error happen");
          this.spinner.hide();

        }
      }
    })
  }
  remove(file){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = {top: '5%'};
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm want to delete this application';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          await this.firestore.collection("StartUp").doc(file.id).delete().then(async e=>{
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
            const info = {
              message: "Application " + file.id+ " has been deleted by Email: "  + this.email,
              date: new Date()
            };
            await this.firestore.collection('StartUpLog').doc(dateFormat).set({ Date: new Date() });
            await this.firestore.collection('StartUpLog').doc(dateFormat).collection('User').add(info);
            this.SetupFile();
          });

          this.spinner.hide();
          this.toaster.success("Delete Successfully");
        }catch(error){
          this.toaster.error(error,"Error happen");
          this.spinner.hide();

        }
      }
    })
  }

  clone(file){
    this.spinner.show();

    try {
      this.firestore.collection("StartUpID").doc("ID").get().forEach(async item => {
        let ID = ('000' + item.data().ID).slice(-3);
        ID = `SUSO${new Date().getFullYear()}-${ID}`;
        this.firestore.collection('StartUp').doc(file.id).get().forEach(async e => {
          if(e.exists){

            let info = {
              ...e.data(),
              CreatedDate: new Date(),
              UpdatedDate: new Date(),
              Status: "Draft",
            }
            await this.firestore.collection("StartUp").doc(ID).set(info);
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
            const infos = {
              message: "Application"+file.id+" has been cloned to  " +ID  + " by "+ this.email,
              date: new Date(),
              data: JSON.stringify(info),
            };
            await this.firestore.collection('StartUpLog').doc(dateFormat).set({ Date: new Date() });
            await this.firestore.collection('StartUpLog').doc(dateFormat).collection('User').add(infos);
            await this.firestore.collection('StartUpID').doc("ID").set({ ID: item.data().ID + 1 })

            this.toaster.success("Clone successfully");
            this.SetupFile();
          }
          this.spinner.hide();
        });
    })

    } catch (error) {
      this.spinner.hide();
      this.toaster.error("Failed to clone");

    }

  }

}
