import { AdminViewDoubleTaxComponent } from './Components/admin-double_tax/admin-view-double_tax/admin-view-double_tax.component';
import { AdminDoubleTaxComponent } from './Components/admin-double_tax/admin-double_tax.component';
import { ViewDoubleTaxComponent } from './Components/double_tax/view-double_tax/view-double_tax.component';
import { NewDoubleTaxComponent } from './Components/double_tax/new-double_tax/new-double_tax.component';
import { DoubleTaxComponent } from './Components/double_tax/double_tax.component';
import { MonthPickerComponent } from './Shared/month-picker/month-picker.component';
import { IndexComponent } from './Components/index/index.component';
import { WordLimit } from './Shared/WordLimit';
import { NotFoundComponent } from './Components/not-found/not-found.component';
import { OnlyNumber } from './Shared/OnlyNumber';
import { NoSlash } from './Shared/NoSlash';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule, MatNativeDateModule } from '@angular/material';
import { MatButtonModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LoginComponent } from './Components/Home/login/login.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { LayoutComponent } from './Components/Home/layout/layout.component';
import { ConfirmationDialogComponent } from './Shared/confirmation-dialog/confirmation-dialog.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FinishDialogComponent } from './Shared/finish-dialog/finish.component';
import { AccountSettingsComponent } from './Components/account-settings/account-settings.component';
import { nl2brPipe } from './Shared/nlpipe';
import { CICComponent } from './Components/cic/cic.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FillFormComponent } from './Components/cic/fill-form/fill-form.component';
import { SubmitFormComponent } from './Components/cic/submit-form/submit-form.component';
import { AdminCICComponent } from './Components/admin-cic/admin-cic.component';
import { RegisterFormComponent } from './Components/Home/login/register-form/register-form.component';
import { VerifyEmailComponent } from './Components/Home/login/verify-email/verify-email.component';
import { SelectionComponent } from './Components/cic/selection/selection.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { DashboardAdminComponent } from './Components/dashboard-admin/dashboard-admin.component';
import { FundsComponent } from './Components/funds/funds.component';
import { AdminFundsComponent } from './Components/admin-funds/admin-funds.component';
import { NewApplicationComponent } from './Components/funds/new-application/new-application.component';
import { ViewFundsComponent } from './Components/funds/view-funds/view-funds.component';
import { AdminViewFundsComponent } from './Components/admin-funds/admin-view-funds/admin-view-funds.component';
import { SusoComponent } from './Components/suso/suso.component';
import { AdminSusoComponent } from './Components/admin-suso/admin-suso.component';
import { NewSusoComponent } from './Components/suso/new-suso/new-suso.component';
import { ViewSusoComponent } from './Components/suso/view-suso/view-suso.component';
import { AdminViewSusoComponent } from './Components/admin-suso/view-suso/view-suso.component';
import { AdminExhibComponent } from './Components/admin-exhib/admin-exhib.component';
import { ExhibComponent } from './Components/exhib/exhib.component';
import { NewExhibComponent } from './Components/exhib/new-exhib/new-exhib.component';
import { ViewExhibComponent } from './Components/exhib/view-exhib/view-exhib.component';
import { AdminViewExhibComponent } from './Components/admin-exhib/view-exhib/view-exhib.component';
import { FinalResultComponent } from './Shared/final-result/final-result.component';
import { RemarkComponent } from './Shared/remark/remark.component';
import { AdminSusoDashboardComponent } from './Components/admin-suso-dashboard/admin-suso-dashboard.component';
import { DataTablesModule } from "angular-datatables";
import { UploadFormComponent } from './Components/double_tax/upload-form/upload-form.component';
import { PidGrantComponent } from './Components/pid-grant/pid-grant.component';
import { NewPidGrantComponent } from './Components/pid-grant/new-pid-grant/new-pid-grant.component';
import { ViewPidGrantComponent } from './Components/pid-grant/view-pid-grant/view-pid-grant.component';
import { AdminPidGrantComponent } from './Components/admin-pid-grant/admin-pid-grant.component';
import { AdminViewPidGrantComponent } from './Components/admin-pid-grant/admin-view-prid-grant/admin-view-pid-grant.component';

const firebaseConfig = {
  apiKey: "AIzaSyCX1-iMRW5DXXH7YSGlk7DbLNvLw6JLnzo",
  authDomain: "cic-usm.firebaseapp.com",
  projectId: "cic-usm",
  storageBucket: "cic-usm.appspot.com",
  messagingSenderId: "614127244368",
  appId: "1:614127244368:web:89dcef3831e08a80b7ad55"
};


@NgModule({
  declarations: [
    OnlyNumber,
    WordLimit,
    NoSlash,
    AppComponent,
    LoginComponent,
    LayoutComponent,
    ConfirmationDialogComponent,
    FinishDialogComponent,
    AccountSettingsComponent,
    nl2brPipe,
    CICComponent,
    FillFormComponent,
    SubmitFormComponent,
    AdminCICComponent,
    RegisterFormComponent,
    VerifyEmailComponent,
    NotFoundComponent,
    SelectionComponent,
    DashboardComponent,
    DashboardAdminComponent,
    FundsComponent,
    AdminFundsComponent,
    NewApplicationComponent,
    ViewFundsComponent,
    AdminViewFundsComponent,
    SusoComponent,
    AdminSusoComponent,
    NewSusoComponent,
    ViewSusoComponent,
    AdminViewSusoComponent,
    AdminExhibComponent,
    ExhibComponent,
    NewExhibComponent,
    ViewExhibComponent,
    AdminViewExhibComponent,
    FinalResultComponent,
    RemarkComponent,
    AdminSusoDashboardComponent,
    IndexComponent,
    MonthPickerComponent,
    DoubleTaxComponent,
    NewDoubleTaxComponent,
    ViewDoubleTaxComponent,
    AdminDoubleTaxComponent,
    AdminViewDoubleTaxComponent,
    UploadFormComponent,
    PidGrantComponent,
    NewPidGrantComponent,
    ViewPidGrantComponent,
    AdminPidGrantComponent,
    AdminViewPidGrantComponent
  ],
  entryComponents:[
    ConfirmationDialogComponent,
    FinishDialogComponent,
    AccountSettingsComponent,
    FillFormComponent,
    SubmitFormComponent,
    RegisterFormComponent,
    VerifyEmailComponent,
    SelectionComponent,
    FinalResultComponent,
    RemarkComponent,
    UploadFormComponent
  ],
  imports: [
    DataTablesModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    NgxSpinnerModule,
    HttpClientModule,
    DragDropModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatSelectSearchModule,
    NgxQRCodeModule,
    MatStepperModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
