import { AngularFirestore } from '@angular/fire/firestore';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserInfoService } from './../../../Services/Utilities/user-info.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Component, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AccountSettingsComponent } from '../../account-settings/account-settings.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnDestroy {

  mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;
  today = Date.now();
  disabledNavigation = false;
  role:string;
  acccessRight:any[]=[];
  done=false;
  expand = false;
  dateRange;
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    private angularFireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private dialog:MatDialog,
    private toast:ToastrService,
    private storage: AngularFireStorage,
    private spinner: NgxSpinnerService,
    private userinfoSrv:UserInfoService,
  ) {

    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
    this.spinner.show();
    const service = this.angularFireAuth.authState.subscribe(async authState =>{
      if(authState === null){
        this.toast.warning('Session expired please login again')
        this.router.navigate(['/Login']);
        service.unsubscribe()
      } else{
        this.firestore.collection('SUSOPMSettings').doc('settings').get().forEach(e=>{
          this.dateRange = {
            startDate : new Date(e.data().startDate.toDate()),
            endDate : new Date(e.data().endDate.toDate()),
          }
        })

        await this.firestore.collection("Admin").doc(authState.uid).get().forEach(async e=>{
          if(e.exists){
            const role = e.data().Role;
            this.userinfoSrv.SetRole(role)
            const accessRights = []
            if(role !== 'SuperAdmin'){
              e.data().Access.forEach(a=>{
                const info={
                  module: a.Module,
                  right: a.Value,
                }
                accessRights.push(info);
              })
              this.userinfoSrv.setAccessRight(accessRights);
              if(this.router.url === '/Admin'){
                this.router.navigate(['/Admin/'+accessRights[0].module.replaceAll(' ', '')])
              }else{
                const url = this.router.url.split('/Admin/')[1];
                const result = accessRights.find(a=>a.module.replaceAll(' ','') === url)
                if(!result){
                  this.toast.warning('You have no right to access this module','Warning')
                  this.angularFireAuth.auth.signOut().then(e=>{
                    this.router.navigate(['/Login']);
                  });

                }
              }
            }else{
              this.router.navigate(['/Admin/dashboard']);

            }
          }else{
            const snapshot = await this.firestore.collection("Users").doc(authState.uid).get();
            snapshot.forEach(e=>{
              if(e.exists){
                const role = e.data().Role;
                this.userinfoSrv.SetRole(role);
                this.userinfoSrv.ic = e.data().IC;
                let path = 'Admin'
                if(role === 'User')
                  path = 'Home'
                if(!this.router.url.includes(path)){
                  this.angularFireAuth.auth.signOut();
                  setTimeout(() => {
                    this.spinner.hide();
                    this.userinfoSrv.SetRole('')
                    this.userinfoSrv.setAccessRight([]);
                    this.router.navigate(['/Login']);
                  }, 1000);
                  this.toast.info("No Access");
                }else
                  this.spinner.hide();

              }else{
                this.angularFireAuth.auth.signOut();
                setTimeout(() => {
                  this.spinner.hide();
                  this.userinfoSrv.SetRole('')
                  this.userinfoSrv.setAccessRight([]);
                  this.router.navigate(['/Login']);
                }, 1000);
                this.toast.info("No Access");
              }
            })
            service.unsubscribe()
          }
          this.spinner.hide();

        })


      }
    })
    this.userinfoSrv.currentRole.subscribe(role=>this.role = role);
    this.userinfoSrv.currentAccessRights.subscribe(access=>this.acccessRight = access);

  }

  checkRight(module){
    return this.userinfoSrv.checkRight(this.role,module, this.acccessRight);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }
  logout() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Log out? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.angularFireAuth.auth.signOut();
        setTimeout(() => {
          this.userinfoSrv.SetRole('')
          this.userinfoSrv.setAccessRight([]);
          this.router.navigate(['/Login']);
        }, 1000);
      }
    })


  }

  sidebar(){
    this.expand = !this.expand;
  }

  accSettings() {
    this.dialog.open(AccountSettingsComponent,{
      width:'70%',
      disableClose:true,
      position:{
        top:'2%'
      }
    })
  }

  getActive(value){
    if(this.router.url.includes(value))
      return true
    return false
  }

  route(){
    if(this.role === 'User'){
      if(new Date().getTime() > this.dateRange.startDate.getTime()
      &&  new Date().getTime() < this.dateRange.endDate.getTime()
      ){
      this.router.navigate(['/Home/monitoring'])
    }else{
      this.router.navigate(['/Home/monitoring/View'])
    }

    }else{
      this.router.navigate(['/Admin/monitoring'])

    }

  }



}

