import { Router } from '@angular/router';
import { FundsServiceService } from 'src/app/Services/Utilities/funds-service.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig, MatTabChangeEvent } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';

@Component({
  selector: 'app-new-application',
  templateUrl: './new-application.component.html',
  styleUrls: ['./new-application.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class NewApplicationComponent implements OnInit {
  firstFormGroup = this._formBuilder.group({
    CompanyName: ['', Validators.required],
    ProductName: ['', Validators.required],
    Proposed: ['', Validators.required],
    Applicant: ['', Validators.required],
    Email: ['', Validators.required],
    Phone: ['', Validators.required],
    School: ['', Validators.required],
    Expertise: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    ProblemStatement: ['', Validators.required],
    ProposedSolution: ['', Validators.required],
    Technology: ['', Validators.required],
    ProductStatus: ['', Validators.required],
    ProductRecord: ['', Validators.required],
    USMIP: ['', Validators.required],
    Innovation: ['', Validators.required],
    TechnologyBenchmarking: ['', Validators.required],
  });
  thirdFormGroup = this._formBuilder.group({
    TargetMarket: ['', Validators.required],
    GoToMarket: ['', Validators.required],
    BusinessModel: ['', Validators.required],
  });
  fourthFormGroup = this._formBuilder.group({
    Directors: this._formBuilder.array([]),
    Shareholders: this._formBuilder.array([]),
    ManagementTeam: this._formBuilder.array([]),
  });
  fifthFormGroup = this._formBuilder.group({
    SparkFund: ['', Validators.required],
    SparkFundType: [0, Validators.required],
    Equity: [0, Validators.required],
    Expenses: this._formBuilder.array([]),
    ProductDevelopment: ['', Validators.required],
  });
  sixFormGroup = this._formBuilder.group({
    PitchingDeck: ['', Validators.required],
    BusinessModelCanvas: ['', Validators.required],
  });
  email: string;
  uuid: string;
  Technologies = [
    'TRL 1 - Basic Principle',
    'TRL 2 - Formulation of Concept',
    'TRL 3 - Experimental Proof of Concept',
    'TRL 4 - Lab Validation',
    'TRL 5 - Validation in Real Environment',
    'TRL 6 - Demonstration in Real Environment',
    'TRL 7 - Demonstration in Prototype',
    'TRL 8 - Product Complete and Qualified',
    'TRL 9 - Product Proven',
  ]
  Funds = [
    { type: 1, value: "RM 0 (USM Equity 5%)" },
    { type: 2, value: "RM 25,000 (USM Equity 10%)" },
    { type: 3, value: "RM 37,500 (USM Equity 15%)" },
    { type: 4, value: "RM 50,000 (USM Equity 20%)" }
  ]
  url = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/business-model-canvas.docx?alt=media&token=1bbf176c-c0c6-49a0-ae03-11b432da3d9b'
  url2 = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/SUSO%20Pitch%20Deck%20CIC.pptx?alt=media&token=55350712-c646-472d-9d4c-b8388cfc3064'

  edit = false;
  editID = '';

  management={
    Name: '',
    IdentityNumber: '',
    Experience: '',
    Role:'',
    USMStaff: false
  }

  director={
    Name: '',
    IdentityNumber: '',
    Experience: '',
    USMStaff: false
  }

  shareholder={
    Name: '',
    IdentityNumber: '',
    Percentages: 0,
    USMStaff: false
  }

  expense={
    Type: '',
    Amount: 0
  }

  constructor(
    private _formBuilder: FormBuilder,
    private toast: ToastrService,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private fundSrv: FundsServiceService,
    private router: Router
  ) {

    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
      this.uuid = auth.uid;

      if (fundSrv.file) {
        this.edit = true;
        this.editID = fundSrv.file.id;
        this.firstFormGroup = this._formBuilder.group({
          CompanyName: [fundSrv.file.firstFormGroup.CompanyName, Validators.required],
          ProductName: [fundSrv.file.firstFormGroup.ProductName, Validators.required],
          Proposed: [fundSrv.file.firstFormGroup.Proposed, Validators.required],
          Applicant: [fundSrv.file.firstFormGroup.Applicant, Validators.required],
          Email: [fundSrv.file.firstFormGroup.Email, Validators.required],
          Phone: [fundSrv.file.firstFormGroup.Phone, Validators.required],
          School: [fundSrv.file.firstFormGroup.School, Validators.required],
          Expertise: [fundSrv.file.firstFormGroup.Expertise, Validators.required],
        });
        this.secondFormGroup = this._formBuilder.group({
          ProblemStatement: [fundSrv.file.secondFormGroup.ProblemStatement, Validators.required],
          ProposedSolution: [fundSrv.file.secondFormGroup.ProposedSolution, Validators.required],
          Technology: [fundSrv.file.secondFormGroup.Technology, Validators.required],
          ProductStatus: [fundSrv.file.secondFormGroup.ProductStatus, Validators.required],
          ProductRecord: [fundSrv.file.secondFormGroup.ProductRecord, Validators.required],
          USMIP: [fundSrv.file.secondFormGroup.USMIP, Validators.required],
          Innovation: [fundSrv.file.secondFormGroup.Innovation, Validators.required],
          TechnologyBenchmarking: [fundSrv.file.secondFormGroup.TechnologyBenchmarking, Validators.required],
        });
        this.thirdFormGroup = this._formBuilder.group({
          TargetMarket: [fundSrv.file.thirdFormGroup.TargetMarket, Validators.required],
          GoToMarket: [fundSrv.file.thirdFormGroup.GoToMarket, Validators.required],
          BusinessModel: [fundSrv.file.thirdFormGroup.BusinessModel, Validators.required],
        });
        this.fourthFormGroup = this._formBuilder.group({
          Directors: this._formBuilder.array([]),
          Shareholders: this._formBuilder.array([]),
          ManagementTeam: this._formBuilder.array([]),
        });
        for (const dir of fundSrv.file.fourthFormGroup.Directors) {
          this.directors().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
              IdentityNumber: [dir.IdentityNumber, Validators.required],
              Experience: [dir.Experience, Validators.required],
              USMStaff: dir.USMStaff,
              isEdit: false,
            }));
        }
        for (const dir of fundSrv.file.fourthFormGroup.Shareholders) {
          this.shareholders().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
              IdentityNumber: [dir.IdentityNumber, Validators.required],
              Percentages: [dir.Percentages, Validators.required],
              USMStaff: dir.USMStaff,
              isEdit: false,
            }));
        }

        for (const dir of fundSrv.file.fourthFormGroup.ManagementTeam) {
          this.managementTeam().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
              IdentityNumber: [dir.IdentityNumber, Validators.required],
              Experience: [dir.Experience, Validators.required],
              Role: [dir.Role, Validators.required],
              USMStaff: dir.USMStaff,
              isEdit: false,
            }));
        }
        this.fifthFormGroup = this._formBuilder.group({
          SparkFund: [fundSrv.file.fifthFormGroup.SparkFund, Validators.required],
          SparkFundType: [fundSrv.file.fifthFormGroup.SparkFundType, Validators.required],
          Expenses: this._formBuilder.array([]),
          Equity: [fundSrv.file.fifthFormGroup.Equity, Validators.required],
          ProductDevelopment: [fundSrv.file.fifthFormGroup.ProductDevelopment, Validators.required],
        });

        for (const dir of fundSrv.file.fifthFormGroup.Expenses) {
          this.expenses().push(
            this._formBuilder.group({
              Type: [dir.Type, Validators.required],
              Amount: [dir.Amount, Validators.required],
              isEdit: false,
            }));
        }

        let file1 = {
          name: fundSrv.file.FileNames[0] || '',
          url: fundSrv.file.FileUrls[0] || '',
        }
        let file2 = {
          name: fundSrv.file.FileNames[1] || '',
          url: fundSrv.file.FileUrls[1] || '',
        }
        this.sixFormGroup = this._formBuilder.group({
          PitchingDeck: [file1, Validators.required],
          BusinessModelCanvas: [file2, Validators.required],
        });

      } else {
        this.firestore.collection('Users').doc(this.uuid).get().forEach(file => {
          this.firstFormGroup.get('Applicant').setValue(file.data().Name);
          this.firstFormGroup.get('Email').setValue(file.data().Email);
          this.firstFormGroup.get('Phone').setValue(file.data().TelephoneNo);
          this.firstFormGroup.get('School').setValue(file.data().SchoolName);
        });


        if (this.router.url.includes('Edit')) {
          if (!this.edit)
            this.router.navigate(['Home/suso'])
        }
        // this.firestore.collection('Drafts').doc(this.uuid).get().forEach(file => {
        //   if (file.exists) {
        //     this.firstFormGroup = this._formBuilder.group({
        //       CompanyName: [file.data().firstFormGroup.CompanyName, Validators.required],
        //       ProductName: [file.data().firstFormGroup.ProductName, Validators.required],
        //       Proposed: [file.data().firstFormGroup.Proposed, Validators.required],
        //       Applicant: [file.data().firstFormGroup.Applicant, Validators.required],
        //       Email: [file.data().firstFormGroup.Email, Validators.required],
        //       Phone: [file.data().firstFormGroup.Phone, Validators.required],
        //       School: [file.data().firstFormGroup.School, Validators.required],
        //       Expertise: [file.data().firstFormGroup.Expertise, Validators.required],
        //     });
        //     this.secondFormGroup = this._formBuilder.group({
        //       ProblemStatement: [file.data().secondFormGroup.ProblemStatement, Validators.required],
        //       ProposedSolution: [file.data().secondFormGroup.ProposedSolution, Validators.required],
        //       Technology: [file.data().secondFormGroup.Technology, Validators.required],
        //       ProductStatus: [file.data().secondFormGroup.ProductStatus, Validators.required],
        //       ProductRecord: [file.data().secondFormGroup.ProductRecord, Validators.required],
        //       USMIP: [file.data().secondFormGroup.USMIP, Validators.required],
        //       Innovation: [file.data().secondFormGroup.Innovation, Validators.required],
        //       TechnologyBenchmarking: [file.data().secondFormGroup.TechnologyBenchmarking, Validators.required],
        //     });
        //     this.thirdFormGroup = this._formBuilder.group({
        //       TargetMarket: [file.data().thirdFormGroup.TargetMarket, Validators.required],
        //       GoToMarket: [file.data().thirdFormGroup.GoToMarket, Validators.required],
        //       BusinessModel: [file.data().thirdFormGroup.BusinessModel, Validators.required],
        //     });
        //     this.fourthFormGroup = this._formBuilder.group({
        //       Directors: this._formBuilder.array([]),
        //       Shareholders: this._formBuilder.array([]),
        //       ManagementTeam: this._formBuilder.array([]),
        //     });
        //     for (const dir of file.data().fourthFormGroup.Directors) {
        //       this.directors().push(
        //         this._formBuilder.group({
        //           Name: [dir.Name, Validators.required],
        //           IdentityNumber: [dir.IdentityNumber, Validators.required],
        //           Experience: [dir.Experience, Validators.required],
        //           USMStaff: dir.USMStaff,
        //         }));
        //     }
        //     for (const dir of file.data().fourthFormGroup.Shareholders) {
        //       this.shareholders().push(
        //         this._formBuilder.group({
        //           Name: [dir.Name, Validators.required],
        //           IdentityNumber: [dir.IdentityNumber, Validators.required],
        //           Percentages: [dir.Percentages, Validators.required],
        //           USMStaff: dir.USMStaff,
        //         }));
        //     }

        //     for (const dir of file.data().fourthFormGroup.ManagementTeam) {
        //       this.managementTeam().push(
        //         this._formBuilder.group({
        //           Name: [dir.Name, Validators.required],
        //           IdentityNumber: [dir.IdentityNumber, Validators.required],
        //           Experience: [dir.Experience, Validators.required],
        //           Role: [dir.Role, Validators.required],
        //           USMStaff: dir.USMStaff,
        //         }));
        //     }
        //     this.fifthFormGroup = this._formBuilder.group({
        //       SparkFund: [file.data().fifthFormGroup.SparkFund, Validators.required],
        //       SparkFundType: [file.data().fifthFormGroup.SparkFundType, Validators.required],
        //       Expenses: this._formBuilder.array([]),
        //       ProductDevelopment: [file.data().fifthFormGroup.ProductDevelopment, Validators.required],
        //     });

        //     for (const dir of file.data().fifthFormGroup.Expenses) {
        //       this.expenses().push(
        //         this._formBuilder.group({
        //           Type: [dir.Type, Validators.required],
        //           Amount: [dir.Amount, Validators.required],
        //         }));
        //     }

        //   }
        // })
      }

    });


  }

  ngOnInit() {
  }


  directors(): FormArray {
    return this.fourthFormGroup.get('Directors') as FormArray;
  }
  shareholders(): FormArray {
    return this.fourthFormGroup.get('Shareholders') as FormArray;
  }
  managementTeam(): FormArray {
    return this.fourthFormGroup.get('ManagementTeam') as FormArray;
  }
  expenses(): FormArray {
    return this.fifthFormGroup.get('Expenses') as FormArray;
  }

  SelectTechnology(item) {
    this.secondFormGroup.get('Technology').setValue(item);
  }

  SelectFunds(item, type) {
    this.fifthFormGroup.get('SparkFund').setValue(item);
    this.fifthFormGroup.get('SparkFundType').setValue(type);
  }

  async downloadSuSoTemplate() {
    const url = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/SUSO%20Pitch%20Deck%20CIC.pptx?alt=media&token=55350712-c646-472d-9d4c-b8388cfc3064'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    let file = new Blob([existingPdfBytes], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = "SUSOtemplate.pptx";
    anchor.href = fileURL;
    anchor.click();
  }

  async downloadModelCanvas() {
    const url = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/business-model-canvas.docx?alt=media&token=1bbf176c-c0c6-49a0-ae03-11b432da3d9b'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    let file = new Blob([existingPdfBytes], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = "BusinessTemplate.docx";
    anchor.href = fileURL;
    anchor.click();
  }

  uploadDeck(event: any) {
    var selectedFiles = event.target.files;
    this.sixFormGroup.get('PitchingDeck').setValue(selectedFiles[0]);
  }

  uploadDoc(event: any) {
    var selectedFiles = event.target.files;
    this.sixFormGroup.get('BusinessModelCanvas').setValue(selectedFiles[0]);
  }

  remove(i, type) {
    switch (type) {
      case 'directors':
        this.directors().removeAt(i);
        break;
      case 'shareholders':
        this.shareholders().removeAt(i);
        break;
      case 'management':
        this.managementTeam().removeAt(i);
        break;
      case 'expenses':
        this.expenses().removeAt(i);
        break;
    }
  }

  editForm(i, type) {
    switch (type) {
      case 'directors':
        this.directors().at(i).get('isEdit').setValue(!this.directors().at(i).get('isEdit').value);
        break;
      case 'shareholders':
        this.shareholders().at(i).get('isEdit').setValue(!this.shareholders().at(i).get('isEdit').value);
        break;
      case 'management':
        this.managementTeam().at(i).get('isEdit').setValue(!this.managementTeam().at(i).get('isEdit').value);
        break;
      case 'expenses':
        this.expenses().at(i).get('isEdit').setValue(!this.expenses().at(i).get('isEdit').value);
        break;
    }
  }


  addDirectors() {
    this.directors().push(this.newDirectors());
  }
  newDirectors() {
    let info = {...this.director}

    this.director={
      Name: '',
      IdentityNumber: '',
      Experience: '',
      USMStaff: false
    }

    return this._formBuilder.group({
     ...info,
     isEdit: false
    });

  }

  addShareHolders() {
    this.shareholders().push(this.newShareHolders());
  }
  newShareHolders() {
    let info = {...this.shareholder}

    this.shareholder={
      Name: '',
      IdentityNumber: '',
      USMStaff: false,
      Percentages: 0
    }

    return this._formBuilder.group({
     ...info,
     isEdit: false
    });
  }

  back() {
    this.router.navigate(['Home/suso'])
  }

  addManagement() {
    this.managementTeam().push(this.newManagement());
  }


  newManagement() {
    let info = {...this.management}
    this.management={
      Name: '',
      IdentityNumber: '',
      Experience: '',
      Role:'',
      USMStaff: false
    }

    return this._formBuilder.group({
      ...info,
      isEdit: false
     });
  }

  getWordLeft(word) {
    return word.split(' ');
  }


  validateError(formControl: FormControl) {

    if (formControl.value.split(' ').length > 150)
      formControl.setErrors({ 'invalid': true })
    else
      formControl.setErrors({ 'invalid': null })
  }

  addExpenses() {
    this.expenses().push(this.newExpenses());
  }
  newExpenses() {
    let info = {...this.expense}
    this.expense={
      Type: '',
      Amount: 0
    }
    return this._formBuilder.group({
      ...info,
      isEdit: false
     });

  }
  selectedIndex = 0;

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedIndex = tabChangeEvent.index;
    this.saveAndNext(true);

  }

  saveAndNext(ddd = false) {
    if (!ddd)
      this.selectedIndex++;

    if (!this.firstFormGroup.invalid) {
      this.spinner.show();
      this.temporarySave();
    }
    // const info = {
    //   firstFormGroup: this.firstFormGroup.value,
    //   secondFormGroup: this.secondFormGroup.value,
    //   thirdFormGroup: this.thirdFormGroup.value,
    //   fourthFormGroup: this.fourthFormGroup.value,
    //   fifthFormGroup: this.fifthFormGroup.value,
    // }
    // this.firestore.collection('Drafts').doc(this.uuid).set(info);
  }

  validateAmount = true;

  getTotalAmount() {
    let expenses = this.expenses().value;
    let val = parseFloat(this.fifthFormGroup.get('SparkFund').value);

    let total = 0;
    for (const e of expenses) {
      total += parseFloat(e.Amount);
    }

    this.validateAmount = total <= val

    if (!this.validateAmount)
      this.fifthFormGroup.setErrors({ invalid: true })
    else
      this.fifthFormGroup.setErrors(null)

    return total;
  }

  validatePercentages = true;
  getTotalPercentage() {
    let share = this.shareholders().value;
    let total = 0;
    for (const e of share) {
      total += parseFloat(e.Percentages);
    }

    this.validatePercentages = (isNaN(total) ? 0 : total) <= 100;

    if (!this.validatePercentages)
      this.fourthFormGroup.setErrors({ invalid: true })
    else
      this.fourthFormGroup.setErrors(null)

    return isNaN(total) ? 0 : total;
  }

  getSparkFundAmount() {
    let type = this.fifthFormGroup.get('SparkFundType').value;

    if (type == 2)
      return '25,000';
    else if (type == 3)
      return '37,500';
    else if (type == 4)
      return '50,000'

  }

  async temporarySave() {
    this.downloadUrls = []
    this.fileNames = []
    try {
      this.spinner.show();

      if (this.edit) {

        if (this.sixFormGroup.get('PitchingDeck').value && this.sixFormGroup.get('PitchingDeck').value.name && !this.sixFormGroup.get('PitchingDeck').value.url)
          await this.uploadImageAsPromise(this.sixFormGroup.get('PitchingDeck').value);
        else if (this.sixFormGroup.get('PitchingDeck').value.url) {
          this.fileNames.push(this.sixFormGroup.get('PitchingDeck').value.name)
          this.downloadUrls.push(this.sixFormGroup.get('PitchingDeck').value.url)
        } else {
          this.fileNames.push('')
          this.downloadUrls.push('')
        }
        if (this.sixFormGroup.get('BusinessModelCanvas').value && this.sixFormGroup.get('BusinessModelCanvas').value.name && !this.sixFormGroup.get('BusinessModelCanvas').value.url)
          await this.uploadImageAsPromise(this.sixFormGroup.get('BusinessModelCanvas').value);
        else if (this.sixFormGroup.get('BusinessModelCanvas').value.url) {
          this.fileNames.push(this.sixFormGroup.get('BusinessModelCanvas').value.name)
          this.downloadUrls.push(this.sixFormGroup.get('BusinessModelCanvas').value.url)
        } else {
          this.fileNames.push('')
          this.downloadUrls.push('')
        }
        let info = {
          UpdatedDate: new Date(),
          UpdatedBy: this.email,
          CreatedBy: this.email,
          Status: this.fundSrv.file? this.fundSrv.file.Status:"Draft",

          firstFormGroup: this.firstFormGroup.value,
          secondFormGroup: this.secondFormGroup.value,
          thirdFormGroup: this.thirdFormGroup.value,
          fourthFormGroup: this.fourthFormGroup.value,
          UserID: this.uuid,
          fifthFormGroup: this.fifthFormGroup.value,
          FileUrls: this.downloadUrls,
          FileNames: this.fileNames,
        }
        await this.firestore.collection("StartUp").doc(this.editID).update(info);
        const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
        const infos = {
          message: "Draft has been saved by: " + this.email,
          date: new Date(),
          data: JSON.stringify(info),
        };
        await this.firestore.collection('StartUpLog').doc(dateFormat).set({ Date: new Date() });
        await this.firestore.collection('StartUpLog').doc(dateFormat).collection('User').add(infos);
        this.spinner.hide();
      } else {
        await this.firestore.collection("StartUpID").doc("ID").get().forEach(async item => {
          let ID = ('000' + item.data().ID).slice(-3);
          ID = `SUSO${new Date().getFullYear()}-${ID}`;
          if (this.sixFormGroup.get('PitchingDeck').value && this.sixFormGroup.get('PitchingDeck').value.name && !this.sixFormGroup.get('PitchingDeck').value.url)
            await this.uploadImageAsPromise(this.sixFormGroup.get('PitchingDeck').value);
          else if (this.sixFormGroup.get('PitchingDeck').value.url) {
            this.fileNames.push(this.sixFormGroup.get('PitchingDeck').value.name)
            this.downloadUrls.push(this.sixFormGroup.get('PitchingDeck').value.url)
          } else {
            this.fileNames.push('')
            this.downloadUrls.push('')
          }
          if (this.sixFormGroup.get('BusinessModelCanvas').value && this.sixFormGroup.get('BusinessModelCanvas').value.name && !this.sixFormGroup.get('BusinessModelCanvas').value.url)
            await this.uploadImageAsPromise(this.sixFormGroup.get('BusinessModelCanvas').value);
          else if (this.sixFormGroup.get('BusinessModelCanvas').value.url) {
            this.fileNames.push(this.sixFormGroup.get('BusinessModelCanvas').value.name)
            this.downloadUrls.push(this.sixFormGroup.get('BusinessModelCanvas').value.url)
          } else {
            this.fileNames.push('')
            this.downloadUrls.push('')
          }
          let info = {
            UpdatedDate: new Date(),
            CreatedDate: new Date(),
            UpdatedBy: this.email,
            CreatedBy: this.email,
            Status: this.fundSrv.file? this.fundSrv.file.Status:"Draft",
            FileUrls: this.downloadUrls,
            FileNames: this.fileNames,
            firstFormGroup: this.firstFormGroup.value,
            secondFormGroup: this.secondFormGroup.value,
            thirdFormGroup: this.thirdFormGroup.value,
            fourthFormGroup: this.fourthFormGroup.value,
            UserID: this.uuid,
            fifthFormGroup: this.fifthFormGroup.value,
          }
          await this.firestore.collection("StartUp").doc(ID).set(info);
          await this.firestore.collection('Drafts').doc(this.uuid).delete();
          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const infos = {
            message: "Draft has been saved by: " + this.email,
            date: new Date(),
            data: JSON.stringify(info),
          };
          await this.firestore.collection('StartUpLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('StartUpLog').doc(dateFormat).collection('User').add(infos);
          await this.firestore.collection('StartUpID').doc("ID").set({ ID: item.data().ID + 1 })

          this.spinner.hide();
          this.edit = true;
          this.editID = ID;
        })
      }


    } catch (error) {
      this.toast.error(error, "Error happen");
      this.spinner.hide();

    }
  }
  dateFormat = new DateFormatService();


  saveAsSubmit() {
    this.downloadUrls = []
    this.fileNames = []
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm submit the new application?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();

          if (this.edit) {
            if (this.sixFormGroup.get('PitchingDeck').value && this.sixFormGroup.get('PitchingDeck').value.name && !this.sixFormGroup.get('PitchingDeck').value.url)
              await this.uploadImageAsPromise(this.sixFormGroup.get('PitchingDeck').value);
            else if (this.sixFormGroup.get('PitchingDeck').value.url) {
              this.fileNames.push(this.sixFormGroup.get('PitchingDeck').value.name)
              this.downloadUrls.push(this.sixFormGroup.get('PitchingDeck').value.url)
            } else {
              this.fileNames.push('')
              this.downloadUrls.push('')
            }
            if (this.sixFormGroup.get('BusinessModelCanvas').value && this.sixFormGroup.get('BusinessModelCanvas').value.name && !this.sixFormGroup.get('BusinessModelCanvas').value.url)
              await this.uploadImageAsPromise(this.sixFormGroup.get('BusinessModelCanvas').value);
            else if (this.sixFormGroup.get('BusinessModelCanvas').value.url) {
              this.fileNames.push(this.sixFormGroup.get('BusinessModelCanvas').value.name)
              this.downloadUrls.push(this.sixFormGroup.get('BusinessModelCanvas').value.url)
            } else {
              this.fileNames.push('')
              this.downloadUrls.push('')
            }
            let info = {
              UpdatedDate: new Date(),
              SubmittedDate: new Date(),
              CreatedBy: this.email,
              UpdatedBy: this.email,
              Status: "Submitted",
              FileUrls: this.downloadUrls,
              FileNames: this.fileNames,
              firstFormGroup: this.firstFormGroup.value,
              secondFormGroup: this.secondFormGroup.value,
              thirdFormGroup: this.thirdFormGroup.value,
              fourthFormGroup: this.fourthFormGroup.value,
              UserID: this.uuid,
              fifthFormGroup: this.fifthFormGroup.value,
            }
            await this.firestore.collection("StartUp").doc(this.editID).update(info);
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
            const infos = {
              message: "New application has been submitted by: " + this.email,
              date: new Date(),
              data: JSON.stringify(info),
            };
            await this.firestore.collection('StartUpLog').doc(dateFormat).set({ Date: new Date() });
            await this.firestore.collection('StartUpLog').doc(dateFormat).collection('User').add(infos);
            this.spinner.hide();
            this.toast.success("Saved Successfully");
            this.router.navigate(['Home/suso'])
          } else {
            await this.firestore.collection("StartUpID").doc("ID").get().forEach(async item => {
              let ID = ('000' + item.data().ID).slice(-3);
              ID = `SUSO${new Date().getFullYear()}-${ID}`;

              if (this.sixFormGroup.get('PitchingDeck').value && this.sixFormGroup.get('PitchingDeck').value.name && !this.sixFormGroup.get('PitchingDeck').value.url)
                await this.uploadImageAsPromise(this.sixFormGroup.get('PitchingDeck').value);
              else if (this.sixFormGroup.get('PitchingDeck').value.url) {
                this.fileNames.push(this.sixFormGroup.get('PitchingDeck').value.name)
                this.downloadUrls.push(this.sixFormGroup.get('PitchingDeck').value.url)
              } else {
                this.fileNames.push('')
                this.downloadUrls.push('')
              }
              if (this.sixFormGroup.get('BusinessModelCanvas').value && this.sixFormGroup.get('BusinessModelCanvas').value.name && !this.sixFormGroup.get('BusinessModelCanvas').value.url)
                await this.uploadImageAsPromise(this.sixFormGroup.get('BusinessModelCanvas').value);
              else if (this.sixFormGroup.get('BusinessModelCanvas').value.url) {
                this.fileNames.push(this.sixFormGroup.get('BusinessModelCanvas').value.name)
                this.downloadUrls.push(this.sixFormGroup.get('BusinessModelCanvas').value.url)
              } else {
                this.fileNames.push('')
                this.downloadUrls.push('')
              }
              let info = {
                UpdatedDate: new Date(),
                SubmittedDate: new Date(),
                CreatedDate: new Date(),
                UpdatedBy: this.email,
                FileUrls: this.downloadUrls,
                FileNames: this.fileNames,
                Status: "Submitted",
                firstFormGroup: this.firstFormGroup.value,
                secondFormGroup: this.secondFormGroup.value,
                thirdFormGroup: this.thirdFormGroup.value,
                fourthFormGroup: this.fourthFormGroup.value,
                UserID: this.uuid,
                fifthFormGroup: this.fifthFormGroup.value,
                CreatedBy: this.email,
              }
              await this.firestore.collection("StartUp").doc(ID).set(info);
              await this.firestore.collection('Drafts').doc(this.uuid).delete();

              const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
              const infos = {
                message: "New application has been submitted by: " + this.email,
                date: new Date(),
                data: JSON.stringify(info),
              };
              await this.firestore.collection('StartUpLog').doc(dateFormat).set({ Date: new Date() });
              await this.firestore.collection('StartUpLog').doc(dateFormat).collection('User').add(infos);
              await this.firestore.collection('StartUpID').doc("ID").set({ ID: item.data().ID + 1 })


              this.spinner.hide();
              this.toast.success("These may take few days to process.", "Submit Successfully");
              this.router.navigate(['Home/suso'])
            })

          }


        } catch (error) {
          this.toast.error(error, "Error happen");
          this.spinner.hide();

        }
      }
    })
  }

  downloadUrls = []
  fileNames = []
  uploadImageAsPromise(imageFile) {
    return new Promise((resolve, reject) => {
      const filePath = `StartUp/${imageFile.name}_${new Date().getTime()}`;
      var storageRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, imageFile);
      let progress;
      task.snapshotChanges().pipe(
        finalize(() => {
          var downloadURL = storageRef.getDownloadURL();
          downloadURL.subscribe(async url => {
            this.downloadUrls.push(url);
            this.fileNames.push(imageFile.name);
            resolve(true);
          });
        })
      ).subscribe(url => {
        if (url) {
          progress = url;
        }
      });
    });
  }

  async viewPPT(form) {
    if(form.url){
      const existingPdfBytes = await fetch(form.url).then(res => res.arrayBuffer())
      let file = new Blob([existingPdfBytes]);
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = form.name;
      anchor.href = fileURL;
      anchor.click();
    }else{
      let file = new Blob([form]);
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = form.name;
      anchor.href = fileURL;
      anchor.click();
    }
  }






}
