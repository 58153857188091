import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FundsServiceService } from 'src/app/Services/Utilities/funds-service.service';

@Component({
  selector: 'app-admin-view-funds',
  templateUrl: './admin-view-funds.component.html',
  styleUrls: ['./admin-view-funds.component.css']
})
export class AdminViewFundsComponent implements OnInit {

  title=''
  file: any;
  constructor(
    private router: Router,
    private fundService: FundsServiceService
  ) {
    if(this.fundService.file){
      this.title = this.fundService.file.id;
      this.file = this.fundService.file;
    }else{
      this.router.navigate(['/Admin/suso']);

    }


   }

  ngOnInit() {
  }

  back(){
    this.router.navigate(['/Admin/suso']);

  }

  getTotalAmount(){
    let total = 0;
    for (const f of this.file.fifthFormGroup.Expenses) {
        total += parseFloat(f.Amount);
    }
    return total;
  }

  getTotalPercentage(){
    let total = 0;
    for (const f of this.file.fourthFormGroup.Shareholders) {
        total += parseFloat(f.Percentages);
    }
    return total;
  }
  async viewPPT(url, name){
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    let file = new Blob([existingPdfBytes]);
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = name;
    anchor.href = fileURL;
    anchor.click();
  }

  async viewDocx(url, name){
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    let file = new Blob([existingPdfBytes]);
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = name;
    anchor.href = fileURL;
    anchor.click();
  }


}
