import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgModel } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { VerifyEmailComponent } from '../verify-email/verify-email.component';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.css']
})
export class RegisterFormComponent implements OnInit {
  registerModel = {
    name: '',
    nameE: false,
    telephoneNo: '',
    telephoneNoE: false,
    email: '',
    emailE: false,
    ic: '',
    icE: false,
    pos: '',
    posE: false,
    schoolName: '',
    schoolNameE: false,
    add1: '',
    add1E: false,
    add2: '',
    add3: '',
    postcode: '',
    postcodeE: false,
    city: '',
    cityE: false,
    state: '',
    stateE: false,
    country: '',
    countryE: false,
    nationality: '',
    nationalityE: false,
    faxNo: '',
    password: '',
    passwordE: false,
    confirm_password: '',
    confirm_passwordE: false,

  };
  constructor(
    public dialogRef: MatDialogRef<RegisterFormComponent>,
    private toast: ToastrService,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) public data) {

  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }
  emailFlag = false;
  private dateFormat = new DateFormatService();
  flag = false;
  validate(value, name) {
    if (!value) {
      this.toast.error("Please fill in the " + name);
      this.flag = true;
      return true;
    }
    return false;

  }
  async submit() {
    this.flag = false;

    this.registerModel.nameE = this.validate(this.registerModel.name, "Full Name");
    this.registerModel.telephoneNoE = this.validate(this.registerModel.telephoneNo, "Phone number");
    this.registerModel.emailE = this.validate(this.registerModel.email, "Email");

    this.registerModel.icE = this.validate(this.registerModel.ic, "IC / Passport no");
    this.registerModel.posE = this.validate(this.registerModel.pos, "Position / Designation");
    this.registerModel.schoolNameE = this.validate(this.registerModel.schoolName, "School Name/ Centre Name");
    this.registerModel.add1E = this.validate(this.registerModel.add1, "School Address Line 1");
    this.registerModel.postcodeE = this.validate(this.registerModel.postcode, "Postcode");
    this.registerModel.cityE = this.validate(this.registerModel.city, "City");
    this.registerModel.stateE = this.validate(this.registerModel.state, "State");
    this.registerModel.countryE = this.validate(this.registerModel.country, "Country");
    this.registerModel.nationalityE = this.validate(this.registerModel.nationality, "Nationality");
    this.registerModel.passwordE = this.validate(this.registerModel.password, "Password");


    if (!this.validateUSMEmail(this.registerModel.email)) {
      this.toast.error("Email is not a valid USM email");
      this.registerModel.emailE = true;
      this.flag = true;
    }
    if (this.registerModel.password.length < 6) {
      this.toast.error("Password must min 6 characters");
      this.registerModel.passwordE = true;
      this.registerModel.confirm_passwordE = true;
      this.flag = true;
    }
    // if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(this.registerModel.password)) {
    //   this.toast.error("Password must min 8 characters, at least 1 uppercase, 1 lowercase, 1 number and 1 special character");
    //   this.registerModel.passwordE = true;
    //   this.registerModel.confirm_passwordE = true;
    //   this.flag = true;
    // }
    if (this.registerModel.password != this.registerModel.confirm_password) {
      this.toast.error("The password is not same");
      this.registerModel.passwordE = true;
      this.registerModel.confirm_passwordE = true;
      this.flag = true;
    }

    if (this.flag) {
      return;
    }

    const email = this.registerModel.email;
    const password = this.registerModel.password;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm submit with this information?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if(data){
        await this.angularFireAuth.auth.createUserWithEmailAndPassword(email, password)
        .then(async fbAuth => {
          let newUserInfo = {
            CreatedDate: new Date(),
            UpdatedDate: new Date(),
            CreatedBy: email,
            UpdatedBy: email,
            Email: email,
            Role: "User",
            Name: this.registerModel.name,
            TelephoneNo: this.registerModel.telephoneNo,
            IC: this.registerModel.ic,
            Position: this.registerModel.pos,
            SchoolName: this.registerModel.schoolName,
            Address1: this.registerModel.add1,
            Address2: this.registerModel.add2,
            Address3: this.registerModel.add3,
            State: this.registerModel.state,
            Postcode: this.registerModel.postcode,
            Country: this.registerModel.country,
            City: this.registerModel.city,
            Nationality: this.registerModel.nationality,
            FaxNo: this.registerModel.faxNo

          }
          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: "New User has been register. Email: " + email,
            date: new Date()
          };
          await this.firestore.collection("Users").doc(fbAuth.user.uid).set(newUserInfo);
          await this.firestore.collection('UsersLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('UsersLog').doc(dateFormat).collection('User').add(info);
          await fbAuth.user.sendEmailVerification().then(e=>{
            console.log(e);
          }).catch(error=>{
            console.log(error);
          });
          this.toast.success("Verification email has been sent, please verify", 'Register successfully', { timeOut: 3000 });
          this.dialog.open(VerifyEmailComponent, {
            width: 'auto',
            height: 'auto',
            disableClose: true,
            data: 300
          }).afterClosed().subscribe(s=>{
            this.dialogRef.close(true);

          })
        })
        .catch(err => {
          this.toast.error(err, 'Failed to register', { timeOut: 3000 });
        })
      }

    });



  }

  validateUSMEmail(value) {
    if (!(/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[A-Za-z\d.]*usm.my$/.test(value))) {
      return false;
    }
    return true;
  }

  SelectPos(pos) {
    this.registerModel.pos = pos;
  }


}
