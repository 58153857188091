import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DoubleTaxService {

  double_tax:any;
  constructor() { }

  setDoubleTax(double_tax){
    this.double_tax = double_tax;
  }
}
