
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[WordLimit]'
})
export class WordLimit {

  constructor(private el: ElementRef) { }

  @Input() WordLimit: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent>event;

    if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode == 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode == 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode == 88 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }


    if (event.target.value.split(' ').length > 149) {
      e.preventDefault();
    }

  }
}

