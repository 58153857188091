import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.css']
})
export class SelectionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SelectionComponent>,

  ) { }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }
  select(value){
    this.dialogRef.close(value)
  }
}
