import { Injectable } from '@angular/core';

import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelHelperService {

  constructor() { }

  static toExportFileName(excelFileName: string): string {

    return `${excelFileName}_export.xlsx`;
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {cellStyles:true});
    // this.wrapAndCenterCell(worksheet.E2)
    const workbook: XLSX.WorkBook = {Sheets: {data: worksheet}, SheetNames: ['data']};


    XLSX.writeFile(workbook, ExcelHelperService.toExportFileName(excelFileName));
  }


  private wrapAndCenterCell(cell: XLSX.CellObject) {
    const wrapAndCenterCellStyle = { alignment: { wrapText: true, vertical: 'center', horizontal: 'center' } };
    this.setCellStyle(cell, wrapAndCenterCellStyle);
}

  private setCellStyle(cell: XLSX.CellObject, style: {}) {
      cell.s = style;
  }


}
