import { UploadFormComponent } from './upload-form/upload-form.component';
import { UserInfoService } from 'src/app/Services/Utilities/user-info.service';
import { PidGrantService } from './../../Services/Utilities/pid-grant.service';
import { data } from 'jquery';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatPaginator, MatDialog, PageEvent, Sort, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { FundsServiceService } from 'src/app/Services/Utilities/funds-service.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { SubmitFormComponent } from '../cic/submit-form/submit-form.component';
import { NewApplicationComponent } from '../funds/new-application/new-application.component';
import fontkit from '@pdf-lib/fontkit';
import { PDFDocument, StandardFonts, layoutMultilineText, TextAlignment, rgb, PDFPage } from 'pdf-lib';

@Component({
  selector: 'app-pid-grant',
  templateUrl: './pid-grant.component.html',
  styleUrls: ['./pid-grant.component.css']
})
export class PidGrantComponent implements OnInit {


  filelist = [];
  sortedfilelist: any;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  searchbox = '';
  searchValue = '';
  sortedu = {
    active: '',
    direction: ''
  };
  dateRange: any;
  email: any;
  uuid: any;
  loading = true;

  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private storageRef: AngularFireStorage,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    public router: Router,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private dateSrv: DateFormatService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    public doubleTaxSrv: PidGrantService,
    public userSrv: UserInfoService
  ) {
    this.spinner.show();
    this.doubleTaxSrv.setPidGrant('');
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
      this.uuid = auth.uid;
      this.firestore.collection('PidGrant', ref => ref.where('UserID', '==', this.uuid).orderBy('CreatedDate', 'desc')).get().forEach(e => {
        e.forEach(file => {
          const data = {
            ...file.data(),
            id: file.id,
            fileName: file.data().FileNames ? file.data().FileNames.join('\n') : "Not yet upload",
            CreatedDate: file.data().CreatedDate ? new Date(file.data().CreatedDate.toDate()) : '',
            SubmittedDate: file.data().SubmittedDate ? new Date(file.data().SubmittedDate.toDate()) : ''
          }
          this.filelist.push(data);
          this.sortedfilelist = this.filelist.slice(this.offset, (this.offset + this.pageSize));
          this.loading = false;
          this.length = this.filelist.length;
        })
      });
      this.spinner.hide();
    });

  }

  ngOnInit() {
  }

  view(file) {
    this.doubleTaxSrv.setPidGrant(file);
    this.router.navigate(['/Home/pid-grant/View'])
  }

  SetupFile() {
    this.loading = true;

    this.filelist = [];
    this.sortedfilelist = [];
    this.firestore.collection('PidGrant', ref => ref.where('UserID', '==', this.uuid).orderBy('CreatedDate', 'desc')).get().forEach(e => {
      e.forEach(file => {
        const data = {
          ...file.data(),
          id: file.id,
          fileName: file.data().FileNames ? file.data().FileNames.join('\n') : "Not yet upload",
          CreatedDate: file.data().CreatedDate ? new Date(file.data().CreatedDate.toDate()) : '',
          SubmittedDate: file.data().SubmittedDate ? new Date(file.data().SubmittedDate.toDate()) : ''
        }
        this.filelist.push(data);
        this.sortedfilelist = this.filelist.slice(this.offset, (this.offset + this.pageSize));
        this.loading = false;
        this.length = this.filelist.length;
      })
    });
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    this.sortedfilelist = this.filelist.slice();
    this.sortFile();
    this.limitFile();
  }

  limitFile() {
    this.sortedfilelist = this.sortedfilelist.slice(this.offset, (this.offset + this.pageSize));
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.sortedfilelist = this.filelist.slice();
    if (this.status || this.fileName || this.dateRange) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !(this.status || this.fileName || this.dateRange)) {
      this.sortedfilelist = this.filelist.slice();
      this.limitFile();
      return;
    }

    this.sortedfilelist = this.sortedfilelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'file': return this.compare(a.fileName, b.fileName, isAsc);
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'date': return this.compareDate(a.CreatedDate, b.CreatedDate, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        default: return 0;
      }
    });
    this.limitFile();
  }

  sortFile() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.sortedfilelist = this.sortedfilelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'file': return this.compare(a.fileName, b.fileName, isAsc);
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'date': return this.compareDate(a.CreatedDate, b.CreatedDate, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  reset() {
    this.fileName = '';
    this.status = 'All';
    this.dateRange = '';
    this.SetupFile();
  }
  search() {
    let startDate;
    let endDate;

    this.sortedfilelist = this.filelist.filter(u => String(u.id).toLowerCase().includes(this.fileName.toLowerCase()));
    if (this.status !== 'All')
      this.sortedfilelist = this.sortedfilelist.filter(u => u.Status === this.status);
    if (typeof (this.dateRange) === 'object') {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        startDate = this.dateRange.startDate.toDate();
        endDate = this.dateRange.endDate.toDate();
        this.sortedfilelist = this.sortedfilelist.filter(u =>
          u.CreatedDate.getTime() >= startDate.getTime() && u.CreatedDate <= endDate.getTime());

      }
    }
    this.length = this.sortedfilelist.length;
    this.sortFile();
    this.limitFile();
  }

  DS() {
    let startDate;
    let endDate;

    this.sortedfilelist = this.filelist.filter(u => String(u.id).toLowerCase().includes(this.fileName.toLowerCase()));
    if (this.status !== 'All')
      this.sortedfilelist = this.sortedfilelist.filter(u => u.Status === this.status);
    if (typeof (this.dateRange) === 'object') {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        startDate = this.dateRange.startDate.toDate();
        endDate = this.dateRange.endDate.toDate();
        this.sortedfilelist = this.sortedfilelist.filter(u =>
          u.CreatedDate.getTime() >= startDate.getTime() && u.CreatedDate <= endDate.getTime());

      }
    }

    this.length = this.sortedfilelist.length;
    this.sortFile();
  }

  status = "All"
  fileName = '';
  applicationList = [];
  SelectStatus(status) {
    this.status = status;
  }


  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  FillForm() {
    this.router.navigate(['/Home/pid-grant/Add'])
  }

  private dateFormat = new DateFormatService();

  edit(file) {
    this.doubleTaxSrv.setPidGrant(file);
    this.router.navigate(['/Home/pid-grant/Edit'])

  }

  cancel(file) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm want to cancel this application';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          await this.firestore.collection("PidGrant").doc(file.id).update({ Status: "Canceled", UpdatedDate: new Date(), UpdatedBy: this.email });
          file.Status = "Canceled";

          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: "Application " + file.id + " has been canceled by Email: " + this.email,
            date: new Date()
          };
          await this.firestore.collection('PidGrantLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('PidGrantLog').doc(dateFormat).collection('User').add(info);


          this.spinner.hide();
          this.toaster.success("Cancel Successfully");
        } catch (error) {
          this.toaster.error(error, "Error happen");
          this.spinner.hide();

        }
      }
    })
  }

  remove(file) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm want to delete this application';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          await this.firestore.collection("PidGrant").doc(file.id).delete().then(async e => {
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
            const info = {
              message: "Application " + file.id + " has been deleted by Email: " + this.email,
              date: new Date()
            };
            await this.firestore.collection('PidGrantLog').doc(dateFormat).set({ Date: new Date() });
            await this.firestore.collection('PidGrantLog').doc(dateFormat).collection('User').add(info);

            this.spinner.hide();
            this.toaster.success("Delete Successfully");
            this.SetupFile();
          });
        } catch (error) {
          this.toaster.error(error, "Error happen");
          this.spinner.hide();

        }
      }
    })
  }



  clone(file) {
    this.spinner.show();

    try {
      this.firestore.collection("PidGrantID").doc("ID").get().forEach(async item => {
        let ID = ('000' + item.data().ID).slice(-3);
        ID = `PD${new Date().getFullYear()}-${ID}`;
        this.firestore.collection('PidGrant').doc(file.id).get().forEach(async e => {
          if (e.exists) {

            let info = {
              ...e.data(),
              CreatedDate: new Date(),
              UpdatedDate: new Date(),
              Status: "Draft",
            }
            await this.firestore.collection("PidGrant").doc(ID).set(info);
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
            const infos = {
              message: "Application" + file.id + " has been cloned to  " + ID + " by " + this.email,
              date: new Date(),
              data: JSON.stringify(info),
            };
            await this.firestore.collection('PidGrantLog').doc(dateFormat).set({ Date: new Date() });
            await this.firestore.collection('PidGrantLog').doc(dateFormat).collection('User').add(infos);
            await this.firestore.collection('PidGrantID').doc("ID").set({ ID: item.data().ID + 1 })

            this.toaster.success("Clone successfully");
            this.SetupFile();
          }
          this.spinner.hide();
        });
      })

    } catch (error) {
      this.spinner.hide();
      this.toaster.error("Failed to clone");

    }

  }

  async download(file) {
    try {
      this.spinner.show();
      await this.generateFile(file);
      this.spinner.hide();
    } catch (error) {
      this.toaster.error(error, "Error happen");
      this.spinner.hide();
    }
  }

  async generateFile(file) {
    this.spinner.show();

    let f1 = file.firstFormGroup
    let f2 = file.secondFormGroup
    let f3 = file.thirdFormGroup
    let f4 = file.fourthFormGroup
    let f5 = file.fifthFormGroup
    let f6 = file.sixthFormGroup
    let f7 = file.seventhFormGroup
    let f8 = file.eightFormGroup
    let f9 = file.ninthFormGroup
    let f10 = file.budgetFormGroup
    let f11 = file.ipForm

    this.pdfDoc = await PDFDocument.create();
    const font = await this.pdfDoc.embedFont(StandardFonts.TimesRoman)

    const fontBold = await this.pdfDoc.embedFont(StandardFonts.TimesRomanBold)

    this.currentPage = this.pdfDoc.addPage();
    this.currentY = 800;
    this.currentX = 50;
    this.currentFontSize = 18;
    this.autoText('Researcher Details', fontBold);
    this.currentY = this.currentY - 10;

    this.currentFontSize = 12;
    this.autoText('Applicant Name ', font);
    this.autoText(': ' + f1.Applicant, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('School', font);
    this.autoText(': ' + f1.School, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Email', font);
    this.autoText(': ' + f1.Email, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Phone number', font);
    this.autoText(': ' + f1.Phone, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Designation', font);
    this.autoText(': ' + f1.Designation, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('SCOPUS Author ID', font);
    this.autoText(': ' + f1.Author, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);


    this.autoText('ORCiD', font);
    this.autoText(': ' + f1.ORCiD, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);


    this.autoText('Type of Service', font);
    this.autoText(': ' + f1.Service, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
    if (typeof (f1.ExpiredDate) === 'object') {
      let dd = new Date(f1.ExpiredDate.toDate());
      let dds = ('0' + dd.getDate()).slice(-2)
        + '-' + ('0' + (dd.getMonth() + 1)).slice(-2)
        + '-' + dd.getFullYear().toString();
  
      this.autoText('Contract Expiry Date', font);
      this.autoText(': ' + dds, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
  
  
    }
    
    if (f1.ProjectMembers.length > 0) {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('Project Members', fontBold);
    }

    let i = 1;
    for (const d of f1.ProjectMembers) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Name, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('School ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.School, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Email ', font, false, this.currentY, true, 70, true);
      this.autoText(':' + d.Email, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Contribution ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Contribution, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }


    this.currentFontSize = 12;


    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Project Title ', font);
    this.currentFontSize = 12;
    this.autoText(f2.ProjectTitle, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Keywords ', font);
    this.currentFontSize = 12;
    this.autoText(f2.Keywords, font);

    if (typeof (f2.StartDate) === 'object') {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('Start Date ', font);

      let d = new Date(f2.StartDate.toDate());
      let dateString = ('0' + d.getDate()).slice(-2)
        + '-' + ('0' + (d.getMonth() + 1)).slice(-2)
        + '-' + d.getFullYear().toString();
      this.currentFontSize = 12;
      this.autoText(dateString, font);
    }

    if (typeof (f2.EndDate) === 'object') {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('End Date ', font);

      let d = new Date(f2.EndDate.toDate());
      let dateString = ('0' + d.getDate()).slice(-2)
        + '-' + ('0' + (d.getMonth() + 1)).slice(-2)
        + '-' + d.getFullYear().toString();
      this.currentFontSize = 12;
      this.autoText(dateString, font);
    }

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Project Duration (Months) ', font);
    this.currentFontSize = 12;
    this.autoText(f2.ProjectDuration, font);

    this.currentFontSize = 16;
    this.currentY = this.currentY - 20;
    this.autoText('Collaborator Information', fontBold);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Company Name', font);
    this.currentFontSize = 12;
    this.autoText(f3.CompanyName, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Company Address', font);
    this.currentFontSize = 12;
    this.autoText(f3.CompanyAddress, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Company SSM Number', font);
    this.currentFontSize = 12;
    this.autoText(f3.CompanySSMNumber, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Sector Industry', font);
    this.currentFontSize = 12;
    this.autoText(f3.SectorIndustry, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Brief Description of the Company', font);
    this.currentFontSize = 12;
    this.autoText(f3.CompanyDescription, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Has the company received any grant or other financial or non-financial assistance related to the project from any Government/State Agency in the last 3 years.', font);
    this.currentFontSize = 12;
    this.autoText(f3.CompanyGrant, font);

    if (f3.CompanyGrant == 'true') {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('Please state the information in detail, including the Agency involved, amount and received and year.', font);
      this.currentFontSize = 12;
      this.autoText(f3.CompanyGrantDetail, font);
    }

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText("Company's Needs In The Project ", font);
    this.currentFontSize = 12;
    this.autoText(f3.CompanyNeeds, font);

    this.currentFontSize = 16;
    this.currentY = this.currentY - 20;
    this.autoText('Employees Information', fontBold);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText("Total number of employees", font);
    this.currentFontSize = 12;
    this.autoText(f3.totalEmployee, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText("Malaysian Citizen Employee", font);
    this.currentFontSize = 12;
    this.autoText(f3.mEmployee, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText("Non Malaysian Citizen Employee", font);
    this.currentFontSize = 12;
    this.autoText(f3.nmEmployee, font);


    this.currentFontSize = 16;
    this.currentY = this.currentY - 20;
    this.autoText('Sales Information', fontBold);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText("Year 1 ", font);
    this.currentFontSize = 12;
    this.autoText(f3.year1, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText("Year 2 ", font);
    this.currentFontSize = 12;
    this.autoText(f3.year2, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText("Year 3", font);
    this.currentFontSize = 12;
    this.autoText(f3.year3, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Company Contact Person', font);

    this.currentFontSize = 12;
    this.autoText('Name ', font);
    this.autoText(': ' + f3.CompanyContactPersonName, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('I.C Number', font);
    this.autoText(': ' + f3.CompanyContactPersonICNumber, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Postion', font);
    this.autoText(': ' + f3.CompanyContactPersonPosition, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Email', font);
    this.autoText(': ' + f3.CompanyContactPersonEmail, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Phone', font);
    this.autoText(': ' + f3.CompanyContactPersonPhone, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);



    this.currentFontSize = 16;
    this.currentY = this.currentY - 20;
    this.autoText('Project Decsription', fontBold);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Research Domain', font);
    this.currentFontSize = 12;
    this.autoText(f4.ResearchDomain, font);


    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Research Cluster', font);
    this.currentFontSize = 12;
    this.autoText(f4.ResearchCluster, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Sustainable Development Goals', font);
    this.currentFontSize = 12;
    this.autoText(f4.Goal, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('My STIE 10x10 Science & Technology Driver ', font);
    this.currentFontSize = 12;
    this.autoText(f4.Driver, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Executive Summary ', font);
    this.currentFontSize = 12;
    this.autoText(f4.ExecutiveSummary, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Problem Statement ', font);
    this.currentFontSize = 12;
    this.autoText(f4.ProblemStatement, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Objectives ', font);
    this.currentFontSize = 12;
    this.autoText(f4.Objectives, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Research Background and References ', font);
    this.currentFontSize = 12;
    this.autoText(f4.ResearchBackground, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Methodology ', font);
    this.currentFontSize = 12;
    this.autoText(f4.Methodology, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Current Technology Readiness Level (TRL) of Proposed Solution ', font);
    this.currentFontSize = 12;
    this.autoText(f4.CurrentTrl, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Expected Impact', font);
    this.currentFontSize = 12;
    this.autoText(f4.ExpectedResearch, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Targeted Technology Readiness Level (TRL) of Project ', font);
    this.currentFontSize = 12;
    this.autoText(f4.TargetTrl, font);

    if (f4.AccessToEquipment.length > 0) {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('Access To Equipment & Facilities', fontBold);
    }

    i = 1;
    for (const d of f4.AccessToEquipment) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Type', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Type, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Description ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Description, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Owner ', font, false, this.currentY, true, 70, true);
      this.autoText(':' + d.Owner, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Location ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Location, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }

    this.currentFontSize = 16;
    this.currentY = this.currentY - 20;
    this.autoText('Project Budget', fontBold);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Vot 11000 - Allowance', font);

    this.currentFontSize = 12;
    this.autoText('Description ', font);
    this.autoText(': ' + f10.AllowanceDesc, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Company', font);
    this.autoText(': RM' + f10.AllowanceCompany, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('USM', font);
    this.autoText(': RM' + f10.AllowanceUSM, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Total', font);
    this.autoText(': RM' + this.computeTotal('Allowance', f10), font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Vot 21000 - Travelling', font);

    this.currentFontSize = 12;
    this.autoText('Description ', font);
    this.autoText(': ' + f10.TravelDesc, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Company', font);
    this.autoText(': RM' + f10.TravelCompany, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('USM', font);
    this.autoText(': RM' + f10.TravelUSM, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Total', font);
    this.autoText(': RM' + this.computeTotal('Travel', f10), font, true, this.currentY + this.currentFontSize + 4, true, 180, true);


    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Vot 24000 - Rental', font);

    this.currentFontSize = 12;
    this.autoText('Description ', font);
    this.autoText(': ' + f10.RentalDesc, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Company', font);
    this.autoText(': RM' + f10.RentalCompany, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('USM', font);
    this.autoText(': RM' + f10.RentalUSM, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Total', font);
    this.autoText(': RM' + this.computeTotal('Rental', f10), font, true, this.currentY + this.currentFontSize + 4, true, 180, true);


    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Vot 27000 - Research Materials and Supplies', font);

    this.currentFontSize = 12;
    this.autoText('Description ', font);
    this.autoText(': ' + f10.ResearchDesc, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Company', font);
    this.autoText(': RM' + f10.ResearchCompany, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('USM', font);
    this.autoText(': RM' + f10.ResearchUSM, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Total', font);
    this.autoText(': RM' + this.computeTotal('Research', f10), font, true, this.currentY + this.currentFontSize + 4, true, 180, true);


    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Vot 28000 - Maintenance and Minor Repair Services', font);

    this.currentFontSize = 12;
    this.autoText('Description ', font);
    this.autoText(': ' + f10.MaintenanceDesc, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Company', font);
    this.autoText(': RM' + f10.MaintenanceCompany, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('USM', font);
    this.autoText(': RM' + f10.MaintenanceUSM, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);


    this.autoText('Total', font);
    this.autoText(': RM' + this.computeTotal('Maintenance', f10), font, true, this.currentY + this.currentFontSize + 4, true, 180, true);


    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Vot 29000 - Professional Services', font);

    this.currentFontSize = 12;
    this.autoText('Description ', font);
    this.autoText(': ' + f10.ProfessionalDesc, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Company', font);
    this.autoText(': RM' + f10.ProfessionalCompany, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('USM', font);
    this.autoText(': RM' + f10.ProfessionalUSM, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Total', font);
    this.autoText(': RM' + this.computeTotal('Professional', f10), font, true, this.currentY + this.currentFontSize + 4, true, 180, true);


    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Vot 35000 - Accessories and Equipment', font);

    this.currentFontSize = 12;
    this.autoText('Description ', font);
    this.autoText(': ' + f10.AccessoriesDesc, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);


    this.autoText('Company', font);
    this.autoText(': RM' + f10.AccessoriesCompany, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('USM', font);
    this.autoText(': RM' + f10.AccessoriesUSM, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Total', font);
    this.autoText(': RM' + this.computeTotal('Accessories', f10), font, true, this.currentY + this.currentFontSize + 4, true, 180, true);


    this.currentY = this.currentY - 20;
    this.currentFontSize = 14;

    this.autoText('Total Budget ', font);
    this.autoText(': RM ' + this.computeTotal('Total', f10), font, true, this.currentY + this.currentFontSize + 4, true, 230, true);

    this.autoText('USM Management Fees (5%)', font);
    this.autoText(': RM ' + (f10.ServiceCharge || 0), font, true, this.currentY + this.currentFontSize + 4, true, 230, true);

    this.autoText('Grand Total of all Budget', font);
    this.autoText(': RM ' + this.computeTotal('GrandTotal', f10), font, true, this.currentY + this.currentFontSize + 4, true, 230, true);

    this.currentFontSize = 16;
    this.currentY = this.currentY - 20;
    this.autoText('Track Record', fontBold);

    if (f5.Publication.length > 0) {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('List of Related Publications (Max 3)', fontBold);
    }

    i = 1;
    for (const d of f5.Publication) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Publication Title', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Title, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Name of Journal ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Name, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Year ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Year, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Role ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Role, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }

    if (f5.IP.length > 0) {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('List of Related Intellectual Properties  (Max 3)', fontBold);
    }

    i = 1;
    for (const d of f5.IP) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Title of IP', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Title, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Type of IP ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Type, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Filing/Granted No', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Number, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Filing/Granted Date  ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Date, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Status  ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Status, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }

    if (f5.Grant.length > 0) {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('List of Related Grants (Max 3)', fontBold);
    }

    i = 1;
    for (const d of f5.Grant) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Grant Title', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Title, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Name of Funder ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Funder, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Role ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Role, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Status  ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Status, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }

    if (f5.Grant.length > 0) {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('List of Related Consultations', fontBold);
    }

    i = 1;
    for (const d of f5.Consultation) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Name, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Value (RM)', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Value, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }

    this.currentFontSize = 16;
    this.currentY = this.currentY - 20;
    this.autoText('Risk Assesment', fontBold);

    if (f6.Risks.length > 0) {
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('List of Risk', fontBold);
    }

    i = 1;
    for (const d of f6.Risks) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Risk Item', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Item, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Risk Item', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Statement, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Risk Level ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Level, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Risk Response', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Response, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }

    this.currentFontSize = 16;
    this.currentY = this.currentY - 20;
    this.autoText('Expected Deliverables', fontBold);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Intellectual Property Filing', font);
    this.currentFontSize = 12;
    this.autoText(f7.IPF, font);

    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Commercialisation', font);
    this.currentFontSize = 12;
    this.autoText(f7.Commercialisation, font);


    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Publication', font);
    this.currentFontSize = 12;
    this.autoText(f7.Publication, font);


    this.currentFontSize = 14;
    this.currentY = this.currentY - 10;
    this.autoText('Pre-Commercialization and Future Grant Application', font);
    this.currentFontSize = 12;
    this.autoText(f7.PreCommer, font);

    if (f7.PreCommer === 'Other') {

      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('Other Description', font);
      this.currentFontSize = 12;
      this.autoText(f7.PreCommerOther, font);
    }

    if(f11){
      this.currentFontSize = 16;
      this.currentY = this.currentY - 20;
      this.autoText('Background Intellectual Property (IP) Information', fontBold);

      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('Name of The Background IP', font);
      this.currentFontSize = 12;
      this.autoText(f11.Name, font);

      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('Type of IP Filed/Granted', font);
      this.currentFontSize = 12;
      this.autoText(f11.TypeOfIp, font);

      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('Date of IP(s) Filed/Granted', font);
      this.currentFontSize = 12;
      this.autoText(f11.Date, font);

      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('Main Inventor', font);
      this.currentFontSize = 12;
      this.autoText(f11.Main, font);

      if (f11.Joint.length > 0) {
        this.currentFontSize = 14;
        this.currentY = this.currentY - 10;
        this.autoText('Joints Inventor', fontBold);
      }
  
      i = 1;
      for (const d of f11.Joint) {
        this.currentFontSize = 12;
        this.autoText('(' + i + ')', font);
        this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
        this.autoText(': ' + d.Name, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
          i++
      }

      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('Description of Background IP', font);
      this.currentFontSize = 12;
      this.autoText(f11.Description, font);
  
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('Relevancy of Background IP to Proposed PRIME Project', font);
      this.currentFontSize = 12;
      this.autoText(f11.Relevancy, font);

      this.currentFontSize = 16;
      this.currentY = this.currentY - 20;
      this.autoText('Foreground Intellectual Property (IP) Information', fontBold);

      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('Potential Name of Foreground IP', font);
      this.currentFontSize = 12;
      this.autoText(f11.FName, font);

      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('Type of IP To Be Filed', font);
      this.currentFontSize = 12;
      this.autoText(f11.FTypeOfIp, font);

      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('Main Inventor', font);
      this.currentFontSize = 12;
      this.autoText(f11.FMain, font);

      if (f11.FJoint.length > 0) {
        this.currentFontSize = 14;
        this.currentY = this.currentY - 10;
        this.autoText('Joints Inventor', fontBold);
      }
  
      i = 1;
      for (const d of f11.FJoint) {
        this.currentFontSize = 12;
        this.autoText('(' + i + ')', font);
        this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
        this.autoText(': ' + d.Name, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
          i++
      }
      
      this.currentFontSize = 14;
      this.currentY = this.currentY - 10;
      this.autoText('Description of Foreground IP', font);
      this.currentFontSize = 12;
      this.autoText(f11.FDescription, font);

    }


    const pdfBytes = await this.pdfDoc.save()
    let f = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(f);
    var anchor = document.createElement("a");
    anchor.download = file.id + ".pdf";
    anchor.href = fileURL;
    anchor.click();
    this.spinner.hide();


  }

  pdfDoc: PDFDocument;
  currentX = 0;
  currentY = 0;
  currentPage: PDFPage;
  currentFontSize = 12

  async autoText(text, font, fixedYPosition = false, y = 0, fixedXPosition = false, x = 0, correctWidth = false) {
    let multiText = layoutMultilineText(text,
      {
        alignment: TextAlignment.Left,
        fontSize: this.currentFontSize,
        font: font,
        bounds: { x: this.currentX, y: this.currentY - this.currentFontSize - 5, width: correctWidth ? 350 : 500, height: 600 }
      })

    if (fixedYPosition)
      this.currentY = y;

    for (let i = 0; i < multiText.lines.length; i++) {
      this.currentPage.drawText(`${multiText.lines[i].text}`, {
        x: fixedXPosition ? x : this.currentX,
        y: this.currentY,
        size: this.currentFontSize,
        font: font,
        color: rgb(0, 0, 0)
      })

      if (this.currentY < 80) {
        this.currentPage = this.pdfDoc.addPage();
        this.currentY = 780
      }
      this.currentY = this.currentY - this.currentFontSize - 4;

    }
  }

  computeTotal(type, f) {
    switch (type) {
      case 'Allowance':
        return !isNaN(
          parseFloat(f.AllowanceCompany) +
          parseFloat(f.AllowanceUSM)
        ) ? (parseFloat(f.AllowanceCompany) +
          parseFloat(f.AllowanceUSM)).toFixed(2) : '0.00'

      case 'Travel':
        return !isNaN(
          parseFloat(f.TravelCompany) +
          parseFloat(f.TravelUSM)
        ) ? (parseFloat(f.TravelCompany) +
          parseFloat(f.TravelUSM)).toFixed(2) : '0.00'

      case 'Rental':
        return !isNaN(
          parseFloat(f.RentalCompany) +
          parseFloat(f.RentalUSM)
        ) ? (parseFloat(f.RentalCompany) +
          parseFloat(f.RentalUSM)).toFixed(2) : '0.00'

      case 'Research':
        return !isNaN(
          parseFloat(f.ResearchCompany) +
          parseFloat(f.ResearchUSM)
        ) ? (parseFloat(f.ResearchCompany) +
          parseFloat(f.ResearchUSM)).toFixed(2) : '0.00'

      case 'Maintenance':
        return !isNaN(
          parseFloat(f.MaintenanceCompany) +
          parseFloat(f.MaintenanceUSM)
        ) ? (parseFloat(f.MaintenanceCompany) +
          parseFloat(f.MaintenanceUSM)).toFixed(2) : '0.00'

      case 'Professional':
        return !isNaN(
          parseFloat(f.ProfessionalCompany) +
          parseFloat(f.ProfessionalUSM)
        ) ? (parseFloat(f.ProfessionalCompany) +
          parseFloat(f.ProfessionalUSM)).toFixed(2) : '0.00'

      case 'Accessories':
        return !isNaN(
          parseFloat(f.AccessoriesCompany) +
          parseFloat(f.AccessoriesUSM)
        ) ? (parseFloat(f.AccessoriesCompany) +
          parseFloat(f.AccessoriesUSM)).toFixed(2) : '0.00'
      case 'Total':
      case 'GrandTotal':
        let total = !isNaN(
          parseFloat(f.AllowanceCompany) +
          parseFloat(f.AllowanceUSM) +
          parseFloat(f.TravelCompany) +
          parseFloat(f.TravelUSM) +
          parseFloat(f.RentalCompany) +
          parseFloat(f.RentalUSM) +
          parseFloat(f.ResearchCompany) +
          parseFloat(f.ResearchUSM) +
          parseFloat(f.MaintenanceCompany) +
          parseFloat(f.MaintenanceUSM) +
          parseFloat(f.ProfessionalCompany) +
          parseFloat(f.ProfessionalUSM) +
          parseFloat(f.AccessoriesCompany) +
          parseFloat(f.AccessoriesUSM) 
        ) ?
          (
            parseFloat(f.AllowanceCompany) +
            parseFloat(f.AllowanceUSM) +
            parseFloat(f.TravelCompany) +
            parseFloat(f.TravelUSM) +
            parseFloat(f.RentalCompany) +
            parseFloat(f.RentalUSM) +
            parseFloat(f.ResearchCompany) +
            parseFloat(f.ResearchUSM) +
            parseFloat(f.MaintenanceCompany) +
            parseFloat(f.MaintenanceUSM) +
            parseFloat(f.ProfessionalCompany) +
            parseFloat(f.ProfessionalUSM) +
            parseFloat(f.AccessoriesCompany) +
            parseFloat(f.AccessoriesUSM) 
          ) : 0;

        if (type === 'Total')
          return total.toFixed(2);

        else if (type === 'GrandTotal')
          return (total + parseFloat(f.ServiceCharge || 0)).toFixed(2);


    }
  }




  async addText(text, x, y, width, height, page, font, nogap = false, fontsize = 12) {
    let multiText = layoutMultilineText(text,
      {
        alignment: TextAlignment.Left,
        fontSize: fontsize,
        font: font,
        bounds: { x: x, y: y, width: width, height: height }
      })

    for (let i = 0; i < multiText.lines.length; i++) {
      if (nogap)
        page.drawText(`${multiText.lines[i].text}`, {
          x: multiText.lines[i].x,
          y: multiText.lines[i].y,
          size: fontsize,
          font: font,
          color: rgb(0, 0, 0)
        })
      else
        page.drawText(`${multiText.lines[i].text}`, {
          x: multiText.lines[i].x,
          y: i > 0 ? multiText.lines[i].y - (7 * i) : multiText.lines[i].y,
          size: fontsize,
          font: font,
          color: rgb(0, 0, 0)
        })
    }
  }

  upload(file) {
    this.dialog.open(UploadFormComponent, {
      width: '70%',
      height: '80%',
      disableClose: true,
      data: { file: file.id, type: 1, name: file.firstFormGroup.Applicant }
    }).afterClosed().subscribe(r => {
      this.SetupFile();
    });
  }

  async downloadTemplate() {
    const url = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/Garis%20Panduan%20Geran%20PRIME.pdf?alt=media&token=680b2507-4aa7-4faf-aaef-23262eb2fee8'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    let file = new Blob([existingPdfBytes], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = "GarisPanduanGeranPRIME.pdf";
    anchor.href = fileURL;
    anchor.click();

  }

}
