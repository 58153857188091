import { PidGrantService } from '../../../Services/Utilities/pid-grant.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { MatDatepicker, MatDialog, MatTabChangeEvent, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PDFDocument, PDFPage, layoutMultilineText, TextAlignment, rgb, StandardFonts } from 'pdf-lib';

@Component({
  selector: 'app-view-pid-grant',
  templateUrl: './view-pid-grant.component.html',
  styleUrls: ['./view-pid-grant.component.css']
})
export class ViewPidGrantComponent implements OnInit {

  todayDate = new Date();
  firstFormGroup = this._formBuilder.group({
    Applicant: ['', Validators.required],
    School: ['', Validators.required],
    Email: ['', Validators.required],
    Phone: [''],
    Designation: ['', Validators.required],
    Author: ['', Validators.required],
    ORCiD: ['', Validators.required],
    Service: ['', Validators.required],
    ExpiredDate: [new Date()],
    ProjectMembers: this._formBuilder.array([]),
  });
  secondFormGroup = this._formBuilder.group({
    ProjectTitle: ['', Validators.required],
    Keywords: ['', Validators.required],
    StartDate: [new Date(), Validators.required],
    EndDate: [new Date(), Validators.required],
    ProjectDuration: ['', Validators.required],
  });
  thirdFormGroup = this._formBuilder.group({
    CompanyName: ['', Validators.required],
    CompanyAddress: ['', Validators.required],
    CompanySSMNumber: ['', Validators.required],
    CompanyContactPersonName: ['', Validators.required],
    CompanyContactPersonICNumber: ['', Validators.required],
    CompanyContactPersonPosition: ['', Validators.required],
    CompanyContactPersonEmail: ['', Validators.required],
    CompanyContactPersonPhone: ['', Validators.required],
    CompanyDescription: ['', Validators.required],
    totalEmployee: ['', Validators.required],
    mEmployee: ['', Validators.required],
    nmEmployee: ['', Validators.required],
    year1: ['', Validators.required],
    year2: ['', Validators.required],
    year3: ['', Validators.required],
    CompanyNeeds: ['', Validators.required],
    CompanyGrant: [false, Validators.required],
    CompanyGrantDetail: ['', Validators.required],
    SectorIndustry: ['', Validators.required],
  });
  fourthFormGroup = this._formBuilder.group({
    ExecutiveSummary: ['', Validators.required],
    ProblemStatement: ['', Validators.required],
    Objectives: ['', Validators.required],
    ResearchBackground: ['', Validators.required],
    Methodology: ['', Validators.required],
    ExpectedResearch: ['', Validators.required],
    ResearchDomain: ['', Validators.required],
    ResearchCluster: ['', Validators.required],
    Goal: ['', Validators.required],
    Driver: ['', Validators.required],
    CurrentTrl: ['', Validators.required],
    TargetTrl: ['', Validators.required],
    AccessToEquipment: this._formBuilder.array([]),
  });
  fifthFormGroup = this._formBuilder.group({
    Publication: this._formBuilder.array([]),
    IP: this._formBuilder.array([]),
    Grant: this._formBuilder.array([]),
    Consultation: this._formBuilder.array([]),
  });
  sixthFormGroup = this._formBuilder.group({
    Risks: this._formBuilder.array([]),
  });
  seventhFormGroup = this._formBuilder.group({
    IPF: ['', Validators.required],
    Commercialisation: ['', Validators.required],
    Publication: ['', Validators.required],
    PreCommer: ['', Validators.required],
    PreCommerOther: ['', Validators.required],
  });
  eightFormGroup = this._formBuilder.group({
    Additional: [[]],
    Flow: ['', Validators.required],
    Compilation: ['', Validators.required],
    Recommendation: ['', Validators.required],
    ProjectGantt: ['', Validators.required],
    OfferLetter: ['', Validators.required],
    Commitment: ['', Validators.required]
  });
  ninthFormGroup = this._formBuilder.group({
    Agree1: [false, Validators.required],
    Agree2: [false, Validators.required],
    Agree3: [false, Validators.required],
    Agree4: [false, Validators.required],
  });
  budgetFormGroup = this._formBuilder.group({
    AllowanceDesc: ['', Validators.required],
    AllowanceCompany: [0, Validators.required],
    AllowanceUSM: [0, Validators.required],
    TravelDesc: ['', Validators.required],
    TravelCompany: [0, Validators.required],
    TravelUSM: [0, Validators.required],
    RentalDesc: ['', Validators.required],
    RentalCompany: [0, Validators.required],
    RentalUSM: [0, Validators.required],
    ResearchDesc: ['', Validators.required],
    ResearchCompany: [0, Validators.required],
    ResearchUSM: [0, Validators.required],
    MaintenanceDesc: ['', Validators.required],
    MaintenanceTotal: [0, Validators.required],
    MaintenanceCompany: [0, Validators.required],
    MaintenanceUSM: [0, Validators.required],
    ProfessionalDesc: ['', Validators.required],
    ProfessionalCompany: [0, Validators.required],
    ProfessionalUSM: [0, Validators.required],
    AccessoriesDesc: ['', Validators.required],
    AccessoriesCompany: [0, Validators.required],
    AccessoriesUSM: [0, Validators.required],
    ServiceCharge: [0, Validators.required]
  });
  ipForm = this._formBuilder.group({
    Name: ['', Validators.required],
    TypeOfIp: ['', Validators.required],
    Date: ['', Validators.required],
    Main: ['', Validators.required],
    Joint: this._formBuilder.array([]),
    Description: ['', Validators.required],
    Relevancy: ['', Validators.required],
    FName: ['', Validators.required],
    FTypeOfIp: ['', Validators.required],
    FMain: ['', Validators.required],
    FJoint: this._formBuilder.array([]),
    FDescription: ['', Validators.required],
  });



  projectMember = {
    Name: '',
    School: '',
    Email: '',
    Contribution: '',
  }

  accessToEquipment = {
    Type: '',
    Description: '',
    Owner: '',
    Location: '',
  }

  commitment = {
    Month: '',
    Year: '',
    Amount: 0,
  }

  publication = {
    Title: '',
    Name: '',
    Year: '',
    Role: ''
  }

  intelliectual = {
    Title: '',
    Type: '',
    Number: '',
    Date: '',
    Status: ''
  }

  grant = {
    Title: '',
    Funder: '',
    Role: '',
    Status: ''
  }

  consultation = {
    Name: '',
    Value: 0
  }

  technologys = {
    Name: '',
    Value: 0
  }

  product = {
    Name: '',
    Value: 0
  }

  industrialPatner = {
    Name: '',
  }

  risk = {
    Item: '',
    Statement: '',
    Level: '',
    Response: '',
  }

  @ViewChild('picker', { read: MatDatepicker, static: false }) datePickerElement = MatDatepicker;

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: true,

  };

  email;
  uuid;
  edit = false;
  editID;

  Goals = [
    'GOAL 1: No Poverty',
    'GOAL 2: Zero Hunger',
    'GOAL 3: Good Health and Well-being',
    'GOAL 4: Quality Education',
    'GOAL 5: Gender Equality',
    'GOAL 6: Clean Water and Sanitation',
    'GOAL 7: Affordable and Clean Energy',
    'GOAL 8: Decent Work and Economic Growth',
    'GOAL 9: Industry, Innovation and Infrastructure',
    'GOAL 10: Reduced Inequality',
    'GOAL 11: Sustainable Cities and Communities',
    'GOAL 12: Responsible Consumption and Production',
    'GOAL 13: Climate Action',
    'GOAL 14: Life Below Water',
    'GOAL 15: Life on Land',
    'GOAL 16: Peace and Justice Strong Institutions',
    'GOAL 17: Partnerships to Achieve the Goal',
  ]

  Techs = [
    '5G/6G',
    'Sensor Technology',
    '4D/5D Printing	',
    'Advanced Materials',
    'Advanced Intelligent Systems',
    'Cybersecurity & Encryption',
    'Augmented Analytics & Data Discovery',
    'Blockchain',
    'Neurotechnology',
    'Bioscience Technology',
  ]

  Technologies = [
    'TRL 1 - Basic Principle',
    'TRL 2 - Formulation of Concept',
    'TRL 3 - Experimental Proof of Concept',
    'TRL 4 - Lab Validation',
    'TRL 5 - Validation in Real Environment',
    'TRL 6 - Demonstration in Real Environment',
    'TRL 7 - Demonstration in Prototype',
    'TRL 8 - Product Complete and Qualified',
    'TRL 9 - Product Proven',
  ]

  Market = [
    'Product Advertisement and Marketing',
    'Market Segment Penetration',
    'Product Development and Application Diversification',
    'Certification',
    'Geographical Expansion',
    'Strategic Alliance',
  ]

  Gap = [
    'Scalability',
    'Facilities/ machinery',
    'Certification',
    'Funding',
    'No industrial partner',
  ]

  Business = [
    'B2B',
    'B2C',

  ]

  TRLA = [
    'USM CIC Endorsement',
    'Industry Endorsement',
    'Self Assessment',
  ]
  yearDisplay = new Date();
  constructor(
    private _formBuilder: FormBuilder,
    private toast: ToastrService,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private router: Router,
    public pidGrantService: PidGrantService
  ) {



    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
      this.uuid = auth.uid;

      if (pidGrantService.pidGrant) {
        this.edit = true;
        let f = pidGrantService.pidGrant;
        this.editID = f.id;

        if (this.pidGrantService.pidGrant.SubmittedDate)
          this.yearDisplay = new Date(this.pidGrantService.pidGrant.SubmittedDate)
        else
          this.yearDisplay = new Date(this.pidGrantService.pidGrant.CreatedDate)

        this.firstFormGroup = this._formBuilder.group({
          Applicant: [f.firstFormGroup.Applicant, Validators.required],
          School: [f.firstFormGroup.School, Validators.required],
          Email: [f.firstFormGroup.Email, Validators.required],
          Phone: [f.firstFormGroup.Phone],
          Author: [f.firstFormGroup.Author, Validators.required],
          ORCiD: [f.firstFormGroup.ORCiD, Validators.required],
          Designation: [f.firstFormGroup.Designation, Validators.required],
          Service: [f.firstFormGroup.Service, Validators.required],
          ExpiredDate: [new Date(f.firstFormGroup.ExpiredDate.toDate())],
          ProjectMembers: this._formBuilder.array([]),
        });

        for (const dir of f.firstFormGroup.ProjectMembers) {
          this.projectMembers().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
              Email: [dir.Email, Validators.required],
              School: [dir.School, Validators.required],
              Contribution: [dir.Contribution, Validators.required],
              isEdit: false
            }));
        }
        this.secondFormGroup = this._formBuilder.group({
          ProjectTitle: [f.secondFormGroup.ProjectTitle, Validators.required],
          Keywords: [f.secondFormGroup.Keywords, Validators.required],
          StartDate: [f.secondFormGroup.StartDate.toDate(), Validators.required],
          EndDate: [f.secondFormGroup.EndDate.toDate(), Validators.required],
          ProjectDuration: [f.secondFormGroup.ProjectDuration, Validators.required],
        });

        this.thirdFormGroup = this._formBuilder.group({
          CompanyName: [f.thirdFormGroup.CompanyName, Validators.required],
          CompanyAddress: [f.thirdFormGroup.CompanyAddress, Validators.required],
          CompanySSMNumber: [f.thirdFormGroup.CompanySSMNumber, Validators.required],
          CompanyContactPersonName: [f.thirdFormGroup.CompanyContactPersonName, Validators.required],
          CompanyContactPersonICNumber: [f.thirdFormGroup.CompanyContactPersonICNumber, Validators.required],
          CompanyContactPersonPosition: [f.thirdFormGroup.CompanyContactPersonPosition, Validators.required],
          CompanyContactPersonEmail: [f.thirdFormGroup.CompanyContactPersonEmail, Validators.required],
          CompanyContactPersonPhone: [f.thirdFormGroup.CompanyContactPersonPhone, Validators.required],
          CompanyDescription: [f.thirdFormGroup.CompanyDescription, Validators.required],
          totalEmployee: [f.thirdFormGroup.totalEmployee, Validators.required],
          mEmployee: [f.thirdFormGroup.mEmployee, Validators.required],
          nmEmployee: [f.thirdFormGroup.nmEmployee, Validators.required],
          year1: [f.thirdFormGroup.year1, Validators.required],
          year2: [f.thirdFormGroup.year2, Validators.required],
          year3: [f.thirdFormGroup.year3, Validators.required],
          CompanyNeeds: [f.thirdFormGroup.CompanyNeeds, Validators.required],
          CompanyGrant: [f.thirdFormGroup.CompanyGrant, Validators.required],
          CompanyGrantDetail: [f.thirdFormGroup.CompanyGrantDetail, Validators.required],
          SectorIndustry: [f.thirdFormGroup.SectorIndustry, Validators.required],
        });

        this.fourthFormGroup = this._formBuilder.group({
          ExecutiveSummary: [f.fourthFormGroup.ExecutiveSummary, Validators.required],
          ProblemStatement: [f.fourthFormGroup.ProblemStatement, Validators.required],
          Objectives: [f.fourthFormGroup.Objectives, Validators.required],
          ResearchBackground: [f.fourthFormGroup.ResearchBackground, Validators.required],
          Methodology: [f.fourthFormGroup.Methodology, Validators.required],
          ExpectedResearch: [f.fourthFormGroup.ExpectedResearch, Validators.required],
          ResearchDomain: [f.fourthFormGroup.ResearchDomain, Validators.required],
          ResearchCluster: [f.fourthFormGroup.ResearchCluster, Validators.required],
          Goal: [f.fourthFormGroup.Goal, Validators.required],
          Driver: [f.fourthFormGroup.Driver, Validators.required],
          CurrentTrl: [f.fourthFormGroup.CurrentTrl, Validators.required],
          TargetTrl: [f.fourthFormGroup.TargetTrl, Validators.required],
          AccessToEquipment: this._formBuilder.array([]),
        });

        for (const dir of f.fourthFormGroup.AccessToEquipment) {
          this.accessToEquipments().push(
            this._formBuilder.group({
              Type: [dir.Type, Validators.required],
              Description: [dir.Description, Validators.required],
              Owner: [dir.Owner, Validators.required],
              Location: [dir.Location, Validators.required],
              isEdit: false
            }));
        }

       

        this.fifthFormGroup = this._formBuilder.group({
          Publication: this._formBuilder.array([]),
          IP: this._formBuilder.array([]),
          Grant: this._formBuilder.array([]),
          Consultation: this._formBuilder.array([]),
        });

        for (const dir of f.fifthFormGroup.Publication) {
          this.publications().push(
            this._formBuilder.group({
              Title: [dir.Title, Validators.required],
              Name: [dir.Name, Validators.required],
              Year: [dir.Year, Validators.required],
              Role: [dir.Role, Validators.required],
              isEdit: false
            }));
        }

        for (const dir of f.fifthFormGroup.IP) {
          this.intelliectuals().push(
            this._formBuilder.group({
              Title: [dir.Title, Validators.required],
              Type: [dir.Type, Validators.required],
              Number: [dir.Number, Validators.required],
              Date: [dir.Date, Validators.required],
              Status: [dir.Status, Validators.required],
              isEdit: false
            }));
        }

        for (const dir of f.fifthFormGroup.Grant) {
          this.grants().push(
            this._formBuilder.group({
              Title: [dir.Title, Validators.required],
              Funder: [dir.Funder, Validators.required],
              Role: [dir.Role, Validators.required],
              Status: [dir.Status, Validators.required],
              isEdit: false
            }));
        }

        for (const dir of f.fifthFormGroup.Consultation) {
          this.consultations().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
              Value: [dir.Value, Validators.required],
              isEdit: false
            }));
        }

        this.sixthFormGroup = this._formBuilder.group({
          Risks: this._formBuilder.array([]),
        });

        for (const dir of f.sixthFormGroup.Risks) {
          this.risks().push(
            this._formBuilder.group({
              Item: [dir.Item, Validators.required],
              Statement: [dir.Statement, Validators.required],
              Level: [dir.Level, Validators.required],
              Response: [dir.Response, Validators.required],
              isEdit: false
            }));
        }


        this.seventhFormGroup = this._formBuilder.group({
          IPF: [f.seventhFormGroup.IPF, Validators.required],
          Commercialisation: [f.seventhFormGroup.Commercialisation, Validators.required],
          Publication: [f.seventhFormGroup.Publication, Validators.required],
          PreCommer: [f.seventhFormGroup.PreCommer, Validators.required],
          PreCommerOther: [f.seventhFormGroup.PreCommerOther, Validators.required],
        });

        this.eightFormGroup = this._formBuilder.group({
          Additional: [f.eightFormGroup.Additional],
          Flow: [f.eightFormGroup.Flow, Validators.required],
          Compilation: [f.eightFormGroup.Compilation, Validators.required],
          Recommendation: [f.eightFormGroup.Recommendation, Validators.required],
          ProjectGantt: [f.eightFormGroup.ProjectGantt, Validators.required],
          OfferLetter: [f.eightFormGroup.OfferLetter, Validators.required],
          Commitment: [f.eightFormGroup.Commitment, Validators.required],
        });

        this.ninthFormGroup = this._formBuilder.group({
          Agree1: [f.ninthFormGroup.Agree1, Validators.required],
          Agree2: [f.ninthFormGroup.Agree2, Validators.required],
          Agree3: [f.ninthFormGroup.Agree3, Validators.required],
          Agree4: [f.ninthFormGroup.Agree4, Validators.required],
        });

        this.budgetFormGroup = this._formBuilder.group({
          AllowanceDesc: [f.budgetFormGroup.AllowanceDesc, Validators.required],
          AllowanceCompany: [f.budgetFormGroup.AllowanceCompany, Validators.required],
          AllowanceUSM: [f.budgetFormGroup.AllowanceUSM, Validators.required],
          TravelDesc: [f.budgetFormGroup.TravelDesc, Validators.required],
          TravelCompany: [f.budgetFormGroup.TravelCompany, Validators.required],
          TravelUSM: [f.budgetFormGroup.TravelUSM, Validators.required],
          RentalDesc: [f.budgetFormGroup.RentalDesc, Validators.required],
          RentalCompany: [f.budgetFormGroup.RentalCompany, Validators.required],
          RentalUSM: [f.budgetFormGroup.RentalUSM, Validators.required],
          ResearchDesc: [f.budgetFormGroup.ResearchDesc, Validators.required],
          ResearchCompany: [f.budgetFormGroup.ResearchCompany, Validators.required],
          ResearchUSM: [f.budgetFormGroup.ResearchUSM, Validators.required],
          MaintenanceDesc: [f.budgetFormGroup.MaintenanceDesc, Validators.required],
          MaintenanceTotal: [f.budgetFormGroup.MaintenanceTotal, Validators.required],
          MaintenanceCompany: [f.budgetFormGroup.MaintenanceCompany, Validators.required],
          MaintenanceUSM: [f.budgetFormGroup.MaintenanceUSM, Validators.required],
          ProfessionalDesc: [f.budgetFormGroup.ProfessionalDesc, Validators.required],
          ProfessionalCompany: [f.budgetFormGroup.ProfessionalCompany, Validators.required],
          ProfessionalUSM: [f.budgetFormGroup.ProfessionalUSM, Validators.required],
          AccessoriesDesc: [f.budgetFormGroup.AccessoriesDesc, Validators.required],
          AccessoriesCompany: [f.budgetFormGroup.AccessoriesCompany, Validators.required],
          AccessoriesUSM: [f.budgetFormGroup.AccessoriesUSM, Validators.required],
          ServiceCharge: [f.budgetFormGroup.ServiceCharge || 0, Validators.required]
        });

        this.ipForm = this._formBuilder.group({
          Name: [f.ipForm.Name, Validators.required],
          TypeOfIp: [f.ipForm.TypeOfIp, Validators.required],
          Date: [f.ipForm.Date, Validators.required],
          Main: [f.ipForm.Main, Validators.required],
          Joint: this._formBuilder.array([]),
          Description: [f.ipForm.Description, Validators.required],
          Relevancy: [f.ipForm.Relevancy, Validators.required],
          FName: [f.ipForm.FName, Validators.required],
          FTypeOfIp: [f.ipForm.FTypeOfIp, Validators.required],
          FMain: [f.ipForm.FMain, Validators.required],
          FJoint: this._formBuilder.array([]),
          FDescription: [f.ipForm.FDescription, Validators.required],
        });

        for (const dir of f.ipForm.Joint) {
          this.joint().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
            }));
        }

        for (const dir of f.ipForm.FJoint) {
          this.fjoint().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
            }));
        }


      } else {
        if (this.router.url.includes('View')) {
          this.router.navigate(['Home/pid-grant'])
      }


        this.firestore.collection('Users').doc(this.uuid).get().forEach(file => {
          this.firstFormGroup.get('Applicant').setValue(file.data().Name);
          this.firstFormGroup.get('Email').setValue(file.data().Email);
          this.firstFormGroup.get('Phone').setValue(file.data().TelephoneNo);
          this.firstFormGroup.get('School').setValue(file.data().SchoolName);
        });

      }
      if (this.router.url.includes('Edit')) {
        if (!this.edit)
          this.router.navigate(['Home/pid-grant'])
      }

    });

  }

  ngOnInit() {

  }


  validateError(formControl: FormControl) {

    if (formControl.value.split(' ').length > 150)
      formControl.setErrors({ 'invalid': true })
    else
      formControl.setErrors({ 'invalid': null })
  }


  back() {
    this.router.navigate(['/Home/pid-grant']);

  }
  getWordLeft(word) {
    return word.split(' ');
  }

  detectChanges1(event) {
    this.firstFormGroup.get('AuditedYearEnd').setValue(event);
  }

  selectedIndex = 0;
  async saveAndNext(ddd = false) {
    if (!ddd)
      this.selectedIndex++;

    if (!ddd || (this.firstFormGroup.get('ProjectMembers').value.length > 0)) {
      this.spinner.show();
      this.temporarySave();
    }

  }

  async temporarySave() {
    try {
      this.spinner.show();
      if (this.edit) {
        let eight = this.eightFormGroup.getRawValue();
        await this.uploadImages(eight);
        let info = {
          UpdatedDate: new Date(),
          UpdatedBy: this.email,
          CreatedBy: this.email,
          Status: this.pidGrantService.pidGrant ? this.pidGrantService.pidGrant.Status : "Draft",
          firstFormGroup: this.firstFormGroup.value,
          secondFormGroup: this.secondFormGroup.value,
          thirdFormGroup: this.thirdFormGroup.value,
          fourthFormGroup: this.fourthFormGroup.value,
          seventhFormGroup: this.seventhFormGroup.value,
          fifthFormGroup: this.fifthFormGroup.value,
          eightFormGroup: eight,
          sixthFormGroup: this.sixthFormGroup.value,
          ninthFormGroup: this.ninthFormGroup.value,
          budgetFormGroup: this.budgetFormGroup.value,
              ipForm: this.ipForm.value,
              UserID: this.uuid,
        }
        await this.firestore.collection("PidGrant").doc(this.editID).update(info);
        this.spinner.hide();

      } else {
        await this.firestore.collection("PidGrantID").doc("ID").get().forEach(async item => {
          let ID = ('000' + item.data().ID).slice(-3);
          ID = `PD${new Date().getFullYear()}-${ID}`;

          let eight = this.eightFormGroup.getRawValue();
          await this.uploadImages(eight);

          let info = {
            UpdatedDate: new Date(),
            CreatedDate: new Date(),
            UpdatedBy: this.email,
            CreatedBy: this.email,
            Status: this.pidGrantService.pidGrant ? this.pidGrantService.pidGrant.Status : "Draft",
            firstFormGroup: this.firstFormGroup.value,
            secondFormGroup: this.secondFormGroup.value,
            thirdFormGroup: this.thirdFormGroup.value,
            fourthFormGroup: this.fourthFormGroup.value,
            seventhFormGroup: this.seventhFormGroup.value,
            fifthFormGroup: this.fifthFormGroup.value,
            eightFormGroup: eight,
            sixthFormGroup: this.sixthFormGroup.value,
            ninthFormGroup: this.ninthFormGroup.value,
            budgetFormGroup: this.budgetFormGroup.value,
            ipForm: this.ipForm.value,

            UserID: this.uuid,
          }

          await this.firestore.collection("PidGrant").doc(ID).set(info);
          await this.firestore.collection('PidGrantDrafts').doc(this.uuid).delete();
          await this.firestore.collection('PidGrantID').doc("ID").set({ ID: item.data().ID + 1 })
          this.spinner.hide();
          this.edit = true;
          this.editID = ID;

        })
      }


    } catch (error) {
      this.toast.error(error, "Error happen");
      this.spinner.hide();

    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedIndex = tabChangeEvent.index;
    this.saveAndNext(true);
  }

  projectMembers(): FormArray {
    return this.firstFormGroup.get('ProjectMembers') as FormArray;
  }
  addProjectMembers() {
    this.projectMembers().push(this.newProjectMembers());
  }

  newProjectMembers() {
    let info = { ...this.projectMember }
    this.projectMember = {
      Name: '',
      School: '',
      Email: '',
      Contribution: '',
    }
    return this._formBuilder.group({
      ...info,
      isEdit: false,
    });
  }


  commitments(): FormArray {
    return this.eightFormGroup.get('Commitment') as FormArray;
  }
  addcommitments() {
    this.commitments().push(this.newcommitments());
  }

  newcommitments() {
    let info = { ...this.commitment }
    this.commitment = {
      Month: '',
      Year: '',
      Amount: 0,
    }
    return this._formBuilder.group({
      ...info,
      isEdit: false,
    });
  }

  accessToEquipments(): FormArray {
    return this.fourthFormGroup.get('AccessToEquipment') as FormArray;
  }
  addAccessToEquipments() {
    this.accessToEquipments().push(this.newAccessToEquipments());
  }

  newAccessToEquipments() {
    let info = { ...this.accessToEquipment }
    this.accessToEquipment = {
      Type: '',
      Description: '',
      Owner: '',
      Location: '',
    }
    return this._formBuilder.group({
      ...info,
      isEdit: false,
    });
  }

  publications(): FormArray {
    return this.fifthFormGroup.get('Publication') as FormArray;
  }
  addPublications() {
    this.publications().push(this.newPublications());
  }

  newPublications() {
    let info = { ...this.publication }
    this.publication = {
      Title: '',
      Name: '',
      Year: '',
      Role: '',
    }

    return this._formBuilder.group({
      ...info,
      isEdit: false,
    });
  }

  intelliectuals(): FormArray {
    return this.fifthFormGroup.get('IP') as FormArray;
  }
  addIntelliectuals() {
    this.intelliectuals().push(this.newIntelliectuals());
  }

  newIntelliectuals() {
    let info = { ...this.intelliectual }
    this.intelliectual = {
      Title: '',
      Type: '',
      Number: '',
      Date: '',
      Status: ''
    }
    return this._formBuilder.group({
      ...info,
      isEdit: false,
    });
  }

  grants(): FormArray {
    return this.fifthFormGroup.get('Grant') as FormArray;
  }
  addGrants() {
    this.grants().push(this.newGrants());
  }

  newGrants() {
    let info = { ...this.grant }
    this.grant = {
      Title: '',
      Role: '',
      Funder: '',
      Status: ''
    }
    return this._formBuilder.group({
      ...info,
      isEdit: false,
    });
  }

  consultations(): FormArray {
    return this.fifthFormGroup.get('Consultation') as FormArray;
  }
  addConsultations() {
    this.consultations().push(this.newConsultations());
  }

  newConsultations() {
    let info = { ...this.consultation }
    this.consultation = {
      Name: '',
      Value: 0
    }
    return this._formBuilder.group({
      ...info,
      isEdit: false,
    });
  }

  risks(): FormArray {
    return this.sixthFormGroup.get('Risks') as FormArray;
  }
  addRisks() {
    this.risks().push(this.newRisks());
  }

  newRisks() {
    let info = { ...this.risk }
    this.risk = {
      Item: '',
      Statement: '',
      Level: '',
      Response: '',
    }
    return this._formBuilder.group({
      ...info,
      isEdit: false,
    });
  }

  joint(): FormArray {
    return this.ipForm.get('Joint') as FormArray;
  }
  addJoint() {
    this.joint().push(this.newJoint());
  }

  j = {
    Name: ''
  }
  newJoint() {
    let info = { ...this.j }
    this.j = {
      Name: '',
    }
    return this._formBuilder.group({
      ...info,
      isEdit: false,
    });
  }

  fjoint(): FormArray {
    return this.ipForm.get('FJoint') as FormArray;
  }
  addfJoint() {
    this.fjoint().push(this.newfJoint());
  }

  fj = {
    Name: ''
  }
  newfJoint() {
    let info = { ...this.fj }
    this.fj = {
      Name: '',
    }
    return this._formBuilder.group({
      ...info,
      isEdit: false,
    });
  }




  save(event: any): void {
    let val = this.eightFormGroup.get('Additional').value;
    this.eightFormGroup.get('Additional').setValue([...val, ...event.target.files])
  }

  removeFile(index) {
    let val = this.eightFormGroup.get('Additional').value;
    val.splice(index, 1);
    this.eightFormGroup.get('Additional').setValue(val)
  }

  save4(event: any, value): void {
    this.eightFormGroup.get(value).setValue(event.target.files[0])
  }

  editForm(i, type) {
    switch (type) {
      case 'projectMembers':
        this.projectMembers().at(i).get('isEdit').setValue(!this.projectMembers().at(i).get('isEdit').value);
        break;
      case 'commitment':
        this.commitments().at(i).get('isEdit').setValue(!this.commitments().at(i).get('isEdit').value);
        break;
      case 'accessToEquipment':
        this.accessToEquipments().at(i).get('isEdit').setValue(!this.accessToEquipments().at(i).get('isEdit').value);
        break;
      case 'publication':
        this.publications().at(i).get('isEdit').setValue(!this.publications().at(i).get('isEdit').value);
        break;
      case 'intelliectual':
        this.intelliectuals().at(i).get('isEdit').setValue(!this.intelliectuals().at(i).get('isEdit').value);
        break;
      case 'grant':
        this.grants().at(i).get('isEdit').setValue(!this.grants().at(i).get('isEdit').value);
        break;
      case 'consultation':
        this.consultations().at(i).get('isEdit').setValue(!this.consultations().at(i).get('isEdit').value);
        break;
      case 'risk':
        this.risks().at(i).get('isEdit').setValue(!this.risks().at(i).get('isEdit').value);
        break;
      case 'joint':
        this.joint().at(i).get('isEdit').setValue(!this.joint().at(i).get('isEdit').value);
        break;
      case 'fjoint':
        this.fjoint().at(i).get('isEdit').setValue(!this.fjoint().at(i).get('isEdit').value);
        break;
    }

  }

  remove(i, type) {
    switch (type) {
      case 'projectMembers':
        this.projectMembers().removeAt(i);
        break;
      case 'commitment':
        this.commitments().removeAt(i);
        break;
      case 'accessToEquipment':
        this.accessToEquipments().removeAt(i);
        break;

      case 'publication':
        this.publications().removeAt(i);
        break;
      case 'intelliectual':
        this.intelliectuals().removeAt(i);
        break;
      case 'grant':
        this.grants().removeAt(i);
        break;

      case 'consultation':
        this.consultations().removeAt(i);
        break;
      case 'risk':
        this.risks().removeAt(i);
        break;
      case 'joint':
        this.joint().removeAt(i);
        break;
      case 'fjoint':
        this.fjoint().removeAt(i);
        break;
    }
  }


  dateFormat = new DateFormatService();


  saveAsSubmit() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm submit the new application?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          if (this.edit) {

            let eight = this.eightFormGroup.getRawValue();
            await this.uploadImages(eight);
            let info = {
              UpdatedDate: new Date(),
              SubmittedDate: new Date(),
              CreatedBy: this.email,
              UpdatedBy: this.email,
              UserID: this.uuid,
              Status: "Submitted",
              firstFormGroup: this.firstFormGroup.value,
              secondFormGroup: this.secondFormGroup.value,
              thirdFormGroup: this.thirdFormGroup.value,
              fourthFormGroup: this.fourthFormGroup.value,
              seventhFormGroup: this.seventhFormGroup.value,
              fifthFormGroup: this.fifthFormGroup.value,
              eightFormGroup: eight,
              sixthFormGroup: this.sixthFormGroup.value,
              ninthFormGroup: this.ninthFormGroup.value,
              budgetFormGroup: this.budgetFormGroup.value,
              ipForm: this.ipForm.value,

            }
            await this.firestore.collection("PidGrant").doc(this.editID).update(info);
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
            const infos = {
              message: "New application has been submitted by: " + this.email,
              date: new Date(),
              data: JSON.stringify(info),
            };
            await this.firestore.collection('PidGrantDrafts').doc(this.uuid).delete();
            await this.firestore.collection('PidGrantLog').doc(dateFormat).set({ Date: new Date() });
            await this.firestore.collection('PidGrantLog').doc(dateFormat).collection('User').add(infos);
            this.spinner.hide();
            this.toast.success("Saved Successfully");
            this.router.navigate(['/Home/pid-grant']);
          } else {
            await this.firestore.collection("PidGrantID").doc("ID").get().forEach(async item => {
              let ID = ('000' + item.data().ID).slice(-3);
              ID = `PD${new Date().getFullYear()}-${ID}`;

              let eight = this.eightFormGroup.getRawValue();
              await this.uploadImages(eight);
              let info = {
                UpdatedDate: new Date(),
                SubmittedDate: new Date(),
                CreatedDate: new Date(),
                UpdatedBy: this.email,
                Status: "Submitted",
                firstFormGroup: this.firstFormGroup.value,
                secondFormGroup: this.secondFormGroup.value,
                thirdFormGroup: this.thirdFormGroup.value,
                fourthFormGroup: this.fourthFormGroup.value,
                seventhFormGroup: this.seventhFormGroup.value,
                fifthFormGroup: this.fifthFormGroup.value,
                eightFormGroup: eight,
                sixthFormGroup: this.sixthFormGroup.value,
                ninthFormGroup: this.ninthFormGroup.value,
                budgetFormGroup: this.budgetFormGroup.value,
              ipForm: this.ipForm.value,
              UserID: this.uuid,
                CreatedBy: this.email,
              }
              await this.firestore.collection("PidGrant").doc(ID).set(info);
              await this.firestore.collection('PidGrantDrafts').doc(this.uuid).delete();

              const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
              const infos = {
                message: "New application has been submitted by: " + this.email,
                date: new Date(),
                data: JSON.stringify(info),
              };
              await this.firestore.collection('PidGrantLog').doc(dateFormat).set({ Date: new Date() });
              await this.firestore.collection('PidGrantLog').doc(dateFormat).collection('User').add(infos);
              await this.firestore.collection('PidGrantID').doc("ID").set({ ID: item.data().ID + 1 })

              this.spinner.hide();
              this.toast.success("Update Successfully");
              this.router.navigate(['/Home/pid-grant']);
            })

          }


        } catch (error) {
          this.toast.error(error, "Error happen");
          this.spinner.hide();

        }
      }
    })
  }

  async uploadImages(six) {
    let realAttachments = [];
    for (const p of six.Additional) {
      if (p && p.name && !p.url) {
        realAttachments.push(await this.uploadImageAsPromise(p));
      } else if (p.url) {
        realAttachments.push(p);
      }
    }
    six.Additional = realAttachments;

    if (six.ProjectGantt && six.ProjectGantt.name && !six.ProjectGantt.url) {
      let file = await this.uploadImageAsPromise(six.ProjectGantt);
      six.ProjectGantt = null;
      six.ProjectGantt = file;
    }
    if (six.OfferLetter && six.OfferLetter.name && !six.OfferLetter.url) {
      let file = await this.uploadImageAsPromise(six.OfferLetter);
      six.OfferLetter = null;
      six.OfferLetter = file;
    }

    if (six.Recommendation && six.Recommendation.name && !six.Recommendation.url) {
      let file = await this.uploadImageAsPromise(six.Recommendation);
      six.Recommendation = null;
      six.Recommendation = file;
    }

    if (six.Commitment && six.Commitment.name && !six.Commitment.url) {
      let file = await this.uploadImageAsPromise(six.Commitment);
      six.Commitment = null;
      six.Commitment = file;
    }

    if (six.Compilation && six.Compilation.name && !six.Compilation.url) {
      let file = await this.uploadImageAsPromise(six.Compilation);
      six.Compilation = null;
      six.Compilation = file;
    }
    if (six.Flow && six.Flow.name && !six.Flow.url) {
      let file = await this.uploadImageAsPromise(six.Flow);
      six.Flow = null;
      six.Flow = file;
    }

  }


  uploadImageAsPromise(imageFile) {
    return new Promise((resolve, reject) => {
      const filePath = `PidGrant/${imageFile.name}_${new Date().getTime()}`;
      var storageRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, imageFile);
      let progress;
      task.snapshotChanges().pipe(
        finalize(() => {
          var downloadURL = storageRef.getDownloadURL();
          downloadURL.subscribe(async url => {
            let file = {
              name: imageFile.name,
              url: url,
            }
            resolve(file);
          });
        })
      ).subscribe(url => {
        if (url) {
          progress = url;
        }
      });
    });
  }

  async viewPPT(form) {
    if (form.url) {
      const existingPdfBytes = await fetch(form.url).then(res => res.arrayBuffer())
      let file = new Blob([existingPdfBytes]);
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = form.name;
      anchor.href = fileURL;
      anchor.click();
    } else {
      let file = new Blob([form]);
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = form.name;
      anchor.href = fileURL;
      anchor.click();
    }
  }

  computeTotal(type) {
    switch (type) {
      case 'Allowance':
        return !isNaN(
          parseFloat(this.budgetFormGroup.get('AllowanceCompany').value) +
          parseFloat(this.budgetFormGroup.get('AllowanceUSM').value)
        ) ? (parseFloat(this.budgetFormGroup.get('AllowanceCompany').value) +
          parseFloat(this.budgetFormGroup.get('AllowanceUSM').value)).toFixed(2) : '0.00'

      case 'Travel':
        return !isNaN(
          parseFloat(this.budgetFormGroup.get('TravelCompany').value) +
          parseFloat(this.budgetFormGroup.get('TravelUSM').value)
        ) ? (parseFloat(this.budgetFormGroup.get('TravelCompany').value) +
          parseFloat(this.budgetFormGroup.get('TravelUSM').value)).toFixed(2) : '0.00'

      case 'Rental':
        return !isNaN(
          parseFloat(this.budgetFormGroup.get('RentalCompany').value) +
          parseFloat(this.budgetFormGroup.get('RentalUSM').value)
        ) ? (parseFloat(this.budgetFormGroup.get('RentalCompany').value) +
          parseFloat(this.budgetFormGroup.get('RentalUSM').value)).toFixed(2) : '0.00'

      case 'Research':
        return !isNaN(
          parseFloat(this.budgetFormGroup.get('ResearchCompany').value) +
          parseFloat(this.budgetFormGroup.get('ResearchUSM').value)
        ) ? (parseFloat(this.budgetFormGroup.get('ResearchCompany').value) +
          parseFloat(this.budgetFormGroup.get('ResearchUSM').value)).toFixed(2) : '0.00'

      case 'Maintenance':
        return !isNaN(
          parseFloat(this.budgetFormGroup.get('MaintenanceCompany').value) +
          parseFloat(this.budgetFormGroup.get('MaintenanceUSM').value)
        ) ? (parseFloat(this.budgetFormGroup.get('MaintenanceCompany').value) +
          parseFloat(this.budgetFormGroup.get('MaintenanceUSM').value)).toFixed(2) : '0.00'

      case 'Professional':
        return !isNaN(
          parseFloat(this.budgetFormGroup.get('ProfessionalCompany').value) +
          parseFloat(this.budgetFormGroup.get('ProfessionalUSM').value)
        ) ? (parseFloat(this.budgetFormGroup.get('ProfessionalCompany').value) +
          parseFloat(this.budgetFormGroup.get('ProfessionalUSM').value)).toFixed(2) : '0.00'

      case 'Accessories':
        return !isNaN(
          parseFloat(this.budgetFormGroup.get('AccessoriesCompany').value) +
          parseFloat(this.budgetFormGroup.get('AccessoriesUSM').value)
        ) ? (parseFloat(this.budgetFormGroup.get('AccessoriesCompany').value) +
          parseFloat(this.budgetFormGroup.get('AccessoriesUSM').value)).toFixed(2) : '0.00'
      case 'Total':
      case 'GrandTotal':
        let total = !isNaN(
          parseFloat(this.budgetFormGroup.get('AllowanceCompany').value) +
          parseFloat(this.budgetFormGroup.get('AllowanceUSM').value) +
          parseFloat(this.budgetFormGroup.get('TravelCompany').value) +
          parseFloat(this.budgetFormGroup.get('TravelUSM').value) +
          parseFloat(this.budgetFormGroup.get('RentalCompany').value) +
          parseFloat(this.budgetFormGroup.get('RentalUSM').value) +
          parseFloat(this.budgetFormGroup.get('ResearchCompany').value) +
          parseFloat(this.budgetFormGroup.get('ResearchUSM').value) +
          parseFloat(this.budgetFormGroup.get('MaintenanceCompany').value) +
          parseFloat(this.budgetFormGroup.get('MaintenanceUSM').value) +
          parseFloat(this.budgetFormGroup.get('ProfessionalCompany').value) +
          parseFloat(this.budgetFormGroup.get('ProfessionalUSM').value) +
          parseFloat(this.budgetFormGroup.get('AccessoriesCompany').value) +
          parseFloat(this.budgetFormGroup.get('AccessoriesUSM').value) 

        ) ?
          (
            parseFloat(this.budgetFormGroup.get('AllowanceCompany').value) +
            parseFloat(this.budgetFormGroup.get('AllowanceUSM').value) +
            parseFloat(this.budgetFormGroup.get('TravelCompany').value) +
            parseFloat(this.budgetFormGroup.get('TravelUSM').value) +
            parseFloat(this.budgetFormGroup.get('RentalCompany').value) +
            parseFloat(this.budgetFormGroup.get('RentalUSM').value) +
            parseFloat(this.budgetFormGroup.get('ResearchCompany').value) +
            parseFloat(this.budgetFormGroup.get('ResearchUSM').value) +
            parseFloat(this.budgetFormGroup.get('MaintenanceCompany').value) +
            parseFloat(this.budgetFormGroup.get('MaintenanceUSM').value) +
            parseFloat(this.budgetFormGroup.get('ProfessionalCompany').value) +
            parseFloat(this.budgetFormGroup.get('ProfessionalUSM').value) +
            parseFloat(this.budgetFormGroup.get('AccessoriesCompany').value) +
            parseFloat(this.budgetFormGroup.get('AccessoriesUSM').value) 
          ) : 0;

        if (type === 'Total')
          return total.toFixed(2);

        else if (type === 'GrandTotal')
          return (total + parseFloat(this.budgetFormGroup.get('ServiceCharge').value) ).toFixed(2);


    }
  }

  async downloadTemplate() {
    const url = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/PTJ_Form.docx?alt=media&token=bc54f5ec-d6b0-456c-bfaf-71b5c837099e'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    let file = new Blob([existingPdfBytes], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = "PTJ_Form.docx";
    anchor.href = fileURL;
    anchor.click();
  }

  async downloadTemplate2() {
    const url = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/Nota%20Komitmen%20Syarikat.docx?alt=media&token=de49ffc9-7126-4660-ba9e-21c7e56e0389'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    let file = new Blob([existingPdfBytes], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = "CommitmentTemplate.docx";
    anchor.href = fileURL;
    anchor.click();
  }

}
