import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder }  from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.css']
})
export class FinishDialogComponent implements OnInit {

  form: FormGroup;
  message: any;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<FinishDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.message = data;
  }

  ngOnInit(){
    this.form = this.fb.group({
      quantity: ['']
    });
  }
  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }

}
