import { HttpClient } from '@angular/common/http';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatPaginator, MatDialog, PageEvent, Sort, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import fontkit from '@pdf-lib/fontkit';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { layoutMultilineText, TextAlignment, rgb, PDFDocument, StandardFonts } from 'pdf-lib';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { FillFormComponent } from '../cic/fill-form/fill-form.component';
import { SubmitFormComponent } from '../cic/submit-form/submit-form.component';

@Component({
  selector: 'app-admin-cic',
  templateUrl: './admin-cic.component.html',
  styleUrls: ['./admin-cic.component.css']
})
export class AdminCICComponent implements OnInit {
  filelist = [];
  sortedfilelist: any;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  searchbox = '';
  searchValue = '';
  sortedu = {
    active: '',
    direction: ''
  };
  dateRange: any;
  email: any;
  uuid: any;
  fileID = ''
  appliedBy = ''
  icNo = ''
  address = ''
  nationality = 'Malaysian'


  loading = true;
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  constructor(
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
  ) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
      this.uuid = auth.uid;
      this.firestore.collection('CIC', ref => ref.orderBy('CreatedDate', 'desc')).get().forEach(e => {
        e.forEach(file => {
          const data = {
            ...file.data(),
            id: file.id,
            fileName: file.data().FileNames?file.data().FileNames.join('\n'): "Not yet upload",
            ReceiptName: file.data().ReceiptNames ? file.data().ReceiptNames.join('\n') : "Not yet upload",
            CreatedDate: new Date(file.data().CreatedDate.toDate()),
          }

          this.firestore.collection('Users')
          this.filelist.push(data);
          this.sortedfilelist = this.filelist.slice(this.offset, (this.offset + this.pageSize));
          this.length = this.filelist.length;
          this.loading = false;

        })
      });
    });

  }

  ngOnInit() {

  }

  view(url) {
    window.open(url);
  }

  SetupFile() {
    this.loading = true;
    this.filelist = [];
    this.firestore.collection('CIC', ref => ref.orderBy('CreatedDate', 'desc')).get().forEach(e => {
      e.forEach(file => {
        const data = {
          ...file.data(),
          id: file.id,
          fileName: file.data().FileNames?file.data().FileNames.join('\n'): "Not yet upload",

          CreatedDate: new Date(file.data().CreatedDate.toDate())
        }
        this.filelist.push(data);
        this.sortedfilelist = this.filelist.slice(this.offset, (this.offset + this.pageSize));
        this.length = this.filelist.length;
        this.loading = false;

      })
    });
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    this.sortedfilelist = this.filelist.slice();
    this.sortFile();
    this.limitFile();
  }

  limitFile() {
    this.sortedfilelist = this.sortedfilelist.slice(this.offset, (this.offset + this.pageSize));
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.sortedfilelist = this.filelist.slice();
    if (this.searchValue) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.searchValue) {
      this.sortedfilelist = this.filelist.slice();
      this.limitFile();
      return;
    }

    this.sortedfilelist = this.sortedfilelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'file': return this.compare(a.fileName, b.fileName, isAsc);
        case 'title': return this.compare(a.formValue.title, b.formValue.title, isAsc);
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'date': return this.compareDate(a.CreatedDate, b.CreatedDate, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        default: return 0;
      }
    });
    this.limitFile();
  }

  sortFile() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.sortedfilelist = this.sortedfilelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'file': return this.compare(a.fileName, b.fileName, isAsc);
        case 'title': return this.compare(a.formValue.title, b.formValue.title, isAsc);
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'date': return this.compareDate(a.CreatedDate, b.CreatedDate, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  reset() {
    this.fileName = '';
    this.status = 'All';
    this.dateRange = '';
    this.fileID = '';
    this.appliedBy = '';
    this.nationality = 'Malaysian'
    this.address = ''
    this.icNo = ''
    this.SetupFile();
  }
  search() {
    let startDate;
    let endDate;

    this.sortedfilelist = this.filelist.filter(u =>
      String(u.formValue.title).toLowerCase().includes(this.fileName.toLowerCase()) &&
      String(u.formValue.mainAuthor.name).toLowerCase().includes(this.appliedBy.toLowerCase()) &&
      String(u.id).toLowerCase().includes(this.fileID.toLowerCase()) &&
      String(u.formValue.mainAuthor.ic).toLowerCase().includes(this.icNo.toLowerCase()) &&
      String(u.formValue.mainAuthor.add1 +' ' + u.formValue.mainAuthor.add2 + '' + u.formValue.mainAuthor.add3).toLowerCase().includes(this.address.toLowerCase()) &&
      String(u.formValue.mainAuthor.nationality).toLowerCase().includes(this.nationality.toLowerCase())
      );
    if (this.status !== 'All')
      this.sortedfilelist = this.sortedfilelist.filter(u => u.Status === this.status);
    if (typeof (this.dateRange) === 'object') {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        startDate = this.dateRange.startDate.toDate();
        endDate = this.dateRange.endDate.toDate();
        this.sortedfilelist = this.sortedfilelist.filter(u =>
          u.CreatedDate.getTime() >= startDate.getTime() && u.CreatedDate <= endDate.getTime());

      }
    }
    this.length = this.sortedfilelist.length;
    this.sortFile();
    this.limitFile();
  }

  SelectNat(val){
    this.nationality = val;
  }

  DS() {
    let startDate;
    let endDate;

    this.sortedfilelist = this.filelist.filter(u =>
      String(u.formValue.title).toLowerCase().includes(this.fileName.toLowerCase()) &&
      String(u.formValue.mainAuthor.name).toLowerCase().includes(this.appliedBy.toLowerCase()) &&
      String(u.id).toLowerCase().includes(this.fileID.toLowerCase()) &&
      String(u.formValue.ic).toLowerCase().includes(this.icNo.toLowerCase()) &&
      String(u.formValue.add1+' ' + u.formValue.add2 + '' + u.formValue.add3).toLowerCase().includes(this.address.toLowerCase()) &&
      String(u.formValue.nationality).toLowerCase().includes(this.nationality.toLowerCase())
     );
    if (this.status !== 'All')
      this.sortedfilelist = this.sortedfilelist.filter(u => u.Status === this.status);
    if (typeof (this.dateRange) === 'object') {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        startDate = this.dateRange.startDate.toDate();
        endDate = this.dateRange.endDate.toDate();
        this.sortedfilelist = this.sortedfilelist.filter(u =>
          u.CreatedDate.getTime() >= startDate.getTime() && u.CreatedDate <= endDate.getTime());

      }
    }

    this.length = this.sortedfilelist.length;
    this.sortFile();
  }


  status = "All"
  fileName = '';
  applicationList = [];
  SelectStatus(status) {
    this.status = status;
  }

  dateRangeChange(e) {


  }

  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  FillForm() {
    this.dialog.open(FillFormComponent, {
      width: '90%',
      height: '90%',
      disableClose: true,
    }).afterClosed().subscribe(r => {
      if (r) {

      }
    });
  }

  SubmitForm() {
    this.dialog.open(SubmitFormComponent, {
      width: '70%',
      height: '80%',
      disableClose: true,
    }).afterClosed().subscribe(r => {
      if (r) {

      }
    });
  }
  private dateFormat = new DateFormatService();

  reject(file) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm want to reject this application';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          await this.firestore.collection("CIC").doc(file.id).update({ Status: "Rejected", UpdatedDate: new Date(), UpdatedBy: this.email });
          file.Status = "Rejected";

          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: "Application " + file.id + " has been rejected by Email: " + this.email,
            date: new Date()
          };
          await this.firestore.collection('CICLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('CICLog').doc(dateFormat).collection('User').add(info);


          this.spinner.hide();
          this.toaster.success("Rejected Successfully");
        } catch (error) {
          this.toaster.error(error, "Error happen");
        }
      }
    })
  }

  draft(file) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm want to return back to draft for this application?'

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          await this.firestore.collection("CIC").doc(file.id).update({ Status:'Draft', UpdatedDate: new Date(), UpdatedBy: this.email }).then(async e => {
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
            const info = {
              message: "Application " + file.id + " has been drafted back by Email: " + this.email,
              date: new Date()
            };
            await this.firestore.collection('CICLog').doc(dateFormat).set({ Date: new Date() });
            await this.firestore.collection('CICLog').doc(dateFormat).collection('User').add(info);

            this.spinner.hide();
            this.toaster.success("Return back to draft Successfully");
            this.SetupFile();
          });
        } catch (error) {
          this.toaster.error(error, "Error happen");
          this.spinner.hide();

        }
      }
    })
  }

  approve(file, cr) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm want to approve this application';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          file.Status = cr;
          await this.firestore.collection("CIC").doc(file.id).update({ Status: file.Status, UpdatedDate: new Date(), UpdatedBy: this.email });


          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: "Application " + file.id + " has been approved by Email: " + this.email,
            status: cr,
            date: new Date()
          };
          await this.firestore.collection('CICLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('CICLog').doc(dateFormat).collection('User').add(info);

          if(cr === 'Pending Payment'){
            this.http.get(`https://cic-usm.as.r.appspot.com/approved?form=${file.id}&user=${file.formValue.mainAuthor.name}&email=${file.formValue.mainAuthor.email}`).subscribe(result=>{
              console.log(result);
            })
          }else if(cr === 'Filed'){
            this.http.get(`https://cic-usm.as.r.appspot.com/filed?form=${file.id}&user=${file.formValue.mainAuthor.name}&email=${file.formValue.mainAuthor.email}`).subscribe(result=>{
              console.log(result);
            })
          }
          this.spinner.hide();
          this.toaster.success("Approved Successfully");
        } catch (error) {
          this.toaster.error(error, "Error happen");
        }
      }
    })
  }

  async addText(text, x, y, width, height, page, font, nogap = false, fontsize = 12) {
    let multiText = layoutMultilineText(text,
      {
        alignment: TextAlignment.Left,
        fontSize: fontsize,
        font: font,
        bounds: { x: x, y: y, width: width, height: height }
      })

    for (let i = 0; i < multiText.lines.length; i++) {
      if (nogap)
        page.drawText(`${multiText.lines[i].text}`, {
          x: multiText.lines[i].x,
          y: multiText.lines[i].y,
          size: fontsize,
          font: font,
          color: rgb(0, 0, 0)
        })
      else
        page.drawText(`${multiText.lines[i].text}`, {
          x: multiText.lines[i].x,
          y: i > 0 ? multiText.lines[i].y - (7 * i) : multiText.lines[i].y,
          size: fontsize,
          font: font,
          color: rgb(0, 0, 0)
        })
    }
  }

  async download(file){
    try {
      this.spinner.show();
      await this.generateCR1Form(file);
      if(file.formValue.numberOfCoAuthor > 0)
        await this.generateAuthorList(file);
      // await this.generateCR5Form(file);
      // await this.generateSD(file);

      // await this.generateCR1Form(file);
      // await this.generateCR5Form(file);
      // await this.generateSD(file);
      this.spinner.hide();
    } catch (error) {
      this.toaster.error(error, "Error happen");
      this.spinner.hide();
    }
  }

  async generateAuthorList(f) {
    const pdfDoc = await PDFDocument.create();
    const font = await pdfDoc.embedFont(StandardFonts.TimesRoman)
    const fontBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold)

    let page = pdfDoc.addPage();

    this.addText("ATTACHMENT FOR CR FORM (SECTION C)", 180, 750, 400, 20, page, fontBold);
    page.drawLine({
      start: { x: 180, y: 754 },
      end: { x: 430, y: 754 },
      color: rgb(0, 0, 0),
      thickness: 1
    })

    this.addText("LIST OF THE AUTHORS:", 50, 725, 400, 20, page, fontBold);
    page.drawLine({
      start: { x: 50, y: 729 },
      end: { x: 190, y: 729 },
      color: rgb(0, 0, 0),
      thickness: 1
    })

    let ic = f.formValue.mainAuthor.ic.substring(0, 6) + '-' + f.formValue.mainAuthor.ic.substring(6, 8) + '-' + f.formValue.mainAuthor.ic.substring(8, 12)


    this.addText("A. Main Author", 70, 700, 400, 20, page, fontBold);
    this.addText("(1)", 80, 675, 400, 20, page, font);
    this.addText("Name", 105, 675, 400, 20, page, font);
    this.addText(": "+ f.formValue.mainAuthor.name , 230, 675, 400, 20, page, font);
    this.addText("IC/ Passport No" , 105, 655, 400, 20, page, font);
    this.addText(": " + ic, 230, 655, 400, 20, page, font);
    this.addText("Nationality" , 105, 635, 400, 20, page, font);
    this.addText(": " + f.formValue.mainAuthor.nationality, 230, 635, 400, 20, page, font);
    this.addText("Position/Designation ", 105, 615, 400, 20, page, font);
    this.addText(": " + f.formValue.mainAuthor.position, 230, 615, 400, 20, page, font);
    this.addText("Address", 105, 595, 400, 20, page, font);
    this.addText("(School / Center/ PTJ)", 105, 580, 400, 20, page, font);
    this.addText(": ", 230, 595, 200, 20, page, font, true);
    this.addText((f.formValue.mainAuthor.add1 + ' ' + f.formValue.mainAuthor.add2 + " " + f.formValue.mainAuthor.add3).split(' ').slice(0, 30).join(' '), 235, 595, 300, 20, page, font);
    this.addText("Tel No. " , 105, 535, 400, 20, page, font);
    this.addText(": " + f.formValue.mainAuthor.telephoneNo, 230, 535, 400, 20, page, font);
    this.addText("Email" , 105, 515, 400, 20, page, font);
    this.addText(": " + f.formValue.mainAuthor.email, 230, 515, 400, 20, page, font);
    this.addText("Fax No" , 105, 495, 400, 20, page, font);
    this.addText(": " + f.formValue.mainAuthor.faxNo, 230, 495, 400, 20, page, font);

    let dateString = '';

    if (typeof (f.formValue.mainAuthor.dateDeath) === 'object') {
      let dateOfDeath = new Date(f.formValue.mainAuthor.dateDeath);
      dateString = ('0' + dateOfDeath.getDate()).slice(-2)
        + '-' + ('0' + (dateOfDeath.getMonth() + 1)).slice(-2)
        + '-' + dateOfDeath.getFullYear().toString();
    }
    this.addText("Date of Death " , 105, 475, 400, 20, page, font);
    this.addText("(if applicable only)" , 105, 460, 400, 20, page, font);
    this.addText(": " + dateString, 230, 475, 400, 20, page, font);


    if (f.formValue.coAuthor.length > 0) {
      this.addText("B. Co Author", 70, 430, 400, 20, page, fontBold);

      for (let index = 0; index < f.formValue.coAuthor.length; index++) {
        let coAuthor = f.formValue.coAuthor[index];
        let c = f.formValue.coAuthor[index].ic.substring(0, 6) + '-' + f.formValue.coAuthor[index].ic.substring(6, 8) + '-' + f.formValue.coAuthor[index].ic.substring(8, 12)

        if(index === 1 || index === 3 || index === 5 || index === 7 || index === 9 || index === 11 || index === 13 )
          page = pdfDoc.addPage();

        if(index === 1 || index === 3 || index === 5 || index === 7 || index === 9 || index === 11 || index === 13 ) {
          this.addText("B. Co Author", 70, 700, 400, 20, page, fontBold);

          this.addText("("+index+1+")", 80, 675, 400, 20, page, font);
          this.addText("Name", 105, 675, 400, 20, page, font);
          this.addText(": "+ coAuthor.name , 230, 675, 400, 20, page, font);
          this.addText("IC/ Passport No" , 105, 655, 400, 20, page, font);
          this.addText(": " + c, 230, 655, 400, 20, page, font);
          this.addText("Nationality" , 105, 635, 400, 20, page, font);
          this.addText(": " + coAuthor.nationality, 230, 635, 400, 20, page, font);
          this.addText("Position/Designation ", 105, 615, 400, 20, page, font);
          this.addText(": " + coAuthor.position, 230, 615, 400, 20, page, font);
          this.addText("Address", 105, 595, 400, 20, page, font);
          this.addText("(School / Center/ PTJ)", 105, 580, 400, 20, page, font);
          this.addText(": ", 230, 595, 200, 20, page, font, true);
          this.addText((coAuthor.add1 + ' ' + coAuthor.add2 + " " + coAuthor.add3).split(' ').slice(0, 30).join(' '), 235, 595, 300, 20, page, font);

          this.addText("Tel No. " , 105, 535, 400, 20, page, font);
          this.addText(": " + coAuthor.telephoneNo, 230, 535, 400, 20, page, font);
          this.addText("Email" , 105, 515, 400, 20, page, font);
          this.addText(": " + coAuthor.email, 230, 515, 400, 20, page, font);
          this.addText("Fax No" , 105, 495, 400, 20, page, font);
          this.addText(": " + coAuthor.faxNo, 230, 495, 400, 20, page, font);
          if (typeof (coAuthor.dateDeath) === 'object') {
            let d = new Date(coAuthor.dateDeath.toDate());
            dateString = ('0' + d.getDate()).slice(-2)
              + '-' + ('0' + (d.getMonth() + 1)).slice(-2)
              + '-' + d.getFullYear().toString();
          }
          this.addText("Date of Death " , 105, 475, 400, 20, page, font);
          this.addText("(if applicable only)" , 105, 460, 400, 20, page, font);
          this.addText(": " + dateString, 230, 475, 400, 20, page, font);
        }else{
            this.addText("("+index+1+")", 80, 410, 400, 20, page, font);
            this.addText("Name", 105, 390, 400, 20, page, font);
            this.addText(": "+ coAuthor.name , 230, 390, 400, 20, page, font);
            this.addText("IC/ Passport No" , 105, 370, 400, 20, page, font);
            this.addText(": " + c, 230, 370, 400, 20, page, font);
            this.addText("Nationality" , 105, 350, 400, 20, page, font);
            this.addText(": " + coAuthor.nationality, 230, 350, 400, 20, page, font);
            this.addText("Position/Designation ", 105, 330, 400, 20, page, font);
            this.addText(": " + coAuthor.position, 230, 330, 400, 20, page, font);
            this.addText("Address", 105, 310, 400, 20, page, font);
            this.addText("(School / Center/ PTJ)", 105, 295, 400, 20, page, font);
            this.addText(": ", 230, 310, 200, 20, page, font, true);
            this.addText((coAuthor.add1 + ' ' + coAuthor.add2 + " " + coAuthor.add3).split(' ').slice(0, 30).join(' '), 235, 310, 300, 20, page, font);
            this.addText("Tel No. " , 105, 250, 400, 20, page, font);
            this.addText(": " + coAuthor.telephoneNo, 230, 250, 400, 20, page, font);
            this.addText("Email" , 105, 230, 400, 20, page, font);
            this.addText(": " + coAuthor.email, 230, 230, 400, 20, page, font);
            this.addText("Fax No" , 105, 210, 400, 20, page, font);
            this.addText(": " + coAuthor.faxNo, 230, 210, 400, 20, page, font);
            if (typeof (coAuthor.dateDeath) === 'object') {
              let d = new Date(coAuthor.dateDeath.toDate());

              dateString = ('0' + d.getDate()).slice(-2)
                + '-' + ('0' + (d.getMonth() + 1)).slice(-2)
                + '-' + d.getFullYear().toString();
            }
            this.addText("Date of Death " , 105, 190, 400, 20, page, font);
            this.addText("(if applicable only)" , 105, 175, 400, 20, page, font);
            this.addText(": " + dateString, 230, 190, 400, 20, page, font);
        }
      }
    }

    const pdfBytes = await pdfDoc.save()
    let file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = "ListOfAuthors_" + f.id + ".pdf";
    anchor.href = fileURL;
    anchor.click();

  }
  MONTH = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  obj: any;

  async generateCR1Form(f) {
    const url = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/CR1_Latest.pdf?alt=media&token=4d8226fc-87eb-4b25-92d7-2976975c3ea7'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    const fonturl = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/WINGDNG2.TTF?alt=media&token=44461c60-dce3-4a45-9970-89f1a07a0681'
    const fontBytes = await fetch(fonturl).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    const pages = pdfDoc.getPages()

    pdfDoc.registerFontkit(fontkit)
    const customFont = await pdfDoc.embedFont(fontBytes)

    const firstPage = pages[0];
    const secondPage = pages[1];
    const thirdPage = pages[2];

    this.addText(f.formValue.title, 170, 458, 400, 200, firstPage, helveticaFont);
    this.addText(f.formValue.translation, 170, 415, 400, 200, firstPage, helveticaFont);
    this.addText(f.formValue.transliteration, 170, 385, 400, 200, firstPage, helveticaFont);
    this.addText(f.formValue.language, 170, 352, 400, 200, firstPage, helveticaFont);

    switch (f.formValue.category) {
      case "Literary":
        this.addText('P', 26, 265, 400, 200, firstPage, customFont);
        break;
      case "Musical":
        this.addText('P', 98, 265, 400, 200, firstPage, customFont);
        break;
      case "Artistic":
        this.addText('P', 163, 265, 400, 200, firstPage, customFont);
        break;
      case "Film":
        this.addText('P', 231, 265, 400, 200, firstPage, customFont);
        break;
      case "Sound Recording":
        this.addText('P', 283, 265, 400, 200, firstPage, customFont);
        break;
      case "Broadcast":
        this.addText('P', 390, 265, 400, 200, firstPage, customFont);
        break;
    }

    switch (f.formValue.derivative) {
      case "Translation":
        this.addText('P', 26, 217, 400, 200, firstPage, customFont);
        break;
      case "Adaptation":
        this.addText('P', 99, 217, 400, 200, firstPage, customFont);
        break;
      case "Arrangement":
        this.addText('P', 176, 217, 400, 200, firstPage, customFont);
        break;
      case "Collection of work or compilation of mere data (database)":
        this.addText('P', 260, 217, 400, 200, firstPage, customFont);
        break;
      case "Other transformation of work eligible for copyright":
        this.addText('P', 433, 217, 400, 200, firstPage, customFont);
        break;

    }

    this.addText(('0' + f.formValue.dateCreation.toDate().getDate()).slice(-2), 163, 180, 400, 200, firstPage, helveticaFont);
    this.addText(('0' + (f.formValue.dateCreation.toDate().getMonth() + 1)).slice(-2), 202, 180, 400, 200, firstPage, helveticaFont);
    this.addText(f.formValue.dateCreation.toDate().getFullYear().toString(), 240, 180, 400, 200, firstPage, helveticaFont);

    if (f.formValue.publishStatus === 'yes') {
      this.addText('P', 161, 137, 400, 200, firstPage, customFont);
      this.addText(f.formValue.yearOfCompilation, 168, 117, 400, 200, firstPage, helveticaFont);
      this.addText(('0' + f.formValue.datePublication.toDate().getDate()).slice(-2), 252, 117, 400, 200, firstPage, helveticaFont);
      this.addText(('0' + (f.formValue.datePublication.toDate().getMonth() + 1)).slice(-2), 290, 117, 400, 200, firstPage, helveticaFont);
      this.addText(f.formValue.datePublication.toDate().getFullYear().toString(), 325, 117, 400, 200, firstPage, helveticaFont);
      this.addText(f.formValue.publishCountry, 383, 117, 400, 200, firstPage, helveticaFont);
    }
    else
      this.addText('P', 237, 139, 400, 200, firstPage, customFont);

    this.addText(f.formValue.mainAuthor.name, 120, 227, 400, 20, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.ic, 120, 204, 400, 20, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.add1, 120, 183, 400, 20, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.add2, 120, 164, 400, 20, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.add3, 120, 145, 400, 20, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.postcode, 120, 123, 400, 20, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.city, 231, 123, 400, 20, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.nationality, 465, 123, 400, 20, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.state, 120, 98, 400, 20, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.country, 465, 98, 400, 20, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.email, 120, 78, 400, 20, firstPage, helveticaFont, false, 12);
    this.addText(f.formValue.mainAuthor.telephoneNo, 120, 53, 400, 20, firstPage, helveticaFont, false, 12);

    // if (typeof (f.formValue.mainAuthor.dateDeath) === 'object') {
    //   let dateOfDeath = new Date(f.formValue.mainAuthor.dateDeath);
    //   this.addText(('0' + dateOfDeath.getDate()).slice(-2), 473, 78, 400, 20, firstPage, helveticaFont);
    //   this.addText(('0' + (dateOfDeath.getMonth() + 1)).slice(-2), 507, 78, 400, 20, firstPage, helveticaFont);
    //   this.addText(dateOfDeath.getFullYear().toString(), 541, 78, 400, 20, firstPage, helveticaFont);
    // }


    // this.addText(f.formValue.mainAuthor.faxNo, 310, 53, 400, 20, firstPage, helveticaFont);

    // this.addText(f.formValue.licenseeInfo.name, 126, 531, 400, 20, secondPage, helveticaFont);
    // this.addText(f.formValue.licenseeInfo.ic, 126, 512, 400, 20, secondPage, helveticaFont);
    // this.addText(f.formValue.licenseeInfo.companyName, 126, 488, 400, 20, secondPage, helveticaFont);
    // this.addText(f.formValue.licenseeInfo.companyNo, 126, 467, 400, 20, secondPage, helveticaFont);
    // this.addText(f.formValue.licenseeInfo.add1, 126, 442, 400, 20, secondPage, helveticaFont);
    // this.addText(f.formValue.licenseeInfo.add2, 126, 423, 400, 20, secondPage, helveticaFont);
    // this.addText(f.formValue.licenseeInfo.add3, 126, 403, 400, 20, secondPage, helveticaFont);
    // this.addText(f.formValue.licenseeInfo.postcode, 126, 385, 400, 20, secondPage, helveticaFont);
    // this.addText(f.formValue.licenseeInfo.city, 230, 385, 400, 20, secondPage, helveticaFont);
    // this.addText(f.formValue.licenseeInfo.nationality, 485, 385, 400, 20, secondPage, helveticaFont);

    // this.addText(f.formValue.licenseeInfo.state, 126, 366, 400, 20, secondPage, helveticaFont);
    // this.addText(f.formValue.licenseeInfo.country, 485, 366, 400, 20, secondPage, helveticaFont);

    // this.addText(f.formValue.licenseeInfo.telephoneNo, 126, 347, 400, 20, secondPage, helveticaFont, false, 10);
    // this.addText(f.formValue.licenseeInfo.email, 252, 349, 400, 20, secondPage, helveticaFont, false, 10);
    // this.addText(f.formValue.licenseeInfo.faxNo, 485, 349, 400, 20, secondPage, helveticaFont);

    // if (typeof (f.formValue.licenseeInfo.dateOfAgree) === 'object') {
    //   let dateOfAgree = new Date(f.formValue.licenseeInfo.dateOfAgree.toDate());
    //   this.addText(('0' + dateOfAgree.getDate()).slice(-2), 152, 330, 400, 20, secondPage, helveticaFont);
    //   this.addText(('0' + (dateOfAgree.getMonth() + 1)).slice(-2), 189, 330, 400, 20, secondPage, helveticaFont);
    //   this.addText(dateOfAgree.getFullYear().toString(), 233, 330, 400, 20, secondPage, helveticaFont);
    // }

    // if (typeof (f.formValue.licenseeInfo.dateRange) === 'object') {
    //   this.obj = f.formValue.licenseeInfo.dateRange;
    //   if (this.obj.startDate && this.obj.endDate) {
    //     let startDate = this.obj.startDate.toDate();
    //     let endDate = this.obj.endDate.toDate();

    //     this.addText(('0' + startDate.getDate()).slice(-2), 152, 308, 400, 20, secondPage, helveticaFont);
    //     this.addText(('0' + (startDate.getMonth() + 1)).slice(-2), 189, 308, 400, 20, secondPage, helveticaFont);
    //     this.addText(startDate.getFullYear().toString(), 233, 308, 400, 20, secondPage, helveticaFont);

    //     this.addText(('0' + endDate.getDate()).slice(-2), 311, 308, 400, 20, secondPage, helveticaFont);
    //     this.addText(('0' + (endDate.getMonth() + 1)).slice(-2), 351, 308, 400, 20, secondPage, helveticaFont);
    //     this.addText(endDate.getFullYear().toString(), 388, 308, 400, 20, secondPage, helveticaFont);
    //   }

    // }
    // if (f.formValue.authorWork)
    //   this.addText('P', 33, 576, 400, 200, thirdPage, customFont);
    // if (f.formValue.ownerCopy)
    //   this.addText('P', 33, 554, 400, 200, thirdPage, customFont);
    // if (f.formValue.licensee)
    //   this.addText('P', 33, 532, 400, 200, thirdPage, customFont);

    this.addText(f.formValue.mainAuthor.name, 110, 498, 400, 200, thirdPage, helveticaFont, false, 12);

    this.addText(('0' +f.CreatedDate.getDate()).slice(-2) + " - " + ('0' + (f.CreatedDate.getMonth() + 1)).slice(-2) + ' - ' +f.CreatedDate.getFullYear().toString(), 110, 475, 400, 200, thirdPage, helveticaFont);


    const pdfBytes = await pdfDoc.save()
    let file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = "CR1_" + f.id + ".pdf";
    anchor.href = fileURL;
    anchor.click();
  }
  async generateCR5Form(f) {
    const url = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/CR5-default.pdf?alt=media&token=46fa2bd6-e29e-4422-ab0c-5afaae03cab4'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    const fonturl = 'https://firebasestorage.googleapis.com/v0/b/cic-usm.appspot.com/o/WINGDNG2.TTF?alt=media&token=44461c60-dce3-4a45-9970-89f1a07a0681'
    const fontBytes = await fetch(fonturl).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    const pages = pdfDoc.getPages()

    pdfDoc.registerFontkit(fontkit)
    const customFont = await pdfDoc.embedFont(fontBytes)

    const firstPage = pages[0];

    this.addText(f.formValue.title, 117, 424, 400, 200, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.name, 117, 375, 400, 200, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.ic, 117, 350, 400, 200, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.add1, 117, 320, 400, 200, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.add2, 117, 296, 400, 200, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.add3, 117, 276, 400, 200, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.postcode, 117, 256, 400, 200, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.city, 260, 256, 400, 200, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.nationality, 478, 256, 400, 200, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.state, 117, 233, 400, 200, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.country, 478, 233, 400, 200, firstPage, helveticaFont);
    this.addText(f.formValue.mainAuthor.telephoneNo, 117, 211, 400, 200, firstPage, helveticaFont, false, 10);
    this.addText(f.formValue.mainAuthor.email, 260, 211, 400, 200, firstPage, helveticaFont, false, 10);
    this.addText(f.formValue.mainAuthor.faxNo, 478, 211, 400, 200, firstPage, helveticaFont);

    switch (f.formValue.category) {
      case "Literary":
        this.addText('P', 30, 455, 400, 200, firstPage, customFont);
        break;
      case "Musical":
        this.addText('P', 102, 455, 400, 200, firstPage, customFont);
        break;
      case "Artistic":
        this.addText('P', 173, 455, 400, 200, firstPage, customFont);
        break;
      case "Film":
        this.addText('P', 241, 455, 400, 200, firstPage, customFont);
        break;
      case "Sound Recording":
        this.addText('P', 294, 455, 400, 200, firstPage, customFont);
        break;
      case "Broadcast":
        this.addText('P', 406, 455, 400, 200, firstPage, customFont);
        break;
      case "Derivative":
        this.addText('P', 491, 455, 400, 200, firstPage, customFont);
        break;
    }


    const pdfBytes = await pdfDoc.save()
    let file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = "CR5_"+f.id+".pdf";

    anchor.href = fileURL;
    anchor.click();
  }
  async generateSD(f) {
    const pdfDoc = await PDFDocument.create();
    const font = await pdfDoc.embedFont(StandardFonts.TimesRoman)
    const fontBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold)

    let page = pdfDoc.addPage();

    let text ="COPYRIGHT ACT 1987";
    let text1 ="IN THE MATTER";
    let text2 ="of Section 42 of the";
    let text21 = " Copyright Act 1987 (Act 332)";
    let text3 ="And";
    let text4 ="of the copyright in the";
    let text41 ="Work (as hereinafter defined and attached"
    let text42 ="hereto marked as \"Exhibit\" 2) in the name of"
    let text5 ="UNIVERSITI SAINS MALAYSIA"


    this.addText(text, 320, 800, 200, 20, page, fontBold);
    this.addText(text1, 320, 770, 200, 20, page, fontBold);
    this.addText(text2, 420, 770, 200, 20, page, font);
    this.addText(text21, 320, 750, 200, 20, page, font);

    this.addText(text3, 320, 720, 200, 20, page, font);
    this.addText(text1, 320, 690, 200, 20, page, fontBold);

    this.addText(text4, 420, 690, 200, 20, page, font);
    this.addText(text41, 320, 670, 300, 20, page, font);
    this.addText(text42, 320, 650, 300, 20, page, font);
    this.addText(text5, 320, 630, 200, 20, page, font);

    this.addText("STATUTORY DECLARATION", 190, 580, 400, 20, page, fontBold);
    page.drawLine({
      start: { x: 190, y: 584 },
      end: { x: 360, y: 584 },
      color: rgb(0, 0, 0),
      thickness: 1
    })
    f.formValue.mainAuthor.ic = '961212085097'
    let ic = f.formValue.mainAuthor.ic.substring(0,6) + '-' + f.formValue.mainAuthor.ic.substring(6,8) + '-' + f.formValue.mainAuthor.ic.substring(8,12)
    let intro = `I,${f.formValue.mainAuthor.name}(NRIC No: ${ic}) of full age and a Malaysian citizen with an address at the ${f.formValue.mainAuthor.add1} ${f.formValue.mainAuthor.add2} ${f.formValue.mainAuthor.add3} ${f.formValue.mainAuthor.country}, do hereby solemnly and sincerely declare that the following contents of this declaration are true:`

    let numbering1 = `1.`
    let numbering2 = `2.`
    let numbering3 = `3.`
    let numbering4 = `4.`
    let numbering5 = `5.`
    let numbering6 = `6.`
    let numbering7 = `7.`
    let numbering8 = `8.`

    let sentence1 = `I am an employee of UNIVERSITI SAINS MALAYSIA, an institution of higher learning with an address at ${f.formValue.mainAuthor.add1} ${f.formValue.mainAuthor.add2} ${f.formValue.mainAuthor.add3} (hereinafter referred to as \"USM\").`

    let sentence2 = `In my aforesaid capacity, I have been duly authorized by USM to make this Statutory Declaration on their behalf. The facts herein contained are, unless to the contrary is stated, from my personal knowledge or taken from the records of USM to which I have free and unrestricted access. The facts deposed to herein are true to the best of my knowledge, information, and belief.`

    let sentence3 = `USM is the owner of the copyright of the ${f.formValue.title} (hereinafter referred to as \"the Work\"). Please refer to \"Exhibit 2\".`

    let sentence4 = `The author(s) of the Work is as follows:\n1. ${f.formValue.mainAuthor.name} (NRIC no: ${ic})`

    let authorIndex = 2;
    for (const author of f.formValue.coAuthor) {
      let c = author.ic.substring(0,6) + '-' + author.ic.substring(6,8) + '-' + author.ic.substring(8,12)
      sentence4 += `\n${authorIndex}. ${author.name} (NRIC no: ${c})`;
      authorIndex++;
    }
    sentence4 +=  `\nThe personal details of the author(s) (hereinafter referred to as \"the Authors\") is attached herewith and marked as \"Exhibit 1\".`

    let date = f.formValue.dateCreation.toDate().getDate() + " " + this.MONTH[f.formValue.dateCreation.toDate().getMonth()] + " " + f.formValue.dateCreation.toDate().getFullYear();
    let sentence5 = `The Authors involved in the development of the Work is as listed in the document annexed as \"Exhibit 1\" herein and have been involved in the development of the Work for a period commencing from ${date}.\n\nThe Work comprises of one (1) document entitled as follows:\n(i)	[please refer to \"Exhibit 2\"]`

    let sentence6 = `I hereby declare the following:\n(i)	on ${date}, copyright subsisted in the Work and continues to subsist;\n(ii)	the Authors had expended sufficient effort to make the Work original in character;\n(iii)	the Work has been reduced to a material form\nand pursuant to section 7 of the Copyright Act, 1987, the Work is eligible for copyright protection.`

    let sentence7 = `I have further been advised and verily believe that as:\n(i)	the Authors were at all material times the employees and/or officers and/or students of USM and had developed the Work in the course of his employment/studies with USM; and\n(ii)	USM is a qualified person within the meaning of Section 10 of the Copyright Act 1987\n(iii)	the copyright in the Work belongs to USM.`

    let sentence8 = `Therefore I, on behalf of USM, do hereby assert the ownership of the copyright in the Work.\n And I make this solemn declaration conscientiously believing the same to be true and by virtue of Section 42 of the Copyright Act 1987 and the Statutory Declaration Act 1960.`

    let sentence9 = `SUBSCRIBED and SOLEMNLY DECLARED\nby ${f.formValue.mainAuthor.name}\n(${ic})\nat …………………………………………\non this ……………………………………\n\n……………………………………\n                   Before me`

    let sentence10 = `…………………………………………\n           Commissioner for Oaths`
    this.addText(intro, 50, 170, 500, 400, page, font);

    this.addText(numbering1, 50, 450, 20, 20, page, font);
    this.addText(sentence1, 100, 270, 450, 200, page, font);
    this.addText(numbering2, 50, 380, 20, 20, page, font);
    this.addText(sentence2, 100, 200, 450, 200, page, font);
    this.addText(numbering3, 50, 250, 20, 20, page, font);
    this.addText(sentence3, 100, 70, 450, 200, page, font);
    this.addText(numbering4, 50, 180, 20, 20, page, font);
    this.addText(sentence4, 100, 0, 450, 200, page, font);

    page = pdfDoc.addPage();

    this.addText(numbering5, 50, 750, 20, 20, page, font);
    this.addText(sentence5, 100, 570, 450, 200, page, font);
    this.addText(numbering6, 50, 610, 20, 20, page, font);
    this.addText(sentence6, 100, 430, 450, 200, page, font);
    this.addText(numbering7, 50, 470, 20, 20, page, font);
    this.addText(sentence7, 100, 290, 450, 200, page, font);
    this.addText(numbering8, 50, 350, 20, 20, page, font);
    this.addText(sentence8, 100, 170, 450, 200, page, font);
    this.addText(sentence9, 50, 80, 450, 200, page, font);
    this.addText(sentence10, 330, 60, 450, 100, page, font);

    page = pdfDoc.addPage();

    this.addText("EXHIBIT 1", 275, 750, 400, 20, page, fontBold);
    page.drawLine({
      start: { x: 275, y: 754 },
      end: { x: 335, y: 754 },
      color: rgb(0, 0, 0),
      thickness: 1
    })

    this.addText("LIST OF THE AUTHORS:", 80, 725, 400, 20, page, fontBold);
    page.drawLine({
      start: { x: 80, y: 729 },
      end: { x: 220, y: 729 },
      color: rgb(0, 0, 0),
      thickness: 1
    })

    this.addText("A. Main Author", 100, 700, 400, 20, page, fontBold);
    this.addText("(1)", 110, 675, 400, 20, page, font);
    this.addText("Name: " + f.formValue.mainAuthor.name, 140, 675, 400, 20, page, font);
    this.addText("IC/ Passport No: " + ic, 140, 660, 400, 20, page, font);
    this.addText("Nationality: " + f.formValue.mainAuthor.nationality, 140, 645, 400, 20, page, font);
    this.addText("Position/Designation: " + f.formValue.mainAuthor.position, 140, 630, 400, 20, page, font);
    this.addText("School / Center/ PTJ (Address):", 140, 615, 400, 20, page, font);
    this.addText(f.formValue.mainAuthor.add1 + ' ' + f.formValue.mainAuthor.add2 + " " + f.formValue.mainAuthor.add3, 300, 615, 200, 20, page, font, true);
    this.addText("Tel No.: " + f.formValue.mainAuthor.telephoneNo, 140, 570, 400, 20, page, font);
    this.addText("Email: " + f.formValue.mainAuthor.email, 140, 555, 400, 20, page, font);
    this.addText("Fax No: " + f.formValue.mainAuthor.faxNo, 140, 540, 400, 20, page, font);

    let dateString = '';

    if (typeof (f.formValue.mainAuthor.dateDeath) === 'object') {
      let dateOfDeath = new Date(f.formValue.mainAuthor.dateDeath);
      dateString = ('0' + dateOfDeath.getDate()).slice(-2)
        + '-' + ('0' + (dateOfDeath.getMonth() + 1)).slice(-2)
        + '-' + dateOfDeath.getFullYear().toString();
    }
    this.addText("Date of Death (if applicable only): " + dateString, 140, 525, 400, 20, page, font);

    if (f.formValue.coAuthor.length > 0) {
      this.addText("B. Co Author", 100, 475, 400, 20, page, fontBold);

      for (let index = 0; index < f.formValue.coAuthor.length; index++) {
        let coAuthor = f.formValue.coAuthor[index];
        let c = f.formValue.coAuthor[index].ic.substring(0,6) + '-' + f.formValue.coAuthor[index].ic.substring(6,8) + '-' + f.formValue.coAuthor[index].ic.substring(8,12)

        if (index === 0 || index === 3) {
          this.addText("(" + (index + 1) + ")", 110, 450, 400, 20, page, font);
          this.addText("Name: " + coAuthor.name, 140, 450, 400, 20, page, font);
          this.addText("IC/ Passport No: " + c, 140, 435, 400, 20, page, font);
          this.addText("Nationality: " + coAuthor.nationality, 140, 420, 400, 20, page, font);
          this.addText("Position/Designation: " + coAuthor.position, 140, 405, 400, 20, page, font);
          this.addText("School / Center/ PTJ (Address):", 140, 390, 400, 20, page, font);
          this.addText(coAuthor.add1 + ' ' + coAuthor.add2 + ' ' + coAuthor.add3, 300, 390, 200, 20, page, font, true);
          this.addText("Tel No.: " + coAuthor.telephoneNo, 140, 345, 400, 20, page, font);
          this.addText("Email: " + coAuthor.email, 140, 330, 400, 20, page, font);
          this.addText("Fax No: " + coAuthor.faxNo, 140, 315, 400, 20, page, font);
          if (typeof (coAuthor.dateDeath) === 'object') {
            let d = new Date(coAuthor.dateDeath);
            dateString = ('0' + d.getDate()).slice(-2)
              + '-' + ('0' + (d.getMonth() + 1)).slice(-2)
              + '-' + d.getFullYear().toString();
          }
          this.addText("Date of Death (if applicable only): " + dateString, 140, 300, 400, 20, page, font);
        } else if (index === 1 || index === 4) {
          this.addText("(" + (index + 1) + ")", 110, 250, 400, 20, page, font);
          this.addText("Name: " + coAuthor.name, 140, 250, 400, 20, page, font);
          this.addText("IC/ Passport No: " + c, 140, 235, 400, 20, page, font);
          this.addText("Nationality: " + coAuthor.nationality, 140, 220, 400, 20, page, font);
          this.addText("Position/Designation: " + coAuthor.position, 140, 205, 400, 20, page, font);
          this.addText("School / Center/ PTJ (Address):", 140, 390, 400, 20, page, font);
          this.addText(coAuthor.add1 + ' ' + coAuthor.add2 + ' ' + coAuthor.add3, 300, 390, 200, 20, page, font, true);
          this.addText("Tel No.: " + coAuthor.telephoneNo, 140, 145, 400, 20, page, font);
          this.addText("Email: " + coAuthor.email, 140, 130, 400, 20, page, font);
          this.addText("Fax No: " + coAuthor.faxNo, 140, 115, 400, 20, page, font);
          if (typeof (coAuthor.dateDeath) === 'object') {
            let d = new Date(coAuthor.dateDeath);
            dateString = ('0' + d.getDate()).slice(-2)
              + '-' + ('0' + (d.getMonth() + 1)).slice(-2)
              + '-' + d.getFullYear().toString();
          }
          this.addText("Date of Death (if applicable only): " + dateString, 140, 100, 400, 20, page, font);
        } else if (index === 2 || index === 5) {
          page = pdfDoc.addPage();
          this.addText("(" + (index + 1) + ")", 110, 675, 400, 20, page, font);
          this.addText("Name: " + coAuthor.name, 140, 675, 400, 20, page, font);
          this.addText("IC/ Passport No: " + c, 140, 660, 400, 20, page, font);
          this.addText("Nationality: " + coAuthor.nationality, 140, 645, 400, 20, page, font);
          this.addText("Position/Designation: " + coAuthor.position, 140, 630, 400, 20, page, font);
          this.addText("School / Center/ PTJ (Address):", 140, 615, 400, 20, page, font);
          this.addText(coAuthor.add1 + ' ' + coAuthor.add2 + ' ' + coAuthor.add3, 300, 615, 200, 20, page, font, true);
          this.addText("Tel No.: " + coAuthor.telephoneNo, 140, 570, 400, 20, page, font);
          this.addText("Email: " + coAuthor.email, 140, 555, 400, 20, page, font);
          this.addText("Fax No: " + coAuthor.faxNo, 140, 540, 400, 20, page, font);
          if (typeof (coAuthor.dateDeath) === 'object') {
            let d = new Date(coAuthor.dateDeath);
            dateString = ('0' + d.getDate()).slice(-2)
              + '-' + ('0' + (d.getMonth() + 1)).slice(-2)
              + '-' + d.getFullYear().toString();
          }
          this.addText("Date of Death (if applicable only): " + dateString, 140, 525, 400, 20, page, font);
        }
      }
    }

    const pdfBytes = await pdfDoc.save()
    let file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = "SD_"+f.id+".pdf";
    anchor.href = fileURL;
    anchor.click();

  }
}
