import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { UserInfoService } from './../../../Services/Utilities/user-info.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { NgModel } from '@angular/forms';
import { transition, style, animate, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material';
import { RegisterFormComponent } from './register-form/register-form.component';

const enterTransition = transition(':enter', [
  style({
    opacity: 0
  }),
  animate('1s ease-in', style({
    opacity: 1
  }))
]);

const leaveTrans = transition(':leave', [
  style({
    opacity: 1
  }),
  animate('1s ease-out', style({
    opacity: 0
  }))
])

const fadeIn = trigger('fadeIn', [
  enterTransition
]);

const fadeOut = trigger('fadeOut', [
  leaveTrans
]);

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    fadeIn,
    fadeOut
  ]
})
export class LoginComponent implements OnInit {

  hidePassword = true;
  hidePasswordR = true;
  hideConfirmPasswordR = true;

  private dateFormat = new DateFormatService();

  loginModel: any = {};
  registerModel: any = {};
  flag = true;
  validationsMessages = ' ';
  today = Date.now();
  breakpoint: boolean;
  app;

  constructor(
    private router: Router,
    private toast: ToastrService,
    private angularFireAuth: AngularFireAuth,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private userinfoSrv: UserInfoService,
    private firestore: AngularFirestore
  ) {

    this.angularFireAuth.authState.subscribe(async authState =>{
    this.spinner.show()
      if(authState === null){
        this.spinner.hide();
      } else{
        if(authState.emailVerified){
          const snapshot = await this.firestore.collection("Users").doc(authState.uid).get();
          snapshot.forEach(e=>{
            if(e.exists){
              if(e.data().Role === 'Admin')
              this.router.navigate(['Admin/CIC/Dashboard']);
            else if(e.data().Role === 'User')
              this.router.navigate(['Home/CIC/Dashboard']);
            }else{
              this.userinfoSrv.SetRole('')
              this.userinfoSrv.setAccessRight([]);
              this.router.navigate(['/Login']);
              this.toast.info("Please login again");
            }
          })
        }
        this.spinner.hide();

      }
    })

  }

  ngOnInit() {

    this.breakpoint = (window.innerWidth <= 800) ? true : false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    this.breakpoint = (window.innerWidth <= 800) ? true : false;
  }

  changeForm() {
    this.flag = !this.flag;
    this.registerSuccessful = false;

  }

  newForm(){
    this.dialog.open(RegisterFormComponent, {
      width: '70%',
      height: '90%',
      disableClose: true,
    })
  }

  login() {
    const email = this.loginModel.email;
    const password = this.loginModel.password;
    this.spinner.show();
    this.angularFireAuth.auth.signInWithEmailAndPassword(email, password)
      .then(async res => {
         await this.firestore.collection('Admin').doc(res.user.uid).get().forEach(async e=>{
          if(e.exists){
            const role = e.data().Role;
            this.userinfoSrv.SetRole(role)
            const accessRights = []
            if(role !== 'SuperAdmin'){
              e.data().Access.forEach(a=>{
                const info={
                  module: a.Module,
                  right: a.Value,
                }
                accessRights.push(info);
              })
            }
            this.userinfoSrv.setAccessRight(accessRights);
            this.router.navigate(['Admin']);
            this.spinner.hide();
          }else{
            if(res.user.emailVerified || res.user.email === 'cicadmin@cic.com'){
              this.toast.success('Welcome to CIC USM', 'Login Successfully !', { timeOut: 3000 });
              const snapshot = await this.firestore.collection('Users').doc(res.user.uid).get();
              snapshot.forEach(e=>{
                if(e.exists){
                  this.userinfoSrv.ic = e.data().IC;

                  if(e.data().Role === 'Admin')
                    this.router.navigate(['Admin/copyright']);
                  else if(e.data().Role === 'User')
                    this.router.navigate(['Home/copyright']);
                }else{
                  this.validationsMessages = 'Invalid email or password.';
                }
              })
            }else{
              this.toast.info("Not yet verified email, please go and verify");
              this.dialog.open(VerifyEmailComponent, {
                width: 'auto',
                height: 'auto',
                disableClose: true,
              })
            }
          }
          this.spinner.hide();
        });





      })
      .catch(err => {
        this.validationsMessages = 'Invalid email or password.';
        this.spinner.hide();

      });
  }
  registerSuccessful = false;
  emailFlag = false;
  emailChange(event, model: NgModel) {
    if (!(/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@usm.my$/.test(model.value))){
      model.control.setErrors({ invalid: true });
      this.emailFlag = true;
    }else{
      this.emailFlag = false;
    }
  }

  sendForgetPassword(){
    this.angularFireAuth.auth.sendPasswordResetEmail(this.registerModel.email).then(
      () => {
        this.toast.success("Forget password email has been sent")
      },
      err => {
        this.toast.success(err,"Failed to send");
      }
    );
  }
  async register() {
    const email = this.registerModel.email;
    const password = this.registerModel.password;
    this.registerSuccessful = false;


    await this.angularFireAuth.auth.createUserWithEmailAndPassword(email, password)
      .then(async fbAuth => {
        let newUserInfo ={
          CreatedDate : new Date(),
          UpdatedDate : new Date(),
          CreatedBy : email,
          UpdatedBy : email,
          Email: email,
          Role : "User",
          Name : this.registerModel.name
        }
        const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
        const info = {
          message: "New User has been register. Email: "  + email,
          date: new Date()
        };
        await this.firestore.collection("Users").doc(fbAuth.user.uid).set(newUserInfo);
        await this.firestore.collection('UsersLog').doc(dateFormat).set({ Date: new Date() });
        await this.firestore.collection('UsersLog').doc(dateFormat).collection('User').add(info);
        // await fbAuth.user.sendEmailVerification();
        this.toast.success("Welcome to CIC-USM", 'Register successfully', { timeOut: 3000 });

        // this.registerSuccessful = true;
      })
      .catch(err => {
        // this.registerSuccessful = false;

        this.toast.error(err, 'Failed to register', { timeOut: 3000 });
      })

  }

}



