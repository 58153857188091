import { DoubleTaxService } from '../../../Services/Utilities/double_tax.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { MatDatepicker, MatDialog, MatTabChangeEvent, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PDFDocument, PDFPage, layoutMultilineText, TextAlignment, rgb, StandardFonts } from 'pdf-lib';

@Component({
  selector: 'app-new-double_tax',
  templateUrl: './new-double_tax.component.html',
  styleUrls: ['./new-double_tax.component.css']
})
export class NewDoubleTaxComponent implements OnInit {

  todayDate = new Date();
  firstFormGroup = this._formBuilder.group({
    Applicant: ['', Validators.required],
    School: ['', Validators.required],
    Email: ['', Validators.required],
    Phone: [''],
    ProjectMembers: this._formBuilder.array([]),
  });
  secondFormGroup = this._formBuilder.group({
    ProjectTitle: ['', Validators.required],
    Keywords: ['', Validators.required],
    StartDate: [new Date(), Validators.required],
    EndDate: [new Date(), Validators.required],
    ProjectDuration: ['', Validators.required],
    ProjectBudget: ['', Validators.required],
  });
  thirdFormGroup = this._formBuilder.group({
    CompanyName: ['', Validators.required],
    CompanyAddress: ['', Validators.required],
    CompanySSMNumber: ['', Validators.required],
    CompanyContactPersonName: ['', Validators.required],
    CompanyContactPersonICNumber: ['', Validators.required],
    CompanyContactPersonPosition: ['', Validators.required],
    CompanyContactPersonEmail: ['', Validators.required],
    CompanyContactPersonPhone: ['', Validators.required],
    CompanyDescription: ['', Validators.required],
  });
  fourthFormGroup = this._formBuilder.group({
    ExecutiveSummary: ['', Validators.required],
    ProblemStatement: ['', Validators.required],
    Objectives: ['', Validators.required],
    ResearchBackground: ['', Validators.required],
    Methodology: ['', Validators.required],
    AccessToEquipment: ['', Validators.required],
    ExpectedResearch: ['', Validators.required],
  });
  fifthFormGroup = this._formBuilder.group({
    AllowanceDesc: ['', Validators.required],
    AllowanceYear1: [0, Validators.required],
    AllowanceYear2: [0, Validators.required],
    AllowanceYear3: [0, Validators.required],
    TravelDesc: ['', Validators.required],
    TravelYear1: [0, Validators.required],
    TravelYear2: [0, Validators.required],
    TravelYear3: [0, Validators.required],
    RentalDesc: ['', Validators.required],
    RentalYear1: [0, Validators.required],
    RentalYear2: [0, Validators.required],
    RentalYear3: [0, Validators.required],
    ResearchDesc: ['', Validators.required],
    ResearchYear1: [0, Validators.required],
    ResearchYear2: [0, Validators.required],
    ResearchYear3: [0, Validators.required],
    MaintenanceDesc: ['', Validators.required],
    MaintenanceYear1: [0, Validators.required],
    MaintenanceYear2: [0, Validators.required],
    MaintenanceYear3: [0, Validators.required],
    ProfessionalDesc: ['', Validators.required],
    ProfessionalYear1: [0, Validators.required],
    ProfessionalYear2: [0, Validators.required],
    ProfessionalYear3: [0, Validators.required],
    AccessoriesDesc: ['', Validators.required],
    AccessoriesYear1: [0, Validators.required],
    AccessoriesYear2: [0, Validators.required],
    AccessoriesYear3: [0, Validators.required]
  });
  sixthFormGroup = this._formBuilder.group({
    Additional: [[]],
    ProjectGantt: ['', Validators.required],
    CompanyOfferLetter: ['', Validators.required],
  });
  seventhFormGroup = this._formBuilder.group({
    Agree1: [false, Validators.required],
    Agree2: [false, Validators.required],
    Name: ['', Validators.required],
  });
  eightFormGroup = this._formBuilder.group({
    Commitment: this._formBuilder.array([]),
  });

  projectMember = {
    Name: '',
    IdentityNumber: '',
    USMStaff: false,
    Experience: '',
  }

  commitment = {
    Month: '',
    Year: '',
    Amount: 0,
  }

  publications = {
    Name: ''
  }
  intelliectuals = {
    Name: ''
  }

  researchs = {
    Name: ''
  }

  grants = {
    Name: '',
    Value: 0
  }

  consultations = {
    Name: '',
    Value: 0
  }

  technologys = {
    Name: '',
    Value: 0
  }

  product = {
    Name: '',
    Value: 0
  }

  industrialPatner = {
    Name: '',
  }

  @ViewChild('picker', { read: MatDatepicker, static: false }) datePickerElement = MatDatepicker;

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: true,

  };

  email;
  uuid;
  edit = false;
  editID;

  constructor(
    private _formBuilder: FormBuilder,
    private toast: ToastrService,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private router: Router,
    public doubleTaxService: DoubleTaxService
  ) {


    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
      this.uuid = auth.uid;

      if (doubleTaxService.double_tax) {
        this.edit = true;
        let f = doubleTaxService.double_tax;
        this.editID = f.id;

        this.firstFormGroup = this._formBuilder.group({
          Applicant: [f.firstFormGroup.Applicant, Validators.required],
          School: [f.firstFormGroup.School, Validators.required],
          Email: [f.firstFormGroup.Email, Validators.required],
          Phone: [f.firstFormGroup.Phone],
          ProjectMembers: this._formBuilder.array([]),
        });

        for (const dir of f.firstFormGroup.ProjectMembers) {
          this.projectMembers().push(
            this._formBuilder.group({
              Name: [dir.Name, Validators.required],
              IdentityNumber: [dir.IdentityNumber, Validators.required],
              Experience: [dir.Experience, Validators.required],
              Percentages: [dir.Percentages, Validators.required],
              USMStaff: dir.USMStaff,
              isEdit: false
            }));
        }

        this.eightFormGroup = this._formBuilder.group({
          Commitment: this._formBuilder.array([]),
        });

        for (const dir of f.eightFormGroup.Commitment) {
          this.commitments().push(
            this._formBuilder.group({
              Month: [dir.Month, Validators.required],
              Year: [dir.Year, Validators.required],
              Amount: [dir.Amount, Validators.required],
              isEdit: false
            }));
        }


        this.secondFormGroup = this._formBuilder.group({
          ProjectTitle: [f.secondFormGroup.ProjectTitle, Validators.required],
          Keywords: [f.secondFormGroup.Keywords, Validators.required],
          StartDate: [f.secondFormGroup.StartDate, Validators.required],
          EndDate: [f.secondFormGroup.EndDate, Validators.required],
          ProjectDuration: [f.secondFormGroup.ProjectDuration, Validators.required],
          ProjectBudget: [f.secondFormGroup.ProjectBudget, Validators.required],
        });

        this.thirdFormGroup = this._formBuilder.group({
          CompanyName: [f.thirdFormGroup.CompanyName, Validators.required],
          CompanyAddress: [f.thirdFormGroup.CompanyAddress, Validators.required],
          CompanySSMNumber: [f.thirdFormGroup.CompanySSMNumber, Validators.required],
          CompanyContactPersonName: [f.thirdFormGroup.CompanyContactPersonName, Validators.required],
          CompanyContactPersonICNumber: [f.thirdFormGroup.CompanyContactPersonICNumber, Validators.required],
          CompanyContactPersonPosition: [f.thirdFormGroup.CompanyContactPersonPosition, Validators.required],
          CompanyContactPersonEmail: [f.thirdFormGroup.CompanyContactPersonEmail, Validators.required],
          CompanyContactPersonPhone: [f.thirdFormGroup.CompanyContactPersonPhone, Validators.required],
          CompanyDescription: [f.thirdFormGroup.CompanyDescription, Validators.required],
        });

        this.fourthFormGroup = this._formBuilder.group({
          ExecutiveSummary: [f.fourthFormGroup.ExecutiveSummary, Validators.required],
          ProblemStatement: [f.fourthFormGroup.ProblemStatement, Validators.required],
          Objectives: [f.fourthFormGroup.Objectives, Validators.required],
          ResearchBackground: [f.fourthFormGroup.ResearchBackground, Validators.required],
          Methodology: [f.fourthFormGroup.Methodology, Validators.required],
          AccessToEquipment: [f.fourthFormGroup.AccessToEquipment, Validators.required],
          ExpectedResearch: [f.fourthFormGroup.ExpectedResearch, Validators.required],
        });

        this.fifthFormGroup = this._formBuilder.group({
          AllowanceDesc: [f.fifthFormGroup.AllowanceDesc, Validators.required],
          AllowanceYear1: [f.fifthFormGroup.AllowanceYear1, Validators.required],
          AllowanceYear2: [f.fifthFormGroup.AllowanceYear2, Validators.required],
          AllowanceYear3: [f.fifthFormGroup.AllowanceYear3, Validators.required],
          TravelDesc: [f.fifthFormGroup.TravelDesc, Validators.required],
          TravelYear1: [f.fifthFormGroup.TravelYear1, Validators.required],
          TravelYear2: [f.fifthFormGroup.TravelYear2, Validators.required],
          TravelYear3: [f.fifthFormGroup.TravelYear3, Validators.required],
          ResearchDesc: [f.fifthFormGroup.ResearchDesc, Validators.required],
          ResearchYear1: [f.fifthFormGroup.ResearchYear1, Validators.required],
          ResearchYear2: [f.fifthFormGroup.ResearchYear2, Validators.required],
          ResearchYear3: [f.fifthFormGroup.ResearchYear3, Validators.required],
          RentalDesc: [f.fifthFormGroup.RentalDesc, Validators.required],
          RentalYear1: [f.fifthFormGroup.RentalYear1, Validators.required],
          RentalYear2: [f.fifthFormGroup.RentalYear2, Validators.required],
          RentalYear3: [f.fifthFormGroup.RentalYear3, Validators.required],
          MaintenanceDesc: [f.fifthFormGroup.MaintenanceDesc, Validators.required],
          MaintenanceYear1: [f.fifthFormGroup.MaintenanceYear1, Validators.required],
          MaintenanceYear2: [f.fifthFormGroup.MaintenanceYear2, Validators.required],
          MaintenanceYear3: [f.fifthFormGroup.MaintenanceYear3, Validators.required],
          ProfessionalDesc: [f.fifthFormGroup.ProfessionalDesc, Validators.required],
          ProfessionalYear1: [f.fifthFormGroup.ProfessionalYear1, Validators.required],
          ProfessionalYear2: [f.fifthFormGroup.ProfessionalYear2, Validators.required],
          ProfessionalYear3: [f.fifthFormGroup.ProfessionalYear3, Validators.required],
          AccessoriesDesc: [f.fifthFormGroup.AccessoriesDesc, Validators.required],
          AccessoriesYear1: [f.fifthFormGroup.AccessoriesYear1, Validators.required],
          AccessoriesYear2: [f.fifthFormGroup.AccessoriesYear2, Validators.required],
          AccessoriesYear3: [f.fifthFormGroup.AccessoriesYear3, Validators.required],
        });

        this.sixthFormGroup = this._formBuilder.group({
          Additional: [f.sixthFormGroup.Additional],
          ProjectGantt: [f.sixthFormGroup.ProjectGantt, Validators.required],
          CompanyOfferLetter: [f.sixthFormGroup.CompanyOfferLetter, Validators.required]
        });

        this.seventhFormGroup = this._formBuilder.group({
          Agree1: [f.seventhFormGroup.Agree1, Validators.required],
          Agree2: [f.seventhFormGroup.Agree2, Validators.required],
          Name: [f.seventhFormGroup.Name, Validators.required],
        });

      } else {
        this.firestore.collection('Users').doc(this.uuid).get().forEach(file => {
          this.firstFormGroup.get('Applicant').setValue(file.data().Name);
          this.firstFormGroup.get('Email').setValue(file.data().Email);
          this.firstFormGroup.get('Phone').setValue(file.data().TelephoneNo);
          this.firstFormGroup.get('School').setValue(file.data().SchoolName);
          this.seventhFormGroup.get('Name').setValue(file.data().Name);
        });

      }
      if (this.router.url.includes('Edit')) {
        if (!this.edit)
          this.router.navigate(['Home/double_tax'])
      }

    });

  }

  ngOnInit() {

  }


  validateError(formControl: FormControl) {

    if (formControl.value.split(' ').length > 150)
      formControl.setErrors({ 'invalid': true })
    else
      formControl.setErrors({ 'invalid': null })
  }


  back() {
    this.router.navigate(['/Home/double_tax']);

  }
  getWordLeft(word) {
    return word.split(' ');
  }

  detectChanges1(event) {
    this.firstFormGroup.get('AuditedYearEnd').setValue(event);
  }

  selectedIndex = 0;
  async saveAndNext(ddd = false) {
    if (!ddd)
      this.selectedIndex++;

    if (!ddd || (this.firstFormGroup.get('ProjectMembers').value.length > 0)) {
      this.spinner.show();
      this.temporarySave();
    }

  }

  async temporarySave() {
    try {
      this.spinner.show();
      if (this.edit) {
        let six = this.sixthFormGroup.getRawValue();
        await this.uploadImages(six);
        let info = {
          UpdatedDate: new Date(),
          UpdatedBy: this.email,
          CreatedBy: this.email,
          Status: this.doubleTaxService.double_tax ? this.doubleTaxService.double_tax.Status : "Draft",
          firstFormGroup: this.firstFormGroup.value,
          secondFormGroup: this.secondFormGroup.value,
          thirdFormGroup: this.thirdFormGroup.value,
          fourthFormGroup: this.fourthFormGroup.value,
          seventhFormGroup: this.seventhFormGroup.value,
          fifthFormGroup: this.fifthFormGroup.value,
          eightFormGroup: this.eightFormGroup.value,

          UserID: this.uuid,
          sixthFormGroup: six,
        }
        await this.firestore.collection("DoubleTax").doc(this.editID).update(info);
        this.spinner.hide();

      } else {
        await this.firestore.collection("DoubleTaxID").doc("ID").get().forEach(async item => {
          let ID = ('000' + item.data().ID).slice(-3);
          ID = `DT${new Date().getFullYear()}-${ID}`;

          let six = this.sixthFormGroup.getRawValue();
          await this.uploadImages(six);

          let info = {
            UpdatedDate: new Date(),
            CreatedDate: new Date(),
            UpdatedBy: this.email,
            CreatedBy: this.email,
            Status: this.doubleTaxService.double_tax ? this.doubleTaxService.double_tax.Status : "Draft",
            firstFormGroup: this.firstFormGroup.value,
            secondFormGroup: this.secondFormGroup.value,
            thirdFormGroup: this.thirdFormGroup.value,
            fourthFormGroup: this.fourthFormGroup.value,
            seventhFormGroup: this.seventhFormGroup.value,
            fifthFormGroup: this.fifthFormGroup.value,
          eightFormGroup: this.eightFormGroup.value,
          UserID: this.uuid,
            sixthFormGroup: six,
          }

          await this.firestore.collection("DoubleTax").doc(ID).set(info);
          await this.firestore.collection('DoubleTaxDrafts').doc(this.uuid).delete();
          await this.firestore.collection('DoubleTaxID').doc("ID").set({ ID: item.data().ID + 1 })
          this.spinner.hide();
          this.edit = true;
          this.editID = ID;

        })
      }


    } catch (error) {
      this.toast.error(error, "Error happen");
      this.spinner.hide();

    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedIndex = tabChangeEvent.index;
    this.saveAndNext(true);
  }

  projectMembers(): FormArray {
    return this.firstFormGroup.get('ProjectMembers') as FormArray;
  }
  addProjectMembers() {
    this.projectMembers().push(this.newProjectMembers());
  }

  newProjectMembers() {
    let info = { ...this.projectMember }
    this.projectMember = {
      Name: '',
      IdentityNumber: '',
      USMStaff: false,
      Experience: '',
    }
    return this._formBuilder.group({
      ...info,
      isEdit: false,
    });
  }


  commitments(): FormArray {
    return this.eightFormGroup.get('Commitment') as FormArray;
  }
  addcommitments() {
    this.commitments().push(this.newcommitments());
  }

  newcommitments() {
    let info = { ...this.commitment }
    this.commitment = {
      Month: '',
      Year: '',
      Amount: 0,
    }
    return this._formBuilder.group({
      ...info,
      isEdit: false,
    });
  }


  save(event: any): void {
    let val = this.sixthFormGroup.get('Additional').value;
    this.sixthFormGroup.get('Additional').setValue([...val, ...event.target.files])
  }

  removeFile(index) {
    let val = this.sixthFormGroup.get('Additional').value;
    val.splice(index, 1);
    this.sixthFormGroup.get('Additional').setValue(val)
  }

  save4(event: any, value): void {
    this.sixthFormGroup.get(value).setValue(event.target.files[0])
  }

  editForm(i, type) {
    switch (type) {
      case 'projectMembers':
        this.projectMembers().at(i).get('isEdit').setValue(!this.projectMembers().at(i).get('isEdit').value);
        break;
      case 'commitment':
        this.commitments().at(i).get('isEdit').setValue(!this.commitments().at(i).get('isEdit').value);
        break;
    }

  }

  remove(i, type) {
    switch (type) {
      case 'projectMembers':
        this.projectMembers().removeAt(i);
        break;
      case 'commitment':
        this.commitments().removeAt(i);
        break;
    }
  }


  dateFormat = new DateFormatService();


  saveAsSubmit() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm submit the new application?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          if (this.edit) {

            let six = this.sixthFormGroup.getRawValue();
            await this.uploadImages(six);
            let info = {
              UpdatedDate: new Date(),
              SubmittedDate: new Date(),
              CreatedBy: this.email,
              UpdatedBy: this.email,
              UserID: this.uuid,
              Status: "Submitted",
              firstFormGroup: this.firstFormGroup.value,
              secondFormGroup: this.secondFormGroup.value,
              thirdFormGroup: this.thirdFormGroup.value,
              fourthFormGroup: this.fourthFormGroup.value,
              seventhFormGroup: this.seventhFormGroup.value,
              fifthFormGroup: this.fifthFormGroup.value,
          eightFormGroup: this.eightFormGroup.value,
          sixthFormGroup: six,

            }
            await this.firestore.collection("DoubleTax").doc(this.editID).update(info);
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
            const infos = {
              message: "New application has been submitted by: " + this.email,
              date: new Date(),
              data: JSON.stringify(info),
            };
            await this.firestore.collection('DoubleTaxDrafts').doc(this.uuid).delete();
            await this.firestore.collection('DoubleTaxLog').doc(dateFormat).set({ Date: new Date() });
            await this.firestore.collection('DoubleTaxLog').doc(dateFormat).collection('User').add(infos);
            this.spinner.hide();
            this.toast.success("Saved Successfully");
            this.router.navigate(['/Home/double_tax']);
          } else {
            await this.firestore.collection("DoubleTaxID").doc("ID").get().forEach(async item => {
              let ID = ('000' + item.data().ID).slice(-3);
              ID = `DT${new Date().getFullYear()}-${ID}`;

              let six = this.sixthFormGroup.getRawValue();
              await this.uploadImages(six);
              let info = {
                UpdatedDate: new Date(),
                SubmittedDate: new Date(),
                CreatedDate: new Date(),
                UpdatedBy: this.email,
                Status: "Submitted",
                firstFormGroup: this.firstFormGroup.value,
                secondFormGroup: this.secondFormGroup.value,
                thirdFormGroup: this.thirdFormGroup.value,
                fourthFormGroup: this.fourthFormGroup.value,
                seventhFormGroup: this.seventhFormGroup.value,
                fifthFormGroup: this.fifthFormGroup.value,
                sixthFormGroup: six,
                UserID: this.uuid,
                CreatedBy: this.email,
              }
              await this.firestore.collection("DoubleTax").doc(ID).set(info);
              await this.firestore.collection('DoubleTaxDrafts').doc(this.uuid).delete();

              const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
              const infos = {
                message: "New application has been submitted by: " + this.email,
                date: new Date(),
                data: JSON.stringify(info),
              };
              await this.firestore.collection('DoubleTaxLog').doc(dateFormat).set({ Date: new Date() });
              await this.firestore.collection('DoubleTaxLog').doc(dateFormat).collection('User').add(infos);
              await this.firestore.collection('DoubleTaxID').doc("ID").set({ ID: item.data().ID + 1 })

              this.spinner.hide();
              this.toast.success("Update Successfully");
              this.router.navigate(['/Home/double_tax']);
            })

          }


        } catch (error) {
          this.toast.error(error, "Error happen");
          this.spinner.hide();

        }
      }
    })
  }

  async uploadImages(six) {
    let realAttachments = [];

    for (const p of six.Additional) {
      if (p && p.name && !p.url) {
        realAttachments.push(await this.uploadImageAsPromise(p));
      } else if (p.url) {
        realAttachments.push(p);
      }
    }
    six.Additional = realAttachments;

    if (six.ProjectGantt && six.ProjectGantt.name && !six.ProjectGantt.url) {
      let file = await this.uploadImageAsPromise(six.ProjectGantt);
      six.ProjectGantt = null;
      six.ProjectGantt = file;
    }
    if (six.CompanyOfferLetter && six.CompanyOfferLetter.name && !six.CompanyOfferLetter.url) {
      let file = await this.uploadImageAsPromise(six.CompanyOfferLetter);
      six.CompanyOfferLetter = null;
      six.CompanyOfferLetter = file;
    }



  }


  uploadImageAsPromise(imageFile) {
    return new Promise((resolve, reject) => {
      const filePath = `DoubleTax/${imageFile.name}_${new Date().getTime()}`;
      var storageRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, imageFile);
      let progress;
      task.snapshotChanges().pipe(
        finalize(() => {
          var downloadURL = storageRef.getDownloadURL();
          downloadURL.subscribe(async url => {
            let file = {
              name: imageFile.name,
              url: url,
            }
            resolve(file);
          });
        })
      ).subscribe(url => {
        if (url) {
          progress = url;
        }
      });
    });
  }

  async viewPPT(form) {
    if (form.url) {
      const existingPdfBytes = await fetch(form.url).then(res => res.arrayBuffer())
      let file = new Blob([existingPdfBytes]);
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = form.name;
      anchor.href = fileURL;
      anchor.click();
    } else {
      let file = new Blob([form]);
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = form.name;
      anchor.href = fileURL;
      anchor.click();
    }
  }

  computeTotal(type) {
    switch (type) {
      case 'Allowance':
        return !isNaN(
          parseFloat(this.fifthFormGroup.get('AllowanceYear1').value) +
          parseFloat(this.fifthFormGroup.get('AllowanceYear2').value) +
          parseFloat(this.fifthFormGroup.get('AllowanceYear3').value)
        ) ? (parseFloat(this.fifthFormGroup.get('AllowanceYear1').value) +
          parseFloat(this.fifthFormGroup.get('AllowanceYear2').value) +
          parseFloat(this.fifthFormGroup.get('AllowanceYear3').value)).toFixed(2) : '0.00'

      case 'Travel':
        return !isNaN(
          parseFloat(this.fifthFormGroup.get('TravelYear1').value) +
          parseFloat(this.fifthFormGroup.get('TravelYear2').value) +
          parseFloat(this.fifthFormGroup.get('TravelYear3').value)
        ) ? (parseFloat(this.fifthFormGroup.get('TravelYear1').value) +
          parseFloat(this.fifthFormGroup.get('TravelYear2').value) +
          parseFloat(this.fifthFormGroup.get('TravelYear3').value)).toFixed(2) : '0.00'

      case 'Rental':
        return !isNaN(
          parseFloat(this.fifthFormGroup.get('RentalYear1').value) +
          parseFloat(this.fifthFormGroup.get('RentalYear2').value) +
          parseFloat(this.fifthFormGroup.get('RentalYear3').value)
        ) ? (parseFloat(this.fifthFormGroup.get('RentalYear1').value) +
          parseFloat(this.fifthFormGroup.get('RentalYear2').value) +
          parseFloat(this.fifthFormGroup.get('RentalYear3').value)).toFixed(2) : '0.00'

      case 'Research':
        return !isNaN(
          parseFloat(this.fifthFormGroup.get('ResearchYear1').value) +
          parseFloat(this.fifthFormGroup.get('ResearchYear2').value) +
          parseFloat(this.fifthFormGroup.get('ResearchYear3').value)
        ) ? (parseFloat(this.fifthFormGroup.get('ResearchYear1').value) +
          parseFloat(this.fifthFormGroup.get('ResearchYear2').value) +
          parseFloat(this.fifthFormGroup.get('ResearchYear3').value)).toFixed(2) : '0.00'

      case 'Maintenance':
        return !isNaN(
          parseFloat(this.fifthFormGroup.get('MaintenanceYear1').value) +
          parseFloat(this.fifthFormGroup.get('MaintenanceYear2').value) +
          parseFloat(this.fifthFormGroup.get('MaintenanceYear3').value)
        ) ? (parseFloat(this.fifthFormGroup.get('MaintenanceYear1').value) +
          parseFloat(this.fifthFormGroup.get('MaintenanceYear2').value) +
          parseFloat(this.fifthFormGroup.get('MaintenanceYear3').value)).toFixed(2) : '0.00'

      case 'Professional':
        return !isNaN(
          parseFloat(this.fifthFormGroup.get('ProfessionalYear1').value) +
          parseFloat(this.fifthFormGroup.get('ProfessionalYear2').value) +
          parseFloat(this.fifthFormGroup.get('ProfessionalYear3').value)
        ) ? (parseFloat(this.fifthFormGroup.get('ProfessionalYear1').value) +
          parseFloat(this.fifthFormGroup.get('ProfessionalYear2').value) +
          parseFloat(this.fifthFormGroup.get('ProfessionalYear3').value)).toFixed(2) : '0.00'
      case 'Accessories':
        return !isNaN(
          parseFloat(this.fifthFormGroup.get('AccessoriesYear1').value) +
          parseFloat(this.fifthFormGroup.get('AccessoriesYear2').value) +
          parseFloat(this.fifthFormGroup.get('AccessoriesYear3').value)
        ) ? (parseFloat(this.fifthFormGroup.get('AccessoriesYear1').value) +
          parseFloat(this.fifthFormGroup.get('AccessoriesYear2').value) +
          parseFloat(this.fifthFormGroup.get('AccessoriesYear3').value)).toFixed(2) : '0.00'
      case 'Total':
      case 'GrandTotal':
      case 'ServiceCharge':
        let total = !isNaN(
          parseFloat(this.fifthFormGroup.get('AllowanceYear1').value) +
          parseFloat(this.fifthFormGroup.get('AllowanceYear2').value) +
          parseFloat(this.fifthFormGroup.get('AllowanceYear3').value) +
          parseFloat(this.fifthFormGroup.get('TravelYear1').value) +
          parseFloat(this.fifthFormGroup.get('TravelYear2').value) +
          parseFloat(this.fifthFormGroup.get('TravelYear3').value) +
          parseFloat(this.fifthFormGroup.get('RentalYear1').value) +
          parseFloat(this.fifthFormGroup.get('RentalYear2').value) +
          parseFloat(this.fifthFormGroup.get('RentalYear3').value) +
          parseFloat(this.fifthFormGroup.get('ResearchYear1').value) +
          parseFloat(this.fifthFormGroup.get('ResearchYear2').value) +
          parseFloat(this.fifthFormGroup.get('ResearchYear3').value) +
          parseFloat(this.fifthFormGroup.get('MaintenanceYear1').value) +
          parseFloat(this.fifthFormGroup.get('MaintenanceYear2').value) +
          parseFloat(this.fifthFormGroup.get('MaintenanceYear3').value) +
          parseFloat(this.fifthFormGroup.get('ProfessionalYear1').value) +
          parseFloat(this.fifthFormGroup.get('ProfessionalYear2').value) +
          parseFloat(this.fifthFormGroup.get('ProfessionalYear3').value) +
          parseFloat(this.fifthFormGroup.get('AccessoriesYear1').value) +
          parseFloat(this.fifthFormGroup.get('AccessoriesYear2').value) +
          parseFloat(this.fifthFormGroup.get('AccessoriesYear3').value)
        ) ?
          (
            parseFloat(this.fifthFormGroup.get('AllowanceYear1').value) +
            parseFloat(this.fifthFormGroup.get('AllowanceYear2').value) +
            parseFloat(this.fifthFormGroup.get('AllowanceYear3').value) +
            parseFloat(this.fifthFormGroup.get('TravelYear1').value) +
            parseFloat(this.fifthFormGroup.get('TravelYear2').value) +
            parseFloat(this.fifthFormGroup.get('TravelYear3').value) +
            parseFloat(this.fifthFormGroup.get('RentalYear1').value) +
            parseFloat(this.fifthFormGroup.get('RentalYear2').value) +
            parseFloat(this.fifthFormGroup.get('RentalYear3').value) +
            parseFloat(this.fifthFormGroup.get('ResearchYear1').value) +
            parseFloat(this.fifthFormGroup.get('ResearchYear2').value) +
            parseFloat(this.fifthFormGroup.get('ResearchYear3').value) +
            parseFloat(this.fifthFormGroup.get('MaintenanceYear1').value) +
            parseFloat(this.fifthFormGroup.get('MaintenanceYear2').value) +
            parseFloat(this.fifthFormGroup.get('MaintenanceYear3').value) +
            parseFloat(this.fifthFormGroup.get('ProfessionalYear1').value) +
            parseFloat(this.fifthFormGroup.get('ProfessionalYear2').value) +
            parseFloat(this.fifthFormGroup.get('ProfessionalYear3').value) +
            parseFloat(this.fifthFormGroup.get('AccessoriesYear1').value) +
            parseFloat(this.fifthFormGroup.get('AccessoriesYear2').value) +
            parseFloat(this.fifthFormGroup.get('AccessoriesYear3').value)
          ) : 0;

        if (type === 'Total')
          return total.toFixed(2);

        else if (type === 'ServiceCharge')
          return (total * 0.05).toFixed(2);

        else if (type === 'GrandTotal')
          return (total + (total * 0.05)).toFixed(2);


    }
  }



  // async preview(){
  //   this.spinner.show();

  //   let f1 = this.firstFormGroup.getRawValue();
  //   let f2 = this.secondFormGroup.getRawValue();
  //   let f3 = this.thirdFormGroup.getRawValue();
  //   let f4 = this.fourthFormGroup.getRawValue();
  //   let f5 = this.sixthFormGroup.getRawValue();

  //   this.pdfDoc = await PDFDocument.create();
  //   const font = await this.pdfDoc.embedFont(StandardFonts.TimesRoman)

  //   const fontBold = await this.pdfDoc.embedFont(StandardFonts.TimesRomanBold)

  //   this.currentPage = this.pdfDoc.addPage();
  //   this.currentY = 800;
  //   this.currentX = 50;
  //   this.currentFontSize = 18;
  //   this.autoText('Preview Technology Exhibition submition', fontBold);
  //   this.currentY = this.currentY-10;

  //   this.currentFontSize = 16;
  //   this.currentY = this.currentY-10;
  //   this.autoText('Introduction', fontBold);

  //   this.currentFontSize = 12;
  //   this.autoText('Applicant Name ', font);
  //   this.autoText(': '+f1.Applicant, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

  //   this.autoText('School', font);
  //   this.autoText(': '+f1.School, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

  //   this.autoText('Email', font);
  //   this.autoText(': '+f1.Email, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

  //   this.autoText('Phone number', font);
  //   this.autoText(': '+f1.Phone, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

  //   this.currentFontSize = 14;
  //   this.currentY = this.currentY-10;
  //   this.autoText('Product/Technology Name: ', font);
  //   this.currentFontSize = 12;
  //   this.autoText(f1.Product, font);


  //   if(f1.ProjectMembers.length > 0){
  //     this.currentFontSize = 14;
  //     this.currentY = this.currentY-10;
  //     this.autoText('Team Members', fontBold);
  //   }

  //   let i = 1;
  //   for (const d of f1.ProjectMembers) {
  //     this.currentFontSize = 12;
  //     this.autoText('(' + i + ')', font);
  //     this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
  //     this.autoText(': '+d.Name,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
  //     this.autoText('Identity Number ', font, false, this.currentY, true, 70, true);
  //     this.autoText(': '+d.IdentityNumber,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
  //     this.autoText('USM Staff ', font, false, this.currentY, true, 70, true);
  //     this.autoText(': '+d.USMStaff?'Yes':'No',  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
  //     this.autoText('Expertise/Experience ', font, false, this.currentY, true, 70, true);
  //     this.autoText(d.Experience,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
  //     this.autoText('Percentage(%)', font, false, this.currentY, true, 70, true);
  //     this.autoText(': '+d.Percentages,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

  //     i++
  //   }

  //   if(f1.ProjectMembers.length > 0){
  //     this.currentFontSize = 14;
  //     this.currentY = this.currentY-5;
  //     this.autoText(' Total Percentage: ' + this.getTotalPercentage(), font);
  //   }

  //   this.currentFontSize = 12;

  //   this.currentFontSize = 16;
  //   this.currentY = this.currentY-20;
  //   this.autoText('Technology Roadmap', fontBold);

  //   this.currentFontSize = 14;
  //   this.currentY = this.currentY-10;
  //   this.autoText('Sustainable Development Goals: ', font);
  //   this.currentFontSize = 12;
  //   this.autoText(f2.Goal, font);

  //   this.currentFontSize = 14;
  //   this.currentY = this.currentY-10;
  //   this.autoText('My STIE 10x10 Science & Technology Driver: ', font);
  //   this.currentFontSize = 12;
  //   this.autoText(f2.TechnologyDriver, font);

  //   if(f2.Publication.length > 0){
  //     this.currentFontSize = 14;
  //     this.currentY = this.currentY-10;
  //     this.autoText('List of Publication', fontBold);
  //   }

  //   i = 1;
  //   for (const d of f2.Publication) {
  //     this.currentFontSize = 12;
  //     this.autoText('(' + i + ')', font);
  //     this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
  //     this.autoText(': ' +d.Name,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
  //     i++
  //   }

  //   if(f2.Intelliectual.length > 0){
  //     this.currentFontSize = 14;
  //     this.currentY = this.currentY-10;
  //     this.autoText('List of Intellectual Property', fontBold);
  //   }

  //   i = 1;
  //   for (const d of f2.Intelliectual) {
  //     this.currentFontSize = 12;
  //     this.autoText('(' + i + ')', font);
  //     this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
  //     this.autoText(': ' +d.Name,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
  //     i++
  //   }

  //   if(f2.Research.length > 0){
  //     this.currentFontSize = 14;
  //     this.currentY = this.currentY-10;
  //     this.autoText('List of Research Collaboration Agreement', fontBold);
  //   }

  //   i = 1;
  //   for (const d of f2.Research) {
  //     this.currentFontSize = 12;
  //     this.autoText('(' + i + ')', font);
  //     this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
  //     this.autoText(': ' +d.Name,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
  //     i++
  //   }

  //   if(f2.Grant.length > 0){
  //     this.currentFontSize = 14;
  //     this.currentY = this.currentY-10;
  //     this.autoText('List of Grants', fontBold);
  //   }

  //   i = 1;
  //   for (const d of f2.Grant) {
  //     this.currentFontSize = 12;
  //     this.autoText('(' + i + ')', font);
  //     this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
  //     this.autoText(': ' +d.Name,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
  //     this.autoText('Value (RM)', font, true, this.currentY , true, 70, true);
  //     this.autoText(': ' +d.Value,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
  //    i++
  //   }

  //   if(f2.Consultation.length > 0){
  //     this.currentFontSize = 14;
  //     this.currentY = this.currentY-10;
  //     this.autoText('List of Consultation', fontBold);
  //   }

  //   i = 1;
  //   for (const d of f2.Consultation) {
  //     this.currentFontSize = 12;
  //     this.autoText('(' + i + ')', font);
  //     this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
  //     this.autoText(': ' +d.Name,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
  //     this.autoText('Value (RM)', font, true, this.currentY , true, 70, true);
  //     this.autoText(': ' +d.Value,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
  //    i++
  //   }


  //   if(f2.Technology.length > 0){
  //     this.currentFontSize = 14;
  //     this.currentY = this.currentY-10;
  //     this.autoText('List of Technology Licencing', fontBold);
  //   }

  //   i = 1;
  //   for (const d of f2.Technology) {
  //     this.currentFontSize = 12;
  //     this.autoText('(' + i + ')', font);
  //     this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
  //     this.autoText(': ' +d.Name,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
  //     this.autoText('Value (RM)', font, true, this.currentY, true, 70, true);
  //     this.autoText(': ' +d.Value || 0,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
  //    i++
  //   }

  //   if(f2.Products.length > 0){
  //     this.currentFontSize = 14;
  //     this.currentY = this.currentY-10;
  //     this.autoText('List of Product Commercialization', fontBold);
  //   }

  //   i = 1;
  //   for (const d of f2.Products) {
  //     this.currentFontSize = 12;
  //     this.autoText('(' + i + ')', font);
  //     this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
  //     this.autoText(': ' +d.Name,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
  //     this.autoText('Value (RM)', font, true, this.currentY , true, 70, true);
  //     this.autoText(': ' +d.Value || 0,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
  //    i++
  //   }

  //   if(f2.IndustrialPatners.length > 0){
  //     this.currentFontSize = 14;
  //     this.currentY = this.currentY-10;
  //     this.autoText('List of Industrial Partners/Collaborators', fontBold);
  //   }

  //   i = 1;
  //   for (const d of f2.IndustrialPatners) {
  //     this.currentFontSize = 12;
  //     this.autoText('(' + i + ')', font);
  //     this.autoText('Name', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
  //     this.autoText(': ' +d.Name,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
  //    i++
  //   }

  //   this.currentFontSize = 16;
  //   this.currentY = this.currentY-20;
  //   this.autoText('Technology Description', fontBold);

  //   this.currentFontSize = 14;
  //   this.currentY = this.currentY-10;
  //   this.autoText('Executive Summary', font);
  //   this.currentFontSize = 12;
  //   this.autoText(f3.ExecutiveSummary, font);

  //   this.currentFontSize = 14;
  //   this.currentY = this.currentY-10;
  //   this.autoText('Problem Statement', font);
  //   this.currentFontSize = 12;
  //   this.autoText(f3.ProblemStatement, font);

  //   this.currentFontSize = 14;
  //   this.currentY = this.currentY-10;
  //   this.autoText('Technology Readiness Level (TRL)', font);
  //   this.currentFontSize = 12;
  //   this.autoText(f3.TRL, font);

  //   this.currentFontSize = 14;
  //   this.currentY = this.currentY-10;
  //   this.autoText('TRL Assessor', font);
  //   this.currentFontSize = 12;
  //   this.autoText(f3.TRLAssessor, font);

  //   this.currentFontSize = 14;
  //   this.currentY = this.currentY-10;
  //   this.autoText('Product/Technology Status', font);
  //   this.currentFontSize = 12;
  //   this.autoText(f3.Status, font);

  //   this.currentFontSize = 14;
  //   this.currentY = this.currentY-10;
  //   this.autoText('Usefulness and Application ', font);
  //   this.currentFontSize = 12;
  //   this.autoText(f3.Usefulness, font);

  //   this.currentFontSize = 14;
  //   this.currentY = this.currentY-10;
  //   this.autoText('Technology Benchmarking', font);
  //   this.currentFontSize = 12;
  //   this.autoText(f3.Benchmarking, font);


  //   this.currentFontSize = 16;
  //   this.currentY = this.currentY-20;
  //   this.autoText('Technology Commercialization', fontBold);

  //   this.currentFontSize = 14;
  //   this.currentY = this.currentY-10;
  //   this.autoText('Target Market ', font);
  //   this.currentFontSize = 12;
  //   this.autoText(f4.TargetMarket, font);

  //   this.currentFontSize = 14;
  //   this.currentY = this.currentY-10;
  //   this.autoText('Go-to-market Strategy ', font);
  //   this.currentFontSize = 12;
  //   this.autoText(f4.MarketStrategy, font);

  //   this.currentFontSize = 14;
  //   this.currentY = this.currentY-10;
  //   this.autoText('Market Strategy Type  ', font);
  //   this.currentFontSize = 12;
  //   this.autoText(f4.MarketStrategyType.join(','), font);

  //   this.currentFontSize = 14;
  //   this.currentY = this.currentY-10;
  //   this.autoText('Business Model ', font);
  //   this.currentFontSize = 12;
  //   this.autoText(f4.BusinessModel, font);

  //   this.currentFontSize = 14;
  //   this.currentY = this.currentY-10;
  //   this.autoText('Commercialization Gap ', font);
  //   this.currentFontSize = 12;
  //   this.autoText(f4.Commercialization, font);



  //   const pdfBytes = await this.pdfDoc.save()
  //   let f = new Blob([pdfBytes], { type: 'application/pdf' });
  //   var fileURL = URL.createObjectURL(f);
  //   var anchor = document.createElement("a");
  //   anchor.download =  "exhibPreview.pdf";
  //   anchor.href = fileURL;
  //   anchor.click();
  //   this.spinner.hide();
  // }


  pdfDoc: PDFDocument;
  currentX = 0;
  currentY = 0;
  currentPage: PDFPage;
  currentFontSize = 12

  async autoText(text, font, fixedYPosition = false, y = 0, fixedXPosition = false, x = 0, correctWidth = false) {
    let multiText = layoutMultilineText(text,
      {
        alignment: TextAlignment.Left,
        fontSize: this.currentFontSize,
        font: font,
        bounds: { x: this.currentX, y: this.currentY - this.currentFontSize - 5, width: correctWidth ? 350 : 500, height: 600 }
      })

    if (fixedYPosition)
      this.currentY = y;

    for (let i = 0; i < multiText.lines.length; i++) {
      this.currentPage.drawText(`${multiText.lines[i].text}`, {
        x: fixedXPosition ? x : this.currentX,
        y: this.currentY,
        size: this.currentFontSize,
        font: font,
        color: rgb(0, 0, 0)
      })

      if (this.currentY < 80) {
        this.currentPage = this.pdfDoc.addPage();
        this.currentY = 780
      }
      this.currentY = this.currentY - this.currentFontSize - 4;

    }

  }






}
