import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {

  constructor() { }

  convertDateIntoYearMonthDay(date: Date){
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return year.toString() + ("0" + month.toString()).slice(-2) + ("0" + day.toString()).slice(-2);

  }


}
